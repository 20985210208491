import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import OutsiderNavbar from '../components/OutsiderNavbar';
import WelcomeBox from '../components/WelcomeBox';

function ResetPasswordInvalid() {

    const navigate = useNavigate();

    const handleSubmit = () => {
        navigate('/forgot_password');
    }

    useEffect(
        () => {
            document.body.style.background = "#503e9d"
        },
        []
    )

    return (
        <div>

            <div>

                <OutsiderNavbar />

                <div className="boxes">
                    <div className="init_form_box init_form_box_mini">
                        <h4 className="outsider_txt">ขณะนี้ลิงก์สำหรับการตั้งรหัสผ่านใหม่<br />
                            ของท่านหมดอายุแล้ว</h4>

                        <br />

                        <h4 className="outsider_txt">คลิก <span onClick={handleSubmit}><u>ที่นี่</u></span> เพื่อส่งลิงก์สำหรับการตั้งรหัสผ่านใหม่อีกครั้ง
                        </h4>

                    </div>
                    <WelcomeBox isRegis={true} />

                </div>

            </div>

        </div>
    );

}

export default ResetPasswordInvalid;
