import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import YouTube from 'react-youtube';
import axios from 'axios';
import Configuration from '../functions/Configuration';
import recheckAccessTokenIsExpired from '../functions/recheckAccessTokenIsExpired';
import getTokenData from '../functions/getTokenData';
import GetUrlHashValue from '../functions/GetUrlHashValue';
import NavBar from '../components/NavBar';
import CoursePageNameBox from '../components/CoursePageNameBox';
import CourseNaviBigBox from '../components/CourseNaviBigBox';
import HomeFooter from '../components/HomeFooter';
import AlertDialog from '../components/AlertDialog';
import material_download from '../res/material_download.svg';
import created_check from '../res/created_check.svg';
import divide_line_thicker from '../res/divide_line_thicker.svg'

function CourseLessonStart() {
  // new implementation on youtube unskippable
  const [checkpoint, setCheckpoint] = useState(0)
  const [user_progress, setUserProgress] = useState(0)
  const [timer, setTimer] = useState(null)
  const [timerDuration, setTimerDuration] = useState(0)

  const [course_id, setCourseID] = useState("")
  const [course_app, setCourseApp] = useState("")
  const [course_title, setCourseTitle] = useState("")
  const [lesson_id, setLessonID] = useState("");
  const [lessons, setLessons] = useState([]);

  const [is_completed, setIsCompleted] = useState(false);

  const [lesson_progress, setLessonProgress] = useState([]);
  const [video_progress, setVideoProgress] = useState([]);

  const [youtube_video_cache_time_second, setYoutubeVideoCache] = useState(0);

  const [playback_speed_multiplier, setPlaybackSpeedMultiplier] = useState(1)

  const [lesson_data, setLessonData] = useState(null);
  const [quizes_data, setQuizesData] = useState([]);
  const [selected_video, setSelectedVideo] = useState(undefined);
  const [selected_video_type, setSelectedVideoType] = useState(undefined);
  const [selected_video_title, setSelectedVideoTitle] = useState(undefined);
  const [selected_video_id, setSelectedVideoID] = useState(undefined);
  const [selected_video_yt_id, setSelectedVideoYTID] = useState(undefined);
  const [selected_video_is_completed, setSelectedVideoIsCompleted] = useState(undefined);
  const [is_loaded, setLoaded] = useState(false)

  const navigate = useNavigate();

  const [dialog_alert_on, setDialogAlertOn] = useState(false)
  const [error_msg, setErrorMsg] = useState("")
  const [quiz_ready_alert, setQuizReadyAlert] = useState(false)

  const timerRef = useRef();


  const closeAlertDialog = () => {
    setDialogAlertOn(false)
  }
  const closeQuizReadyAlertDialog = () => {
    setQuizReadyAlert(false)
  }

  const startCourseQuiz = (quiz_id) => {
    navigate('/course_quiz#id=' + course_id + "&course_title=" + course_title + "&lesson_id=" + lesson_id + "&lesson_title=" + lesson_data.title + "&quiz_id=" + quiz_id +"&course_app="+ course_app )
  }

  const selectLesson = (lesson_id) => {
    navigate('/course_lesson_start#id=' + course_id + "&course_title=" + course_title + "&lesson_id=" + lesson_id +"&course_app="+ course_app);
    reloadPage(0)
  }

  const backToCourseStart = () => {
    navigate('/course_enroll#id=' + course_id + "&course_app=" + course_app);
  }

  const downloadMaterial = (source) => {
    window.open(source, '_blank').focus();
  }

  const selectVideo = (source, video_type, title, video_id, index, video_progress) => {
    setSelectedVideo("")
    let user_id = getTokenData("user_id")
    setTimeout(() => {

      setSelectedVideo(source)
      setSelectedVideoType(video_type)
      setSelectedVideoTitle(title)

      var cut_short_true_link = source
      if (source.includes("&")) { //prevent aliening url hashes
        cut_short_true_link = (source.split("&"))[0]
      }
      if (video_type === "youtube") {
        if (cut_short_true_link.includes("/embed/")) {
          let source_parts = source.split("/embed/")
          setSelectedVideoYTID(source_parts[1])
        } else {
          let source_parts_w = cut_short_true_link.split("watch?v=")
          setSelectedVideoYTID(source_parts_w[1])
        }
      }
      setSelectedVideoID(video_id)

      let is_completed = selected_video_is_completed
      if (video_progress) {
        if (video_progress.length > 0) {
          if (video_progress[index]) {
            is_completed = video_progress[index].is_completed
            setSelectedVideoIsCompleted(is_completed)
          } else {
            is_completed = false
            setSelectedVideoIsCompleted(is_completed)
          }
        } else {
          is_completed = false
          setSelectedVideoIsCompleted(is_completed)
        }
      } else {
        is_completed = false
        setSelectedVideoIsCompleted(is_completed)
      }

      /**Cache Zone**/

      let cache_time = parseFloat(window.localStorage.getItem("video_time_" + video_id + "_user_" + user_id));
      //console.log(cache_time)
      if (cache_time) {
        if (video_type === "youtube") {
          setYoutubeVideoCache(cache_time)
          setCheckpoint(cache_time)
        }
      }

      if (!is_completed) {
        if (video_type !== "youtube") {
          unfinishedVideoHandler(cache_time)
          let video = document.getElementById('video');
          //console.log(video)
          if (video) {
            //video.playbackRate = 4.0;
            video.addEventListener('ended', submitVideoFinished);
          }
        }
      }

      if (video_id) {
        window.localStorage.setItem("user_selected_video_id_user_" + user_id, video_id)
        window.localStorage.setItem("user_selected_video_index_user_" + user_id, index)
      }

    }, 5);

  }

  const unfinishedVideoHandler = (cache_time) => { //https://stackoverflow.com/questions/11903545/how-to-disable-seeking-with-html5-video-tag
    let video = document.getElementById('video');
    if (!video) {
      return
    }
    if (selected_video_is_completed) {
      return
    }

    let user_id = getTokenData("user_id")

    setTimeout(() => {

      video.currentTime = cache_time

    }, 100);

    setTimeout(() => {

      let supposedCurrentTime = 0;
      video.addEventListener('timeupdate', function () {
        if (!video.seeking) {
          supposedCurrentTime = video.currentTime;
          let selected_video_id = window.localStorage.getItem("user_selected_video_id_user_" + user_id);
          //console.log(selected_video_id, supposedCurrentTime)
          if (video.currentTime >= cache_time) {
            window.localStorage.setItem("video_time_" + selected_video_id + "_user_" + user_id, supposedCurrentTime);
          }
        }
      });
      // prevent user from seeking
      video.addEventListener('seeking', function () {
        // guard agains infinite recursion:
        // user seeks, seeking is fired, currentTime is modified, seeking is fired, current time is modified, ....
        let delta = video.currentTime - supposedCurrentTime;
        if (Math.abs(delta) > 0.01 && video.currentTime >= cache_time) {
          video.currentTime = supposedCurrentTime;
          //console.log(video.currentTime)
        }
      });
      // delete the following event handler if rewind is not required

    }, 500);

  }

  function onYTPlaybackRateChange(event) {
    event.target.pauseVideo();
  }

  function onYTReady(event) {
    event.target.pauseVideo();
    if (selected_video_is_completed) {
      return
    }
    let user_id = getTokenData("user_id")
    let cache_time = parseFloat(window.localStorage.getItem("video_time_" + selected_video_id + "_user_" + user_id));
    event.target.seekTo(cache_time)
  }

  function onYTPause(event) {
    if (selected_video_is_completed) {
      return
    }
    clearInterval(timer)

    const user_id = getTokenData("user_id") 
    const currentPlayerTime = event.target.playerInfo.currentTime

    //normal pause
    if (currentPlayerTime - checkpoint - timerDuration <= 1) {
      //pause when already pass the progress
      if (checkpoint + timerDuration > user_progress) {
        //set new progress 
        setUserProgress(parseFloat(checkpoint) + parseFloat(timerDuration))
        // setYTMaxTempPlayerTime(parseFloat(yt_tempPlayerTime) + parseFloat(yt_duration))
        window.localStorage.setItem("video_time_" + selected_video_id + "_user_" + user_id, parseFloat(checkpoint) + parseFloat(timerDuration));
      }
      setTimerDuration(0)
      setCheckpoint(parseFloat(checkpoint) + parseFloat(timerDuration))
    }
  }

  function onYTPlay(event) {
    if (selected_video_is_completed) {
      return
    }
    const user_id = getTokenData("user_id")
    const currentPlayerTime = event.target.playerInfo.currentTime
    const playbackRate = event.target.playerInfo.playbackRate

    var seconds, startTime = new Date

    //start normal play
    var ytDurationTimerDurationDiff = Math.abs(currentPlayerTime - checkpoint - timerDuration)
    if (ytDurationTimerDurationDiff <= 1) {
      setTimerDuration(0)
      //update timer every ~ 500 milli seconds 
      
      const tempTimer = setInterval(() => {
        var currentTime = new Date
        seconds = (currentTime.getTime() - startTime.getTime()) / 1000
        setTimerDuration(seconds * playbackRate)
        // console.log(seconds * playbackRate)
        //is this a good idea update localstorage every 500 milli seconds?
        window.localStorage.setItem("video_time_" + selected_video_id + "_user_" + user_id, event.target.getCurrentTime());
      }, 500)
      setTimer(tempTimer)
      if (checkpoint + timerDuration > user_progress) {
        setUserProgress(checkpoint + timerDuration)
        window.localStorage.setItem("video_time_" + selected_video_id + "_user_" + user_id, parseFloat(checkpoint) + parseFloat(timerDuration));
      }
    } else {
      //skip
      if (currentPlayerTime > user_progress) {
        event.target.seekTo(parseFloat(checkpoint) + parseFloat(timerDuration))
      }
    }

    clearInterval(timer)
    setTimerDuration(0)
    setCheckpoint(checkpoint + timerDuration)

  }

  function onYTEnd(event) {

    if (selected_video_is_completed) {
      return
    }
    clearInterval(timer)
    if (Math.abs(event.target.playerInfo.duration - timerDuration - checkpoint) <= 5 ) {
      submitVideoFinished()
    } else {
      event.target.seekTo(checkpoint + timerDuration)
    }
    
    // if (Math.abs(event.target.playerInfo.duration - yt_duration - yt_tempPlayerTime) <= 5) {
    // submitVideoFinished()
    // } else {
    //   event.target.seekTo(yt_tempPlayerTime + yt_duration)
    // }
  }

  const getVideoIDFromURL = (videoURL) => {
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = videoURL.match(regExp);
    return (match && match[7].length == 11) ? match[7] : false;
  }

  const submitVideoFinished = () => {
    let access_token_data = window.localStorage.getItem("access_token_data");
    let access_token_data_type = window.localStorage.getItem("access_token_data_type");

    let user_id = getTokenData("user_id")
    let course_id = GetUrlHashValue("id");
    let lesson_id = GetUrlHashValue("lesson_id");
    let selected_video_id = window.localStorage.getItem("user_selected_video_id_user_" + user_id);
    let selected_video_index = window.localStorage.getItem("user_selected_video_index_user_" + user_id);

    const url = Configuration("video_host") + "/api/v1/user/" + user_id + "/course/" + course_id + "/lesson/" + lesson_id + "/video/" + selected_video_id + "/submit-video";
    //console.log(url)
    //console.log(body)

    const postData = async () => {
      try {
        await axios({
          method: "post",
          url: url,
          headers: { "Authorization": access_token_data_type + " " + access_token_data },
        });
        window.localStorage.removeItem("video_time_" + selected_video_id + "_user_" + user_id);

        if (parseInt(selected_video_index) == lesson_data.videos?.length - 1) {
          reloadPage(selected_video_index)
          setQuizReadyAlert(true)
        } else {
          reloadPage(parseInt(selected_video_index) + 1)
        }
        //navigate('/course_quiz_result#id='+course_id+"&course_title="+course_title+"&lesson_id="+lesson_id+"&lesson_title="+lesson_title+"&quiz_id="+quiz_id);

      } catch (error) {
        if (error.response.status < 500) {
          setErrorMsg("Video Submit Input Error")
        } else {
          setErrorMsg("Internal Server Error")
        }
        setDialogAlertOn(true)
      }
    };

    //console.log(answers)
    postData()

  }

  const reloadPage = (selected_video_index) => {
    let tag = document.createElement('script');

    tag.src = "https://www.youtube.com/iframe_api";
    let firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    let get_course_id = GetUrlHashValue("id")
    setCourseID(get_course_id)

    let get_course_app = GetUrlHashValue("course_app")
    setCourseApp(get_course_app)

    let get_course_title = GetUrlHashValue("course_title")
    setCourseTitle(decodeURIComponent(get_course_title))

    let get_lesson_id = GetUrlHashValue("lesson_id")
    setLessonID(get_lesson_id)

    let user_id = getTokenData("user_id")

    setSelectedVideo(undefined)
    setSelectedVideoType(undefined)
    setSelectedVideoTitle(undefined)
    setSelectedVideoIsCompleted(false)
    setUserProgress(0)
    setCheckpoint(0)
    setTimerDuration(0)
    clearInterval(timer)
    setTimer(null)

    if (recheckAccessTokenIsExpired()) {
      navigate('/');
    }

    let access_token_data = window.localStorage.getItem("access_token_data");
    let access_token_data_type = window.localStorage.getItem("access_token_data_type");

    const url = Configuration("video_host") + "/api/v1/course/" + get_course_id + "/lesson";

    const fetchData = async () => {
      try {
        const { data: response } = await axios({
          method: "get",
          url: url,
          headers: { "Authorization": access_token_data_type + " " + access_token_data },
        });
        let the_data = response.data.lessons
        setLessons(the_data)
        fetchData2()
      } catch (error) {
        if (error.response.status < 500) {
          setErrorMsg("Lesson Load Error")
        } else {
          setErrorMsg("Internal Server Error")
        }
        setDialogAlertOn(true)
      }
    };

    fetchData()

    const url2 = Configuration("video_host") + "/api/v1/course/" + get_course_id + "/lesson/" + get_lesson_id;

    const fetchData2 = async () => {
      try {
        const { data: response } = await axios({
          method: "get",
          url: url2,
          headers: { "Authorization": access_token_data_type + " " + access_token_data },
        });
        let the_data = response.data
        //console.log(the_data)
        if (!the_data.videos) {
          the_data.videos = []
        }
        setLessonData(the_data)
        if (the_data) {
          console.log(the_data)
          let vid_select
          if (the_data.videos && the_data.videos.length > 0) {
              vid_select = {
                source: the_data.videos[selected_video_index].source,
                video_type: the_data.videos[selected_video_index].video_type,
                title: the_data.videos[selected_video_index].title,
                video_id: the_data.videos[selected_video_index].id,
              }
          }
          fetchDataForTheRest(vid_select)
        }

      } catch (error) {
        console.log(error)
        if (error.response.status < 500) {
          setErrorMsg("Lesson Load Error")
        } else {
          setErrorMsg("Internal Server Error")
        }
        setDialogAlertOn(true)
      }
    };

    const url3 = Configuration("video_host") + "/api/v1/course/" + get_course_id + "/lesson/" + get_lesson_id + "/quiz";

    const url4 = Configuration("video_host") + "/api/v1/user/" + user_id + "/course/" + get_course_id + "/progress";

    const url5 = Configuration("video_host") + "/api/v1/user/" + user_id + "/course/" + get_course_id + "/lesson/" + get_lesson_id + "/progress";

    const fetchDataForTheRest = async (vid_select) => {
      try {
        const data_arr = await Promise.all([
          axios({
            method: "get",
            url: url3,
            headers: { "Authorization": access_token_data_type + " " + access_token_data },
          }),
          axios({
            method: "get",
            url: url4,
            headers: { "Authorization": access_token_data_type + " " + access_token_data },
          }),
          axios({
            method: "get",
            url: url5,
            headers: { "Authorization": access_token_data_type + " " + access_token_data },
          })

        ]);
        //console.log(data_arr)

        for (let i in data_arr) {
          let index = parseInt(i)
          let response = data_arr[index].data

          if (index === 0) {
            let the_data = response.data
            setQuizesData(the_data)
          } else if (index === 1) {
            let the_data = response.data
            if(the_data.lesson_progress){
              setLessonProgress(the_data.lesson_progress)
            }else{
              setLessonProgress([])
            }
          } else if (index === 2) {
            let the_data = response.data
            setIsCompleted(the_data.is_completed)

            if (the_data) {
              if (the_data.video_progress) {
                setVideoProgress(the_data.video_progress)
              } else {
                setVideoProgress([])
              }
            }
            setLoaded(true)

            if(vid_select){
              selectVideo(vid_select.source, vid_select.video_type, vid_select.title, vid_select.video_id, selected_video_index, the_data.video_progress)
              window.localStorage.setItem("user_selected_video_id_user_" + user_id, vid_select.video_id);
            }
          }

        }

      } catch (error) {
        console.log(error)
        if (error.response.status < 500) {
          setErrorMsg("Lesson Load Error")
        } else {
          setErrorMsg("Internal Server Error")
        }
        setDialogAlertOn(true)
      }
    };

    fetchData()

  }

  useEffect(
    () => {
      reloadPage(0)
    },
    [] 
  )
  useEffect(()=>{
    timerRef.current = timer
  }, [timer])
  useEffect(()=>{
    return () => {
      clearInterval(timerRef.current)
    }
  },[])

  const completedLessonWording = () => {
    switch(course_app){
      case "video":{
        return "บทเรียนนี้ได้ผ่านเรียบร้อยแล้ว"
      }
      case "exam" :{
        return "คุณทำแบบทดสอบฉบับนี้เสร็จแล้ว"
      }
      default:{
        //TODO handle default course app
        return ""
      }
    }
  }

  return (
    <main>
      <NavBar isCourse={true} />
      <div className='container'>
        <div className={"course_body_back "}>
          {quiz_ready_alert ? <AlertDialog alert_title="คุณดูวิดีโอของบทนี้จบแล้ว ตอนนี้คุณสามารถทำแบบฝึกหัดท้ายบทด้านล่าง หรือเลือกเรียนบทถัดไปได้เลย" parentCallback={closeQuizReadyAlertDialog}/>:<></>}
          {dialog_alert_on ? <AlertDialog alert_title={error_msg} alert_msg={""} parentCallback={closeAlertDialog} /> : ""}

          <div className={"course_body"}>


            <div className="home_item boxes course_item_big_box">

              {is_loaded ? <CoursePageNameBox courseName={course_title} courseCallback={backToCourseStart} lessonName={lesson_data.title} type={"lesson"} /> : ""}

              {/*<CourseNaviSmallBox type={"lessons"}/>*/}

            </div>

            <div className="home_item boxes course_item_big_box">
    
              {is_loaded ? <CourseNaviBigBox course_id={course_id} arrayType={"lessons"} lessonsArr={lessons} lessonProgressArr={lesson_progress} parentCallbackLesson={selectLesson} /> : ""}

              {is_loaded ?
                <div className="course_main_box">

                  {selected_video ? <div className="video_wrapper">

                    {/*selected_video ? <div className="course_enroll_paragraph_title lesson_title_after">
            กำลังเล่นวิดีโอ : {selected_video_title}
            </div> : ""*/}

                    {selected_video_type === "youtube" ? <div tabIndex="0">
                      <YouTube className="responsive_iframe course_video" opts={{
                        playerVars: { //https://www.npmjs.com/package/react-youtube
                          // https://developers.google.com/youtube/player_parameters
                          controls: 1,//(selected_video_is_completed ? 1 : 0),
                          start: youtube_video_cache_time_second,
                        },
                      }} videoId={selected_video_yt_id}
                        onReady={onYTReady}
                        onEnd={onYTEnd}
                        onPlay={onYTPlay}
                        onPause={onYTPause}
                        onPlaybackRateChange={onYTPlaybackRateChange}
                      />
                    </div> : <div tabIndex="0">
                      <video className="responsive_iframe course_video" id={"video"}
                        src={selected_video}
                        controls /></div>}

                  </div> : ""}
                  <div className="bottom_part_lesson">
                    {/* lesson_data.videos.length > 0 ? <div className="course_enroll_paragraph_title">
            รายการวิดีโอของบทเรียนนี้
            </div> : ""*/}
                    {lesson_data.videos.length > 0 ? <div className="video_lesson_list">{lesson_data.videos.map((item, index) => {
                      return (
                        <span key={item.storage_id + "_" + index}>
                          {video_progress[index - 1] || index === 0 ?
                            <span>
                              <div className="course_video_btn_txt">
                                {video_progress[index] ? <img alt="a completed video" src={created_check} width="20" height="20" /> : ""} {"คลิปที่ " + (index + 1) + "/" + lesson_data.videos.length + " " + item.title}
                              </div>
                              <button className="course_video_btn" onClick={() => selectVideo(item.source, item.video_type, item.title, item.id, index, video_progress)}>
                                {item.video_type == "youtube" && item.source ?
                                  < img className="course_video_btn_img" src={"https://img.youtube.com/vi/" + getVideoIDFromURL(item.source) + "/0.jpg"} alt={item.title} />
                                  :
                                  <img className="course_video_btn_img" src={item.video_thumbnail} alt={item.title} />

                                }
                              </button>


                            </span>
                            : <span>
                              <div className="course_video_btn_txt">
                                {video_progress[index] ? <img alt="a completed video" src={created_check} width="20" height="20" /> : ""} {"คลิปที่ " + (index + 1) + "/" + lesson_data.videos.length + " " + item.title}
                              </div>
                              <button className="course_video_btn course_video_btn_locked" disabled>
                                {item.video_type == "youtube" && item.source ?
                                  < img className="course_video_btn_img course_video_btn_img_locked" src={"https://img.youtube.com/vi/" + getVideoIDFromURL(item.source) + "/0.jpg"} alt={item.title} />
                                  :
                                  <img className="course_video_btn_img course_video_btn_img_locked" src={item.video_thumbnail} alt={item.title} />
                                }
                              </button>



                            </span>}
                        </span>
                      )
                    })
                    }</div> : ""}
                    <br />

                    <div className="bottom_bottom_part_lesson">

                      <h2 className="course_enroll_paragraph_title lesson_title_after">
                        {lesson_data.title}
                      </h2>
                      <img className='divide_line' src={divide_line_thicker} />

                      <p className="course_enroll_paragraph">{lesson_data.description}</p>

                      {lesson_data.materials ? <h3 className="course_enroll_paragraph_title lesson_title_after">
                        คู่มือการเรียน / เอกสารที่เกี่ยวข้อง
                      </h3> : ""}
                      {lesson_data.materials ? (lesson_data.materials.map((item, index) => {
                        return (
                          <div className='subtopic' key={item.storage_id}>
                            <div className='lesson_material_name_box'>
                              <text className='lesson_material_name'>{index + 1}. {item.title}.{item.file_type}</text>
                            </div>
                            <div className='course_material_download_btn_box'>
                              <button className="course_material_download_btn" onClick={() => downloadMaterial(item.source)}><img src={material_download} alt="download material" width="25" height="25" /> Download</button>
                            </div>
                          </div>
                        )
                      })
                      ) : ""}

                      {!is_completed ? <span>
                        {quizes_data ? <h3 className="course_enroll_paragraph_title lesson_title_after">
                          แบบฝึกหัด: {quizes_data ? quizes_data.length : "0"} ชุด
                        </h3> : ""}

                        {quizes_data ? (quizes_data.map((item, index) => {
                          return (
                            <div className='subtopic' key={item.id}>
                              {/* <text className='topic_dot'>·</text>  */}
                              ชุดที่ {index + 1}: {item.title}
                            </div>
                          )
                        })
                        ) : ""}

                        {quizes_data ? <span><br /></span> : ""}

                        {quizes_data ?
                          (lesson_data.videos.length <= video_progress.length ?
                            <button className="course_start_quiz_btn" onClick={() => {
                              // Find current lesson index
                              var currentLessonIndex
                              for (let i = 0; i < lesson_progress.length; i++) {
                                if (lesson_data.id == lesson_progress[i].lesson_id) {
                                  currentLessonIndex = i
                                }
                              }

                              var targetQuizIndex

                              /* If there is quiz progress go to the quiz which there is not done yet */
                              if (lesson_progress[currentLessonIndex]?.quiz_progress) {
                                for (let i = 0; i < lesson_progress[currentLessonIndex].quiz_progress.length + 1; i++) {
                                  if (lesson_progress[currentLessonIndex].quiz_progress[i] && lesson_progress[currentLessonIndex].quiz_progress[i].is_passed) {
                                    continue
                                  } else {
                                    targetQuizIndex = i
                                    break
                                  }
                                }
                              } else {
                                // If there is no quiz progress go to the first quiz
                                targetQuizIndex = 0
                              }
                              startCourseQuiz(quizes_data[targetQuizIndex].id)
                            }}>เริ่มทำแบบทดสอบ</button>
                            : <button className="course_start_quiz_btn course_start_quiz_btn_greyed" disabled>บทเรียนนี้ยังดูวิดีโอไม่ครบ</button>) : ""}
                      </span> :
                        <button className="course_start_quiz_btn course_start_quiz_btn_greyed" disabled>{completedLessonWording()}</button>}
                    </div>
                  </div>
                </div> : ""}

            </div>

            <br />

          </div>
          <HomeFooter />
        </div>
      </div>
    </main>
  );

}

export default CourseLessonStart;
