import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import Configuration from '../functions/Configuration';
import recheckAccessTokenIsExpired from '../functions/recheckAccessTokenIsExpired';
import getCurrentUserType from '../functions/getCurrentUserType';
import GetUrlHashValue from '../functions/GetUrlHashValue';
import NavBar from '../components/NavBar';
import CourseIconItem from '../components/CourseIconItem';
import HomeFooter from '../components/HomeFooter';
import AlertDialog from '../components/AlertDialog';
import FirstLoginDialog from '../components/FirstLoginDialog';
import person_working_home_page from '../res/people_working_home_page.png';
import getTokenData from '../functions/getTokenData';

function Home() {
  const [is_loaded, setIsLoaded] = useState(false)
  const [courses, setArrayCourses] = useState([])

  const [user_data, setUserData] = useState(null);

  const navigate = useNavigate();

  const [dialog_alert_on, setDialogAlertOn] = useState(false)
  const [error_msg, setErrorMsg] = useState("")

  const [is_first_login, setIsFirstLogin] = useState(false) //work in progress
  const [disability_input_enum, setDisabilityInputEnum] = useState([]);

  const [category_filter, setCategoryFilter] = useState("null");
  const [category_filter_display, setCategoryFilterDisplay] = useState("");
  const [subcategory_filter, setSubCategoryFilter] = useState("null");
  const [subcategory_filter_display, setSubCategoryFilterDisplay] = useState("");

  const [categories_data, setCategoriesData] = useState([]);
  const [subcategories_data, setSubCategoriesData] = useState([]);

  const [title_search, setTitleSearch] = useState([]);

  const closeAlertDialog = () => {
    setDialogAlertOn(false)
  }

  const confirmFirstLogin = (chosen) => {
    editUserInfo(chosen)
  }

  const setValue = (name, value) => {
    if (name === "category") {
      setCategoryFilter(value)

      if (value !== "null") {
        for (let category of categories_data) {
          if (value === category.id) {
            setCategoryFilterDisplay({
              avaliable: category.display_name,
              unavaliable: category.display_name+" ปัจจุบันยังไม่มี",
            })
            break
          }
        }
      } else {
          setCategoryFilterDisplay({
              avaliable: "หลักสูตรทั้งหมด",
              unavaliable: "หลักสูตรทั้งหมดปัจจุบันยังไม่มี",
            })
      }

      setSubCategoryFilter("null")
      // setIsLoaded(false)
      if (value !== "null") {
        for (let category of categories_data) {
          if (value === category.id) {
            if (category.sub_category) {
              setSubCategoriesData(category.sub_category)
            } else {
              setSubCategoriesData([])
            }
          }
        }
        // navigate('/home#filter='+value);
      } else {
        setSubCategoriesData([])
        // navigate('/home');
      }
      setTimeout(() => { 
          reloadPage(value)
        }, 1);
      
    } else if (name === "subcategory") {
      setSubCategoryFilter(value)

      if (value !== "null") {
        for (let subc of subcategories_data) {
          if (subc.id === value) {
            setSubCategoryFilterDisplay(subc.display_name)
            break
          }
        }
      } else {
        setSubCategoryFilterDisplay("")
      }

      setTimeout(() => { 
          reloadPage(category_filter, value)
        }, 1);
    } 
   } 

  const getUserInfo = () => {
    const url = Configuration("user_host")+"/api/v1/user/me";
    let access_token_data = window.localStorage.getItem("access_token_data");
    let access_token_data_type = window.localStorage.getItem("access_token_data_type");

      const fetchData = async () => {
        try {
          const {data: response} = await axios({
            method: "get",
            url: url,
            headers: { "Authorization": access_token_data_type + " " + access_token_data },
          });
          //console.log(response)
          setUserData(response.data)
          if (response.data.display_feature <= 0 && getCurrentUserType() !== "admin") {
            fetchData2()
          } else {
            setIsFirstLogin(false)
            setIsLoaded(true)
          }
          
        } catch (error) {
          if (error.response.status < 500) {
            setErrorMsg("Load User Data Error")
          } else {
            setErrorMsg("Internal Server Error")
          }
          setDialogAlertOn(true)
        }
      };

      const url2 = Configuration("user_host")+"/api/v1/enum/display-feature-info";

      const fetchData2 = async () => {
        try {
          const {data: response} = await axios.get(url2);
          //console.log(response);
          setDisabilityInputEnum(response.data)

          setIsFirstLogin(true)
          setIsLoaded(true)
        } catch (error) {
          if (error.response.status < 500) {
            setErrorMsg("Load Error")
          } else {
            setErrorMsg("Internal Server Error")
          }
          setIsFirstLogin(false)
          setDialogAlertOn(true)
        }
      };

      fetchData()
  }

  const editUserInfo = (new_display_feature) => {
    const url = Configuration("user_host")+"/api/v1/user/me";
    let access_token_data = window.localStorage.getItem("access_token_data");
    let access_token_data_type = window.localStorage.getItem("access_token_data_type");

    let body = {
      display_feature: new_display_feature
    }

    //console.log(body)

      const fetchData = async () => {
        try {
          await axios({
            method: "patch",
            url: url,
            data: body,
            headers: { "Authorization": access_token_data_type + " " + access_token_data },
          });
          //console.log(response)

          fetchData2()
          
        } catch (error) {
          if (error.response.status < 500) {
            setErrorMsg("Edit Input Error")
          } else {
            setErrorMsg("Internal Server Error")
          }
          setIsFirstLogin(false)
          setDialogAlertOn(true)
        }
      };

      const url2 = Configuration("user_host")+"/api/v1/login/refresh";
      let r_token = window.localStorage.getItem("refresh_token_data");
      let r_token_body = {
        refresh_token: r_token,
      }

      const fetchData2 = async () => {
        try {
          const {data: response} = await axios({
            method: "post",
            url: url2,
            data: r_token_body,
          });
          //console.log(response);

          let access_token = response.data.access_token
          let refresh_token = response.data.refresh_token
          let token_type = response.data.token_type

          window.localStorage.setItem("access_token_data", access_token);
          window.localStorage.setItem("refresh_token_data", refresh_token);
          window.localStorage.setItem("access_token_data_type", token_type);

          setIsFirstLogin(false)
          reloadPage()

        } catch (error) {
          if (error.response.status < 500) {
            setErrorMsg("Load Error")
          } else {
            setErrorMsg("Internal Server Error")
          }
          setDialogAlertOn(true)
          setIsFirstLogin(false)
        }
      };

      fetchData()
  }

  const reloadPage = (category_filter, subcategory_filter) => {
    if (recheckAccessTokenIsExpired()) {
        navigate('/');
      }

      let access_token_data = window.localStorage.getItem("access_token_data");
      let access_token_data_type = window.localStorage.getItem("access_token_data_type");
      let user_id = getTokenData("user_id")
      let url = Configuration("main_host")+"/api/v1/course/user/"+user_id;

      url += "?&status=published" //disable this when debug mode

      if (category_filter !== "null" && category_filter !== null && category_filter !== undefined) {
        url += "&category="+category_filter
      }

      if (subcategory_filter !== "null" && subcategory_filter !== null && subcategory_filter !== undefined) {
        url += "&subCategory="+subcategory_filter
      }

      if (title_search) {
        url += "&titleContains=" + title_search
      }

      const fetchData = async () => {
        try {
          const {data: response} = await axios({
            method: "get",
            url: url,
            headers: { "Authorization": access_token_data_type + " " + access_token_data },
          });
          let the_data = response.data
          //console.log(the_data)
          if (the_data) {
            let the_length = the_data.length
            let rows = Math.ceil(the_length / 3)
            let the_display_data = []
            for (let r = 1; r <= rows; r++) {
              let the_array = []
              for (let each_data_i in the_data) {
                if (each_data_i < r*3 && each_data_i >= (r-1)*3) {
                  let each_data = the_data[each_data_i]
                  the_array.push(each_data)
                }
              }
              the_display_data.push(the_array)
            }
            setIsLoaded(true)
            
            setArrayCourses(the_display_data)
          } else {
            setArrayCourses([])
          }
          
          getUserInfo()
        } catch (error) {
          if (error.response.status < 500) {
            setErrorMsg("Course Load Error")
          } else {
            setErrorMsg("Internal Server Error")
          }
          setDialogAlertOn(true)
        }
      };

      fetchData()
  }

  useEffect(
    () => {
      let filter = GetUrlHashValue("filter")

      let access_token_data = window.localStorage.getItem("access_token_data");
      let access_token_data_type = window.localStorage.getItem("access_token_data_type");

      let url = Configuration("main_host")+"/api/v1/course/categories";

      const fetchData = async () => {
        try {
          const {data: response} = await axios({
            method: "get",
            url: url,
            headers: { "Authorization": access_token_data_type + " " + access_token_data },
          });
          const the_data = response.data
          setCategoriesData(the_data)

          if (filter) {
            setCategoryFilter(filter)

            if (filter !== "null") {
              for (let category of the_data) {
                if (filter === category.id) {
                  setCategoryFilterDisplay({
                    avaliable: category.display_name,
                    unavaliable: category.display_name+" ปัจจุบันยังไม่มี",
                  })
                  break
                }
              }
            } else {
                setCategoryFilterDisplay({
                    avaliable: "หลักสูตรทั้งหมด",
                    unavaliable: "หลักสูตรทั้งหมดปัจจุบันยังไม่มี",
                  })
            }
            for (let category of the_data) {
              if (filter === category.id) {
                if (category.sub_category) {
                  setSubCategoriesData(category.sub_category)
                } else {
                  setSubCategoriesData([])
                }
              }
            }
          } else {
            setCategoryFilter("null")
          }

          setTimeout(() => { 
            reloadPage(filter)
          }, 1);

        } catch (error) {
          if (error.response.status < 500) {
            setErrorMsg("Category Load Error")
          } else {
            setErrorMsg("Internal Server Error")
          }
          setDialogAlertOn(true)
        }
      };

      fetchData()
      
    },
    []
  )

  //https://stackoverflow.com/questions/53824116/react-hooks-usestate-array

    return (
      <main>
        <NavBar isCourse={true} fixed="top"/>

        {is_loaded ? <div className={"container"}> 

          <div className={"course_header text-center"}>        

            {dialog_alert_on ? <AlertDialog alert_title={error_msg} alert_msg={""} parentCallback={closeAlertDialog}/> : ""}

            {is_first_login ? <FirstLoginDialog check_data={disability_input_enum} parentCallback={confirmFirstLogin}/> : ""}

            <div className={"row"}>
              <div className={'col-6 course_header_txt'}>
                <h1>
                <span className={'course_header_headtxt'}> Vulcan Academy </span><br/>
                <span className={'course_header_subtxt'}>แหล่งความรู้ที่ทุกคนเข้าถึงได้</span>
                </h1>
              </div>
              <div className={'col-5 align-self-center'}>
                <img className=" course_header_img img-fluid" alt="working" src={person_working_home_page}/>
              </div>
            </div>
          </div>

          <div className='course_cards_body'>

            <div className='course_filter_input_body'>
                <select className="filter_course_label_txt_box course_filter_input_body_1" name="category" defaultValue={category_filter} onChange={e => setValue(e.target.name, e.target.value)}>
                    <option value="null">หมวดหมู่หลักสูตรทั้งหมด</option>
                    <optgroup label="หมวดหมู่หลักสูตร">
                    { categories_data.map((item,index) => {
                      return (
                          <option key={index} value={item.id}>{Configuration("proper_text_display")[item.id+"_th"]}</option>
                        )
                      })}
                    </optgroup>
                  </select>
                {subcategories_data ? (subcategories_data.length > 0 ? <select className="filter_course_label_txt_box course_filter_input_body_2" name="subcategory" onChange={e => setValue(e.target.name, e.target.value)}>
                    <option value="null">หมวดหมู่ย่อยทั้งหมด</option>
                    <optgroup label={"หมวดหมู่ย่อย"}>
                    { subcategories_data.map((item,index) => {
                      return (
                          <option key={index} value={item.id}>{item.display_name}</option>
                        )
                      })}
                    </optgroup>
                  </select> : 
                  <select className="course_filter_input_body_2 filter_course_label_txt_box_disabled" name="subcategory" disabled>
                    <option value="null">ยังไม่มีหมวดหมู่ให้เลือก</option>
                  </select>
                  ) : ""}
                <label className="label_icon label_icon_search course_filter_input_body_3">
                  <input className="filter_course_label_txt_box_white insider_txt_box_with_icon" 
                  name="search" 
                  value={title_search}
                  placeholder="ค้นหา..."
                  onChange={e => setTitleSearch(e.target.value)} 
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      reloadPage(category_filter, subcategory_filter)
                    }
                  }}
                  />
                </label>
                <button 
                onClick={e => reloadPage(category_filter, subcategory_filter)}
                className="filter_course_label_txt_box_white filter_course_label_txt_box_white_btn course_filter_input_body_4">ค้นหา</button>
            </div>

          { courses.length > 0 ? 
            <div>
              <h4 className="card_header_txt">{category_filter_display.avaliable} {subcategory_filter !== "null" ? <span><br/>สำหรับหมวดหมู่: {subcategory_filter_display}</span> : ""}</h4>
              { courses.map((item,index) => {
                return (
                    <div key={index} className="home_list_item_box">
                      { item.map((sub_item,sub_index) => {
                        return (
                            <div key={sub_item.id} className="home_list_item">
                            <CourseIconItem displayOn={"home"} courseData={sub_item} is_in_profile={false}/>
                            </div>
                          )
                      })}
                    </div>
                  )
                })}
              </div>
            : <div className='course_cards_body'><h4 className="card_header_txt">{category_filter_display.unavaliable}{subcategory_filter !== "null" ? <span><br/>สำหรับหมวดหมู่: {subcategory_filter_display}</span> : "ไม่มีคอร์สตรงกับคำที่ค้นหา"}</h4></div>}
            </div>

        </div>: ""}

      <HomeFooter/>
      </main>
    );
 // }
}

export default Home;
