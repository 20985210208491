import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import Configuration from '../functions/Configuration';
import recheckAccessTokenIsExpired from '../functions/recheckAccessTokenIsExpired';
import getTokenData from '../functions/getTokenData';
import GetUrlHashValue from '../functions/GetUrlHashValue';
import NavBar from '../components/NavBar';
import CourseNaviBigBox from '../components/CourseNaviBigBox';
import HomeFooter from '../components/HomeFooter';
import AlertDialog from '../components/AlertDialog';
import logo from '../res/logo.svg';
import result_success_certificate from '../res/result_success_certificate.svg';

function CourseFinished() {
  const [course_id, setCourseID] = useState("")

  const [is_completed, setIsCompleted] = useState(false);

  const [lessons, setLessons] = useState([]);
  const [lesson_progress, setLessonProgress] = useState([]);

  const [course_data, setCourseData] = useState(null);
  const [user_data, setUserData] = useState(null);
  const [is_loaded, setLoaded] = useState(false)

  const navigate = useNavigate();

  const [dialog_alert_on, setDialogAlertOn] = useState(false)
  const [error_msg, setErrorMsg] = useState("")

  const closeAlertDialog = () => {
    setDialogAlertOn(false)
  }

  const backToCourseStart = (course_id) => {
      navigate('/course_enroll#id='+course_id);
    }

  const selectLesson = (lesson_id) => {
       navigate('/course_lesson_start#id='+course_id+"&course_title="+course_data.course_title+"&lesson_id="+lesson_id);
   }

  const getUserInfo = () => {
    const url = Configuration("user_host")+"/api/v1/user/me";
    let access_token_data = window.localStorage.getItem("access_token_data");
    let access_token_data_type = window.localStorage.getItem("access_token_data_type");

      const fetchData = async () => {
        try {
          const {data: response} = await axios({
            method: "get",
            url: url,
            headers: { "Authorization": access_token_data_type + " " + access_token_data },
          });
          //console.log(response)
          setUserData(response.data)
          setLoaded(true)
          
        } catch (error) {
          if (error.response.status < 500) {
            setErrorMsg("Load User Data Error")
          } else {
            setErrorMsg("Internal Server Error")
          }
          setDialogAlertOn(true)
        }
      };

      fetchData()
  }

  const downloadCertificate = () => {
    if (!is_completed) {
      setErrorMsg("Course not completed!")
      setDialogAlertOn(true)
      return
    }
      let access_token_data = window.localStorage.getItem("access_token_data");
      let access_token_data_type = window.localStorage.getItem("access_token_data_type");

      const url = Configuration("main_host")+"/api/v1/course/"+course_id+"/certificate";

      const fetchData = async () => {
        try {
          const {data: response} = await axios({
            method: "get",
            url: url,
            headers: { "Authorization": access_token_data_type + " " + access_token_data },
          });
          let the_data = response.data
          //console.log(the_data)

          //values :
          //the_data.certificate.source --> uri
          //the_data.completion_date
          //the_data.course_id
          //the_data.id
          //the_data.user_id

          window.open(the_data.certificate.source, "_blank");

        } catch (error) {
          if (error.response.status < 500) {
            setErrorMsg("Certificate Load Error")
          } else {
            setErrorMsg("Internal Server Error")
          }
          setDialogAlertOn(true)
        }
      };

      fetchData()
  }

   useEffect(
    () => {
      let get_course_id = GetUrlHashValue("id")
      setCourseID(get_course_id)

      let user_id = getTokenData("user_id")

      if (recheckAccessTokenIsExpired()) {
        navigate('/');
      }

      let access_token_data = window.localStorage.getItem("access_token_data");
      let access_token_data_type = window.localStorage.getItem("access_token_data_type");

      const url = Configuration("main_host")+"/api/v1/course/"+get_course_id;

      const url2 = Configuration("video_host")+"/api/v1/user/"+user_id+"/course/"+get_course_id+"/progress";

      const url3 = Configuration("video_host")+"/api/v1/course/"+get_course_id+"/lesson";

      const url4 = Configuration("video_host")+"/api/v1/user/"+user_id+"/course/"+get_course_id+"/progress";

      const fetchDataAll = async () => {
        try {
          const data_arr = await Promise.all([
            axios({
              method: "get",
              url: url,
              headers: { "Authorization": access_token_data_type + " " + access_token_data },
            }),
            axios({
              method: "get",
              url: url2,
              headers: { "Authorization": access_token_data_type + " " + access_token_data },
            }),
            axios({
              method: "get",
              url: url3,
              headers: { "Authorization": access_token_data_type + " " + access_token_data },
            }),
            axios({
              method: "get",
              url: url4,
              headers: { "Authorization": access_token_data_type + " " + access_token_data },
            })

          ]);
          //console.log(data_arr)

          for (let i in data_arr) {
            let index = parseInt(i)
            let response = data_arr[index].data

            if (index === 0) {
              let the_data = response.data
              setCourseData(the_data)
            } else if (index === 1) {
              let the_data = response.data
              setIsCompleted(the_data.is_completed)

              if (the_data) {
                if (!the_data.is_completed) {
                  backToCourseStart(get_course_id)
                }
              } else {
                backToCourseStart(get_course_id)
              }
            } else if (index === 2) {
              let the_data = response.data.lessons
              setLessons(the_data)
            } else if (index === 3) {
              let the_data = response.data
              setLessonProgress(the_data.lesson_progress)
              getUserInfo()
            } 

          }
          
        } catch (error) {
          if (error.response.status < 500) {
            setErrorMsg("Finish Page Load Error")
          } else {
            setErrorMsg("Internal Server Error")
          }
          setDialogAlertOn(true)
        }
      };

      fetchDataAll()
    },
    []
  )

    return (
      <main>
        <NavBar isCourse={true}/>
        <div className='container'>
      <div className={"course_body_back "}>

      {dialog_alert_on ? <AlertDialog alert_title={error_msg} alert_msg={""} parentCallback={closeAlertDialog}/> : ""}

      <div className={"course_body"}>

        {is_loaded ? <div className="home_item course_enroll_title">
          <span className="lesson_name">{course_data.course_title}</span>
        </div> : ""}

        <div className="home_item boxes course_item_big_box">

        {is_loaded ? <CourseNaviBigBox course_id={course_id} arrayType={"lessons"} lessonsArr={lessons} lessonProgressArr={lesson_progress} parentCallbackLesson={selectLesson}/> : ""}

        {is_loaded ? 
          <div className="course_main_box course_main_box_finished">

          <div className="quiz_title">{course_data.course_title}</div>

          <div className={"certificate_box_container"}>
          <div className={"certificate_box"}>
          <img src={logo} alt="Vulcan Coalition" width="200" height="76"/><br/><br/>
              <h2>ขอแสดงความยินดีแก่ คุณ {user_data.name} {user_data.lastname}</h2>
                <p>คุณได้ผ่านหลักสูตร {course_data.course_title} แล้ว</p>
          <img className="result_finished_img" alt="finished" src={result_success_certificate} width="110" height="100"/>
          </div>
          </div>

          <br/><br/>

          {course_data.is_certificated && course_data.certificate_template ? <button className="result_btn result_btn_center result_btn_finished" onClick={downloadCertificate}>ดาวน์โหลดประกาศนียบัตร</button> : <button className="result_btn result_btn_center result_btn_finished_greyed" disabled>ไม่มีประกาศนียบัตร</button>}

          </div> : ""}

        </div>

        <br/>
        <br/>
          <br/>
          <br/>
          <br/>
        
      </div>
      <HomeFooter/>
      </div>
      </div>
      </main>
    );
  
}

export default CourseFinished;
