import React, { useState, useEffect } from 'react';
import Shuffle from '../functions/Shuffle';
import YoutubeConvertToEmbed from '../functions/YoutubeConvertToEmbed';
import getTokenData from '../functions/getTokenData';
import play_vid_button from '../res/play_vid_button.svg';
import play_vid_button_disabled from '../res/play_vid_button_disabled.svg'
import default_video_layout from '../res/default_video_layout.svg'
import divide_line_thinner from '../res/divide_line_thinner.svg'
import Linkify from 'react-linkify';

function Question(props) {
  const parentCallback = props.parentCallback
  const index_no = props.index_no

  const [choices, setChoicesArray] = useState([]);
  const [chosen, setChosen] = useState('');

  const questionData = props.questionData
  const isRandomChoices = props.isRandomChoices

  const [selectedVideoSource, setVideoSource] = useState('');

  const [is_for_deaf, setIsForDeaf] = useState(false);

  const onValueChange = (e) => {
    setChosen(e.target.value)
    parentCallback(questionData.id, e.target.value, questionData.type)
  }

  const onValueTextChange = (e) => {
    parentCallback(questionData.id, e.target.value, questionData.type)
  }

  const changeVideoSource = (source) => {
    if (!source) {
      setVideoSource('')
      return
    }
    setVideoSource(source)
  }

  useEffect(
    () => {

      if (questionData.choices) {
        if (isRandomChoices) {
          setChoicesArray(Shuffle(questionData.choices))
        } else {
          setChoicesArray(questionData.choices)
        }
      } /*else if (rawChoices) {
        if (isRandomChoices) {
          setChoicesArray(Shuffle(rawChoices))
        } else {
          setChoicesArray(rawChoices)
        }
      }*/

      if (getTokenData("display_feature") === 4) {
        setIsForDeaf(true)
      } else {
        setIsForDeaf(false)
      }
    },
    []
  )

  return (
    <div className="question">
      <div className="boxes">
        {is_for_deaf ? <div  className='quiz_video_box'>
          {selectedVideoSource !== "" && selectedVideoSource ?
            <div>
              <div className='video_wrapper'>
                <iframe title="video_within_quiz" className="responsive_iframe quiz_video" src={YoutubeConvertToEmbed(selectedVideoSource)} allowFullScreen></iframe>
              </div>
            </div> : <div>
              <img className="choice_video_layout_def" src={default_video_layout}></img>
            </div>}
        </div> : ""}

        <div className={`answer_box${is_for_deaf ? "_deaf": ""}`}>
          <div className="boxes">
            {
              is_for_deaf ? <div>

                {questionData.video_source !== "" && questionData.video_source ?
                  <button className={"playvid_link_deaf"} onClick={() => changeVideoSource(questionData.video_source)}>
                    <img src={play_vid_button} alt="Play" width="20" height="20" />
                  </button> :
                  <button className={"playvid_link_deaf_disabled"}>
                    <img src={play_vid_button_disabled} alt="Play" width="20" height="20" />
                  </button>}

              </div> : ""
            }
            <Linkify
                    componentDecorator={(
                        decoratedHref,
                        decoratedText,
                        key
                    ) => (
                        <a href={decoratedHref} key={key} target="_blank">
                            {decoratedText}
                        </a>
                    )}
                >
                     <h2 className="question_text">Q{index_no + ". "} {questionData.question}</h2>
                </Linkify>
          </div>

          <br />
          {questionData.type === "mcq" ? (choices ? (choices.map((item, index) => {
            return (
              <div key={item.id + "_" + item.order}>
                <div className="boxes">
                  {is_for_deaf ? <div>

                    {item.video_source !== "" && item.video_source ?
                      <button className={"playvid_link_deaf"} onClick={() => changeVideoSource(item.video_source)}>
                        <img src={play_vid_button} alt="Play" width="20" height="20" /></button>
                      : <button className={"playvid_link_deaf_disabled"}>
                        <img src={play_vid_button_disabled} alt="Play" width="20" height="20" /></button>}
                  </div> : ""}
                  <label>
                    <input
                      type="radio"
                      value={item.id}
                      className="question_choice_button obj_checkbox_dark"
                      checked={chosen === item.id}
                      onChange={onValueChange}
                    />
                    <span className="question_choice_txt obj_checkbox_label_dark">{item.choice}</span>
                  </label>

                </div>

                <br /></div>
            )
          })
          ) : "") :
            <div><textarea className="choice_txt_area" rows="6" name="new_txtarea" defaultValue={""} placeholder="คำตอบ / Answer" onChange={onValueTextChange} /></div>
          }
        </div>

      </div>
      <img className='divide_line' src={divide_line_thinner} />


    </div>
  );

}

export default Question;