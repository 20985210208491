import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import Configuration from '../functions/Configuration';
import OutsiderNavbar from '../components/OutsiderNavbar';
import WelcomeBox from '../components/WelcomeBox';

function RegisterEmailInput() {
  const [input_email, setEmail] = useState("")

  const [is_error, setIsError] = useState(false)
  const [error_msg, setErrorMsg] = useState("")

  const navigate = useNavigate();

  const handleSubmit = () => {
    const url = Configuration("user_host") + "/api/v1/login/send-verify-email";
    let body = {
      email: input_email,
    }

    //console.log(body)

    const postData = async () => {
      try {
        const response = await axios.post(url, body);
        //console.log(response); 

        navigate('/register_email_sent#email=' + input_email)

      } catch (error) {
        if (error.response.status < 500) {
          //console.log(error.response)
          setErrorMsg("Invalid Email")
        } else {
          setErrorMsg("Internal Server Error")
        }

        setIsError(true)
      }
    };

    postData()
  }

  useEffect(
    () => {
      document.body.style.background = "#503e9d"
    },
    []
  )

  return (
    <div>

      <div>

        <OutsiderNavbar />

        <div className="boxes">
          <div className="init_form_box init_form_box_mini">
            <h4 className="outsider_txt">กรุณากรอกอีเมลที่ท่านต้องการใช้สร้างบัญชี</h4><br />

            {is_error ? <h6 className="outsider_login_error">{error_msg}</h6> : ""}

            <label className="label_icon label_icon_email">
              <input className="outsider_txt_box outsider_txt_box_login" type="email" name="email" placeholder="Email" onChange={e => setEmail(e.target.value)} />
            </label>
            <br />
            <div className="outsider_btns">
              <input className="outsider_btn_base outsider_btn_email_submit" type="submit" value="ยืนยัน" onClick={handleSubmit} />
            </div>

          </div>
          <WelcomeBox isRegis={true} />

        </div>

      </div>

    </div>
  );

}

export default RegisterEmailInput;
