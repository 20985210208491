import working_image from '../res/working_image.svg';

function WelcomeBox(props) {
    const is_regis = props.isRegis

    return (
      <div className="welcome_box"> 
            <h1 className="outsider_title_container">

            <span className="outsider_title">{is_regis ? "สร้างบัญชีใหม่" : "ยินดีต้อนรับสู่"}<br/>Vulcan Academy !</span><br/>

            {is_regis ? "" : <span className="outsider_txt_welcome highlight">แหล่งความรู้ที่ทุกคนเข้าถึงได้</span>}

            </h1>

            <img alt="person_working_at_computer" className="working_img" preserveAspectRatio="none" src={working_image}/>
      </div>   
    );

}

export default WelcomeBox;