import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import Configuration from '../functions/Configuration';
import recheckAccessTokenIsExpired from '../functions/recheckAccessTokenIsExpired';
import getTokenData from '../functions/getTokenData';
import GetUrlHashValue from '../functions/GetUrlHashValue';
import RenderMarkdownHTML from '../functions/RenderMarkdownHTML';
import YoutubeConvertToEmbed from '../functions/YoutubeConvertToEmbed';
import NavBar from '../components/NavBar';
import HomeFooter from '../components/HomeFooter';
import AlertDialog from '../components/AlertDialog';
import divide_line_thicker from '../res/divide_line_thicker.svg'

function CourseEnroll() {
  const [course_id, setCourseID] = useState("")
  const [course_app, setCourseApp] = useState("")
  const [is_loaded, setLoaded] = useState(false)
  const [course_data, setCourseData] = useState({})
  const [lessons, setLessons] = useState([]);
  const [lessons_loaded, setLessonsLoaded] = useState(false);

  const [is_enrolled, setIsEnrolled] = useState(false);

  const [is_completed, setIsCompleted] = useState(false);

  const [first_lesson_id, setFirstLessonID] = useState("");

  const navigate = useNavigate();

  const [dialog_alert_on, setDialogAlertOn] = useState(false)
  const [error_msg, setErrorMsg] = useState("")

  const closeAlertDialog = () => {
    setDialogAlertOn(false)
  }

  const startCourse = () => {
    if (is_enrolled) {
      navigate('/course_lesson_start#id=' + course_id + "&course_title=" + course_data.course_title + "&lesson_id=" + first_lesson_id + "&course_app=" + course_data.course_app);
    } else {
      let user_id = getTokenData("user_id")

      let access_token_data = window.localStorage.getItem("access_token_data");
      let access_token_data_type = window.localStorage.getItem("access_token_data_type");

      const url = Configuration("user_host") + "/api/v1/user-course/enroll";

      const postData = async () => {
        try {
          await axios({
            method: "post",
            data: {
              user_id: user_id,
              course_id: course_id,
            },
            url: url,
            headers: { "Authorization": access_token_data_type + " " + access_token_data },
          });
          //console.log(response)
          navigate('/course_lesson_start#id=' + course_id + "&course_title=" + course_data.course_title + "&lesson_id=" + first_lesson_id + "&course_app=" + course_data.course_app);

        } catch (error) {
          if (error.response.status < 500) {
            setErrorMsg("Enroll Error")
          } else {
            setErrorMsg("Internal Server Error")
          }
          setDialogAlertOn(true)
        }
      };

      postData()
    }
  }

  const concludeCourse = () => {
    navigate('/course_finished#id=' + course_id);
  }

  useEffect(
    () => {
      let get_course_id = GetUrlHashValue("id")
      setCourseID(get_course_id)

      let get_course_app = GetUrlHashValue("course_app")
      setCourseApp(get_course_app)

      let user_id = getTokenData("user_id")

      if (recheckAccessTokenIsExpired()) {
        navigate('/');
      }

      let access_token_data = window.localStorage.getItem("access_token_data");
      let access_token_data_type = window.localStorage.getItem("access_token_data_type");

      const url = Configuration("main_host") + "/api/v1/course/" + get_course_id;

      const url2 = Configuration("video_host") + "/api/v1/course/" + get_course_id + "/lesson";

      const url3 = Configuration("video_host") + "/api/v1/user/" + user_id + "/course/" + get_course_id + "/progress";

      const url4 = Configuration("user_host") + "/api/v1/user-course/check-enroll";

      const fetchDataAll = async () => {
        try {
          const data_arr = await Promise.all([
            axios({
              method: "get",
              url: url,
              headers: { "Authorization": access_token_data_type + " " + access_token_data },
            }),
            axios({
              method: "get",
              url: url2,
              headers: { "Authorization": access_token_data_type + " " + access_token_data },
            }),
            axios({
              method: "get",
              url: url3,
              headers: { "Authorization": access_token_data_type + " " + access_token_data },
            }),
            axios({
              method: "post",
              data: {
                user_id: user_id,
                course_id: get_course_id,
              },
              url: url4,
              headers: { "Authorization": access_token_data_type + " " + access_token_data },
            })

          ]);
          //console.log(data_arr)

          for (let i in data_arr) {
            let index = parseInt(i)
            let response = data_arr[index].data

            if (index === 0) {
              console.log('course data ', response.data)
              setCourseData(response.data)
            } else if (index === 1) {
              let the_data = response.data.lessons
              if (the_data) {
                setLessons(the_data)
                setLessonsLoaded(true)
                setFirstLessonID(the_data[0].id)
              } else {
                setLessons([])
                setLessonsLoaded(false)
              }
            } else if (index === 2) {
              let the_data = response.data
              setIsCompleted(the_data.is_completed)
            } else if (index === 3) {
              let the_data = response.data
              setIsEnrolled(the_data.result)
              setLoaded(true)
            }

          }

        } catch (error) {
          if (error.response.status < 500) {
            setErrorMsg("Course Load Error")
          } else {
            setErrorMsg("Internal Server Error")
          }
          setDialogAlertOn(true)
        }
      };

      fetchDataAll()
    },
    []
  )

  const renderToLessonCta = () => {
    if (lessons_loaded && lessons.length > 0) {
      switch (course_data.course_app) {
        case "video": {
          return (
            <button className="course_enroll_btn" onClick={startCourse}>
              {is_enrolled ?
                (is_completed ? "ทบทวนบทเรียน" : "เรียนต่อ")
                : "ลงทะเบียนเรียน"}
            </button>
          )
        }
        case "exam": {
          if (is_completed) {
            return <button className="course_enroll_btn_disabled" disabled>
              คุณได้ทำแบบทดสอบเสร็จสิ้นแล้ว
            </button>
          } else {
            return (
              <button className="course_enroll_btn" onClick={startCourse}>
                {is_enrolled ? "ทำแบบทดสอบต่อ" : "ลงทะเบียนทำแบบทดสอบ"}
              </button>
            )
          }
        }
        default: {
          //TODO: default course app
        }
      }
    }
  }
  const renderToCertificateCta = () => {
    if (is_completed && course_data.is_certificated && course_data.certificate_template && course_data.course_app === "video")
      return (
        <span><button className="course_enroll_btn" onClick={concludeCourse}>รับประกาศนียบัตร</button><br /><br /></span>
      )
  }

  const renderIsCertificate = () => {
    if(course_app === "video"){
      if(course_data.is_certificated && course_data.certificate_template){
        return "มี"
      }else{
        return "ไม่มี"
      }
    }else if(course_app === "exam"){
      return "-"
    }
    //TODO handle default app
  }


  return (
    <main>
      <NavBar isCourse={true} />
      {is_loaded ? <div className={"container"}>
        <div className={"course_body_back "}>

          {dialog_alert_on ? <AlertDialog alert_title={error_msg} alert_msg={""} parentCallback={closeAlertDialog} /> : ""}

          <div className={"course_body"}>

            <h1 className="home_item course_enroll_title">
              <span className="lesson_name_title lesson_name_large">{course_data.course_title}</span>
            </h1>

            <div className="home_item boxes course_item_big_box">

              <div className="enroll_long_box">

                {course_data.teaser.media_type === "video" ?
                  <div className="video_wrapper">
                    {course_data.teaser.video_type === "upload" ?
                      <video className={"responsive_iframe course_video"}
                        src={course_data.teaser.source}
                        controls /> :
                      <iframe title="teaser_vid_file" className="responsive_iframe course_video" src={YoutubeConvertToEmbed(course_data.teaser.source)} allowFullScreen />

                    }
                  </div> : <img className="enroll_item course_enroll_video" alt="teaser" src={course_data.teaser.source} />}

                <div className="course_des_box">

                  <h2 className="course_enroll_paragraph_title lesson_title_after">
                    รายละเอียดหลักสูตร
                  </h2>
                  <p className="course_enroll_paragraph" dangerouslySetInnerHTML={{ __html: RenderMarkdownHTML(course_data.overview_desc, true) }}></p>

                  <img className='divide_line' src={divide_line_thicker} />

                  <h2 className="course_enroll_paragraph_title lesson_title_after course_enroll_paragraph_title_after">
                    สิ่งที่คุณจะได้เรียนรู้จากหลักสูตรนี้
                  </h2>
                  <p className="course_enroll_paragraph" dangerouslySetInnerHTML={{ __html: RenderMarkdownHTML(course_data.learning_desc, true) }}></p>

                  <img className='divide_line' src={divide_line_thicker} />

                  <h2 className="course_enroll_paragraph_title lesson_title_after course_enroll_paragraph_title_after">
                    แบบฝึกหัดและเกณฑ์การผ่านหลักสูตร
                  </h2>
                  <p className="course_enroll_paragraph" dangerouslySetInnerHTML={{ __html: RenderMarkdownHTML(course_data.assignment_desc, true) }}></p>

                  <img className='divide_line' src={divide_line_thicker} />

                  <h2 className="course_enroll_paragraph_title lesson_title_after course_enroll_paragraph_title_after">
                    ครูผู้สอน และ ทีมงาน
                  </h2>
                  <p className="course_enroll_paragraph">{course_data.author}</p>

                  {/*lessons_loaded ? (lessons.length > 0 ? <h4>Lessons List: </h4> : "") : ""*/}

                  {/*lessons_loaded ? (lessons.map((item,index) => {
                      return (
                          <div key={item.id}>
                            - {item.title}
                          </div>
                        )
                    })
                    ) : ""*/}


                </div>

              </div>

              <div className="course_action_box">
                <div className="course_action_box_inner">
                  <table className="course_action_table">
                    <tbody>
                      <tr className="course_action_row">
                        <td className="course_action_table_left">ราคา</td>
                        <td className="course_action_table_right">ฟรี</td>
                      </tr>
                      <tr className="course_action_row">
                        <td className="course_action_table_left">ครูผู้สอน</td>
                        <td className="course_action_table_right">{course_data.author}</td>
                      </tr>
                      <tr className="course_action_row">
                        <td className="course_action_table_left">ความยาว</td>
                        <td className="course_action_table_right">{course_data.duration} ชั่วโมง</td>
                      </tr>
                      <tr className="course_action_row">
                        <td className="course_action_table_left">จำนวนบทเรียน</td>
                        <td className="course_action_table_right">{lessons_loaded ? (lessons.length > 0 ? lessons.length : "0") : "0"}</td>
                      </tr>
                      <tr className="course_action_row">
                        <td className="course_action_table_left">ประกาศนียบัตร</td>
                        <td className="course_action_table_right">{renderIsCertificate()}</td>
                      </tr>
                      <tr className="course_action_row">
                        <td className="course_action_table_left">ภาษา</td>
                        <td className="course_action_table_right">{Configuration("proper_text_display")[course_data.language]}</td>
                      </tr>
                      <tr className="course_action_row">
                        <td className="course_action_table_left">การเข้าถึง</td>
                        <td className="course_action_table_right">ตลอดชีพ</td>
                      </tr>
                    </tbody>
                  </table>
                  <br />


                  {renderToLessonCta()}

                  <br /><br />

                  {renderToCertificateCta()}

                </div>
              </div>

            </div>

            <br />

          </div>
          <HomeFooter />
        </div>
      </div> : ""}
    </main>
  );

}

export default CourseEnroll;
