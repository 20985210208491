import React from 'react';
import { useNavigate } from "react-router-dom";
import logo from '../res/logo.svg';

function OutsiderNavbar() {

  const navigate = useNavigate();

  const backToHome = () => {
       navigate('/login');
   }

  const externalLink = (link) => {
    if (!link || link === "") {
      navigate('/404');
    } else {
      window.open(link, '_blank').focus();
    }
       
   }

    return (
      <div>

          <div className={"navbar"}><button className="navbar2_title" onClick={backToHome}><img src={logo} alt="Vulcan Coalition (กลับหน้าแรก)" width="200" height="76"/> </button>
          <div className="navbar2_right">
          <button className="navbar_item" onClick={() => externalLink(null)}>ทำความรู้จัก Vulcan Academy</button>
          <button className="navbar_item" onClick={() => externalLink(null)}>วิธีใช้งาน</button>
          <button className="navbar_item" onClick={() => externalLink(null)}>คำถามที่พบบ่อย (FAQ)</button>
          </div>
          </div>

        </div>
    );

}

export default OutsiderNavbar;