import React, { useState, useEffect } from 'react';

function NewCourseProgress(props) {
  const progress = props.progress

   useEffect(
    () => {
    },
    []
  )

    return (
      <div className="Stepper">

      <div className={"Stepper__step "+(progress >= 1 ? 'active ' : ' ')}>
          <div className={"Stepper__indicator "+(progress >= 1 ? 'active ' : ' ')}>
            <span className={"Stepper__info "+(progress >= 1 ? 'active ' : ' ')}>1</span>
            </div>
            <div className={"Stepper__label "+(progress >= 1 ? 'active ' : ' ')}>Add Information</div>
      </div>

      <div className={"Stepper__step "+(progress >= 2 ? 'active ' : ' ')}>
          <div className={"Stepper__indicator "+(progress >= 2 ? 'active ' : ' ')}>
            <span className={"Stepper__info "+(progress >= 2 ? 'active ' : ' ')}>2</span>
            </div>
            <div className={"Stepper__label "+(progress >= 2 ? 'active ' : ' ')}>Add Curriculum</div>
      </div>

      <div className={"Stepper__step "+(progress >= 3 ? 'active ' : ' ')}>
          <div className={"Stepper__indicator "+(progress >= 3 ? 'active ' : ' ')}>
            <span className={"Stepper__info "+(progress >= 3 ? 'active ' : ' ')}>3</span>
            </div>
            <div className={"Stepper__label "+(progress >= 3 ? 'active ' : ' ')}>Add Test</div>
      </div>

      <div className={"Stepper__step "+(progress >= 4 ? 'active ' : ' ')}>
          <div className={"Stepper__indicator "+(progress >= 4 ? 'active ' : ' ')}>
            <span className={"Stepper__info "+(progress >= 4 ? 'active ' : ' ')}>4</span>
            </div>
            <div className={"Stepper__label "+(progress >= 4 ? 'active ' : ' ')}>Publish</div>
      </div>

      </div>
    );
  
}

export default NewCourseProgress;
