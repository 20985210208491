import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import Configuration from '../functions/Configuration';
import OutsiderNavbar from '../components/OutsiderNavbar';
import WelcomeBox from '../components/WelcomeBox';

function ForgetPassword() {
  const [input_email, setEmail] = useState("")

  const [is_error, setIsError] = useState(false)
  const [error_msg, setErrorMsg] = useState("")

  const navigate = useNavigate();

  const handleSubmit = () => {
    const url = Configuration("user_host") + "/api/v1/login/initiate-password-reset";
    let body = {
      email: input_email,
    }

    const postData = async () => {
      try {
        const response = await axios.post(url, body);

        setIsError(false)

        navigate('/forgot_password_email_sent', {state:{email:input_email}})

      } catch (error) {
        if (error.response.status < 500) {
          setErrorMsg("Invalid Email")
        } else {
          setErrorMsg("Internal Server Error")
        }

        setIsError(true)
      }
    };

    postData()
  }

  useEffect(
    () => {
      document.body.style.background = "#503e9d"
    },
    []
  )

  return (
    <div>

      <div>

        <OutsiderNavbar />

        <div className="boxes">
          <div className="init_form_box init_form_box_mini">
            <h4 className="outsider_txt">กรุณากรอกอีเมลเพื่อรับลิงก์ตั้งรหัสผ่านใหม่</h4><br />

            {is_error ? <h6 className="outsider_login_error">{error_msg}</h6> : ""}

            <label className="label_icon label_icon_email">
              <input className="outsider_txt_box outsider_txt_box_login" type="email" name="email" placeholder="Email" onChange={e => setEmail(e.target.value)} />
            </label>
            <br />
            <div className="outsider_btns">
              <input className="outsider_btn_base outsider_btn_email_submit" type="submit" value="ส่งลิ้งค์ตั้งรหัสผ่านใหม่" onClick={handleSubmit} />
            </div>

          </div>

          <WelcomeBox isRegis={false} />

        </div>

      </div>

    </div>
  );

}

export default ForgetPassword;
