import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import Configuration from '../functions/Configuration';
import recheckAccessTokenIsExpired from '../functions/recheckAccessTokenIsExpired';
import ProfileMenu from '../components/ProfileMenu';
import HomeFooter from '../components/HomeFooter';
import AlertDialog from '../components/AlertDialog';
import NavBar from '../components/NavBar';

function ProfileStudentSettings() {
  const [user_data, setUserData] = useState(null);
  const [user_data_loaded, setUserDataLoaded] = useState(false);

  const [chosen, setChosen] = useState(0);

  const [disability_input_enum, setDisabilityInputEnum] = useState([]);

  /*const [snd1_on, setSND1On] = useState(false);
  const [snd2_on, setSND2On] = useState(false);*/

  const navigate = useNavigate();

  const [dialog_alert_on, setDialogAlertOn] = useState(false)
  const [error_msg, setErrorMsg] = useState("")

  const closeAlertDialog = () => {
    setDialogAlertOn(false)
  }

  const setCheckBoxValue = (value, check) => {
    setUserDataLoaded(false)
    editUserInfo(parseInt(value))
  }

  /*const setSwitchValue = (value, check) => {
    if (value === "snd1") {
      setSND1On(check)
    } else {
      setSND2On(check)
    }
  }*/

  const getUserInfo = () => {
    const url = Configuration("user_host")+"/api/v1/user/me";
    let access_token_data = window.localStorage.getItem("access_token_data");
    let access_token_data_type = window.localStorage.getItem("access_token_data_type");

      const fetchData = async () => {
        try {
          const {data: response} = await axios({
            method: "get",
            url: url,
            headers: { "Authorization": access_token_data_type + " " + access_token_data },
          });
          //console.log(response)
          setUserData(response.data)
          setChosen(response.data.display_feature)
          fetchData2()
          
        } catch (error) {
          if (error.response.status < 500) {
            setErrorMsg("Load User Data Error")
          } else {
            setErrorMsg("Internal Server Error")
          }
          setDialogAlertOn(true)
        }
      };

      const url2 = Configuration("user_host")+"/api/v1/enum/display-feature-info";

      const fetchData2 = async () => {
        try {
          const {data: response} = await axios.get(url2);
          //console.log(response);
          setDisabilityInputEnum(response.data)

          setUserDataLoaded(true)
        } catch (error) {
          if (error.response.status < 500) {
            setErrorMsg("Load Error")
          } else {
            setErrorMsg("Internal Server Error")
          }
          setDialogAlertOn(true)
        }
      };

      fetchData()
  }

  const editUserInfo = (new_display_feature) => {
    const url = Configuration("user_host")+"/api/v1/user/me";
    let access_token_data = window.localStorage.getItem("access_token_data");
    let access_token_data_type = window.localStorage.getItem("access_token_data_type");

    let body = user_data
    body["display_feature"] = new_display_feature
    body["address"]["province_id"] = user_data.address.province.id
    body["address"]["district_id"] = user_data.address.district.id
    body["address"]["subdistrict_id"] = user_data.address.subdistrict.id

    //console.log(body)

      const fetchData = async () => {
        try {
          await axios({
            method: "patch",
            url: url,
            data: body,
            headers: { "Authorization": access_token_data_type + " " + access_token_data },
          });
          //console.log(response)

          fetchData2()
          
        } catch (error) {
          if (error.response.status < 500) {
            setErrorMsg("Edit Input Error")
          } else {
            setErrorMsg("Internal Server Error")
          }
          setDialogAlertOn(true)
        }
      };

      const url2 = Configuration("user_host")+"/api/v1/login/refresh";
      let r_token = window.localStorage.getItem("refresh_token_data");
      let r_token_body = {
        refresh_token: r_token,
      }

      const fetchData2 = async () => {
        try {
          const {data: response} = await axios({
            method: "post",
            url: url2,
            data: r_token_body,
          });
          //console.log(response);

          let access_token = response.data.access_token
          let refresh_token = response.data.refresh_token
          let token_type = response.data.token_type

          window.localStorage.setItem("access_token_data", access_token);
          window.localStorage.setItem("refresh_token_data", refresh_token);
          window.localStorage.setItem("access_token_data_type", token_type);

          getUserInfo()

        } catch (error) {
          if (error.response.status < 500) {
            setErrorMsg("Load Error")
          } else {
            setErrorMsg("Internal Server Error")
          }
          setDialogAlertOn(true)
        }
      };

      fetchData()
  }

   useEffect(
    () => {

      if (recheckAccessTokenIsExpired()) {
        navigate('/');
      }

      getUserInfo()

    },
    []
  )

    return (
      <main>
        <NavBar isCourse={true}/>
        <div className='container'>
    <div className={"course_body_back "}>
      <div className={"course_body_profile"}>

        {dialog_alert_on ? <AlertDialog alert_title={error_msg} alert_msg={""} parentCallback={closeAlertDialog}/> : ""}

        <div className="boxes">
          <ProfileMenu selected_menu_init={3}/>

          <div className="profile_detail_box">
            {user_data_loaded ? <div>
              
              <div className="admin_profile_detail_title">
                <h4 className="card_header_txt">ตั้งค่า</h4>
              </div>

              <br/>

              <div className="boxes">

              {/*<div className="settings_left_box">

              <h4 className="settings_header">เสียงอ่านหน้าจอ</h4><br/>

              <span className="settings_subheader">เปิด-ปิดเสียงอ่าน</span> 

              <label className="switch">
                <input type="checkbox" name="snd1" value="snd1" checked={snd1_on} onChange={e => setSwitchValue(e.target.name, e.target.checked)}/>
                <span className="slider round"></span>
              </label>

              <br/><br/>

              <span className="settings_subheader">ความดังเสียงอ่าน</span>

              <br/><br/>

              <span className="settings_subheader">ความเร็วเสียงอ่าน</span>

              <br/><br/>

              <br/>

              <h4 className="settings_header">เสียงประกอบ / Sound Effect</h4><br/>

              <span className="settings_subheader">เปิด-ปิดเสียงประกอบ</span>

              <label className="switch">
                <input type="checkbox" name="snd2" value="snd2" checked={snd2_on} onChange={e => setSwitchValue(e.target.name, e.target.checked)}/>
                <span className="slider round"></span>
              </label>

              <br/><br/>

              <span className="settings_subheader">ความดังเสียงอ่าน</span>

              <br/><br/>

              <br/>

              <h4 className="settings_header">การแสดงผลบนหน้าจอ</h4><br/>

              <span className="settings_subheader">ธีมสี</span>

              <br/><br/>

              <span className="settings_subheader">ขนาดตัวอักษร</span>

              <br/><br/>

              <span className="settings_subheader">ขนาดปุ่มการทำงาน</span>

              <br/><br/>

              <br/>

              </div>*/}

              <div className="settings_right_box">

              <h4 className="settings_header">ลักษณะฟีเจอร์การใช้งาน</h4><br/>

        <label className="settings_label">
            <input className="obj_checkbox_dark" type="checkbox" name={disability_input_enum[0].name} value={disability_input_enum[0].id} checked={chosen === disability_input_enum[0].id} onChange={e => setCheckBoxValue(e.target.value, e.target.checked)}/>
              <span className="obj_checkbox_label_dark"><b>ข้าพเจ้าไม่มีความจำเป็นต้องใช้ฟีเจอร์อำนวยความสะดวก</b></span><br/>
              <span className="obj_checkbox_label_dark_small">เหมาะสำหรับผู้เรียนที่สามารถเข้าถึงข้อมูลประเภทภาพ เสียง และข้อความได้ตามปกติ</span><br/>
            </label><br/><br/>

        <label className="settings_label">
            <input className="obj_checkbox_dark" type="checkbox" name={disability_input_enum[1].name} value={disability_input_enum[1].id} checked={chosen === disability_input_enum[1].id} onChange={e => setCheckBoxValue(e.target.value, e.target.checked)}/>
              <span className="obj_checkbox_label_dark"><b>ข้าพเจ้าต้องการใช้ Screen Reader</b></span><br/>
              <span className="obj_checkbox_label_dark_small">เหมาะสำหรับผู้เรียนกลุ่มตาบอดและสายตาเลือนรางที่มีข้อจำกัดด้านการเข้าถึงข้อมูลด้านรูปภาพ</span><br/>
            </label><br/><br/>

        <label className="settings_label">
            <input className="obj_checkbox_dark" type="checkbox" name={disability_input_enum[2].name} value={disability_input_enum[2].id} checked={chosen === disability_input_enum[2].id} onChange={e => setCheckBoxValue(e.target.value, e.target.checked)}/>
              <span className="obj_checkbox_label_dark"><b>ข้าพเจ้าต้องการใช้ล่ามภาษามือ</b></span><br/>
              <span className="obj_checkbox_label_dark_small">เหมาะสำหรับผู้เรียนกลุ่มหูหนวกหรือหูตึงที่มีความจำเป็นต้องใช้ล่ามภาษามือ</span><br/>
            </label><br/><br/>

              </div>

              </div>

            </div> : <h4 className="card_header_txt">Loading...</h4>}
          </div>
        </div>
      </div>
        <HomeFooter/>
      </div>
      </div>
      </main>
    );
  
}

export default ProfileStudentSettings;
