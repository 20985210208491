import React, { useEffect } from 'react';

function NewCourseHeader(props) {

  const parentCallback = props.parentCallback

   useEffect(
    () => {
    },
    []
  )

    return (
      <div className="boxes">

              <div className="admin_profile_detail_title">
                <h2>Create new course</h2>
              </div>

              <div className="admin_profile_detail_button_container">
                <button className="admin_profile_detail_button" onClick={() => parentCallback(false)}>Save</button>
              </div>

      </div>
    );
  
}

export default NewCourseHeader;
