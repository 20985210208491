import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import Configuration from '../functions/Configuration';
import recheckAccessTokenIsExpired from '../functions/recheckAccessTokenIsExpired';
import getCurrentUserType from '../functions/getCurrentUserType';
import SimplifyDateString from '../functions/SimplifyDateString';
import AlertDialog from '../components/AlertDialog';
import ConfirmDialog from '../components/ConfirmDialog';
import ProfileMenu from '../components/ProfileMenu';
import UserAdminDropdown from '../components/UserAdminDropdown';
import NavBar from '../components/NavBar';
import HomeFooter from '../components/HomeFooter';

function ProfileAdminUserList() {
  const [is_loaded, setIsLoaded] = useState(false)
  const [user_list, setUserList] = useState([
      {
        id: "aaaaaaaaa",
        name:"aaa",
        email: "abadaf",
        disability_type: "4535",
        user_type: "fvmx",
        course_enrolled: 0,
        joined: "02ujfjf",
      },
      {
        id: "aafdsfsdfaaaaaa",
        name:"aavxcvxa",
        email: "abadbbbvcbaf",
        disability_type: "4ffdfss535",
        user_type: "fvmx",
        course_enrolled: 5,
        joined: "02ujfjf",
      },
      {
        id: "aaasfdfsdaaaaa",
        name:"fdfdsfaaa",
        email: "abaddfsssdfdsaf",
        disability_type: "45343435",
        user_type: "fvmx",
        course_enrolled: 777,
        joined: "dfsf",
      },
    ])

  const [dialog_confirm_on, setDialogConfirmOn] = useState(false)
  const [dialog_data, setDialogData] = useState(false)

  const [dialog_alert_on, setDialogAlertOn] = useState(false)
  const [error_msg, setErrorMsg] = useState("")

  const navigate = useNavigate();

  const activateDeleteDialog = (course_id) => {
    setDialogConfirmOn(true)
    setDialogData(course_id)
  }

  const cancelDeleteDialog = () => {
    setDialogConfirmOn(false)
  }

  const closeAlertDialog = () => {
    setDialogAlertOn(false)
  }

   useEffect(
    () => {

      if (recheckAccessTokenIsExpired() || getCurrentUserType() !== "admin") {
        navigate('/');
      }

      setIsLoaded(true)

      //reloadTable()

    },
    []
  )

    return (
      <main>
        <NavBar isCourse={true}/>
        <div className='container'>
      <div className={"course_body_profile"}>

        {dialog_alert_on ? <AlertDialog alert_title={error_msg} alert_msg={""} parentCallback={closeAlertDialog}/> : ""}

        {dialog_confirm_on ? <ConfirmDialog confirm_title={"คุณต้องการจะลบใช่หรือไม่?"} confirm_msg={""} dialog_data={dialog_data} parentCallbackCancel={cancelDeleteDialog}/> : ""}

        <div className="boxes">
          <ProfileMenu selected_menu_init={1}/>

          <div className="admin_profile_detail_box">

          {is_loaded ? <div>

            <div className="boxes">

              <div className="admin_profile_detail_title">
                <h2>ผู้เรียนทั้งหมด</h2>
              </div>

              <div className="admin_profile_detail_button_container">
                <button className="admin_profile_detail_button">Export CSV</button>
              </div>

            </div>

            <div className="course_filter_input_body_admin">
              <select className="course_list_search_1 filter_course_label_txt_box course_filter_input_body_1" name="filter">
                <option disabled value="null" hidden>Published?</option>
                <optgroup label="Published?">
                  <option value="published">Published = Yes</option>
                  <option value="unpublished">Published = No</option>
                </optgroup>
              </select>

              <label className="label_icon label_icon_search course_filter_input_body_3">
                  <input className="filter_course_label_txt_box_white insider_txt_box_with_icon" 
                  name="author" 
                  placeholder="ค้นหาตาม Author"
                  />
                </label>
            </div>

        <table className="course_list_table">
         <thead className="course_list_thread">
           <tr className="course_list_tr">
             <th className="course_list_th"></th>
             <th className="course_list_th">Name</th>
             <th className="course_list_th">E-mail</th>
             <th className="course_list_th">PWD Type</th>
             <th className="course_list_th">User Status</th>
             <th className="course_list_th">Total enrolled Courses</th>
             <th className="course_list_th">Joined</th>
             <th className="course_list_th">Actions</th>
          </tr>
         </thead>
         <tbody className="course_list_tbody">
          {user_list ? (user_list.length > 0 ? (user_list.map((key, index) => {
            //console.log(key)
            return (
              <tr className="course_list_tr" key={key.id}>
                <td className="course_list_td"></td>
                <td className="course_list_td">{key.name}</td>
                <td className="course_list_td">{key.email}</td>
                <td className="course_list_td">{key.disability_type}</td>
                <td className="course_list_td">{key.user_type}</td>
                <td className="course_list_td">{key.course_enrolled}</td>
                <td className="course_list_td">{key.joined}</td>
                <td className="course_list_td"><UserAdminDropdown userID={key.id}/></td>
              </tr>
            );
          })) : (<tr className="course_list_tr">
                <td className="course_list_td"></td>
                <td className="course_list_td">ไม่พบรายการใดๆ</td>
                <td className="course_list_td"></td>
                <td className="course_list_td"></td>
                <td className="course_list_td"></td>
                <td className="course_list_td"></td>
                <td className="course_list_td"></td>
                <td className="course_list_td"></td>
              </tr>)
          ) : 
              (<tr className="course_list_tr">
                <td className="course_list_td"></td>
                <td className="course_list_td">Loading...</td>
                <td className="course_list_td"></td>
                <td className="course_list_td"></td>
                <td className="course_list_td"></td>
                <td className="course_list_td"></td>
                <td className="course_list_td"></td>
                <td className="course_list_td"></td>
              </tr>)}
          </tbody>
        </table>

          </div> : ""}

          </div>
        </div>
      
        
      </div>
      <HomeFooter/>
      </div>
      </main>
    );
  
}

export default ProfileAdminUserList;
