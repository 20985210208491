import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import axios from 'axios';
import Configuration from '../functions/Configuration';
import OutsiderNavbar from '../components/OutsiderNavbar';

function RegisterSuccess() {
  const { state } = useLocation();

  const [input_email, setEmail] = useState("")

  const [is_error, setIsError] = useState(false)
  const [error_msg, setErrorMsg] = useState("")

  const navigate = useNavigate();

  const backToLogin = () => {
    navigate('/login');
   } 

  useEffect(
    () => {
      document.body.style.background = "#503e9d"

      let is_debug_mode = false //TURN FALSE WHEN FINISHED DEBUGGING OR TESTING!!!!!!**********

      if (!is_debug_mode) {
        if (!state) {
          navigate('/login')
          return
        }
        let truth = state.truth
        if (!truth) {
          navigate('/login');
        }
      } 
      
    },
    []
  )

    return (
      <div>

        <div>

          <OutsiderNavbar/>

          <div className="boxes">

            <div className="msg_regis_box">  
              <h1 className="outsider_txt outsider_txt_regis_success"> การสร้างบัญชีผู้ใช้งานของท่านเสร็จสิ้นแล้ว</h1><br/><br/>

              <div className="outsider_btns">
                <input className="outsider_btn_base outsider_btn_regis_success" type="submit" value="เข้าสู่ระบบ" onClick={backToLogin}/>
              </div>

            </div>

          </div>

        </div>

      </div>
    );
  
}

export default RegisterSuccess;
