import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './res/bootstrap.min.css';
import './style.css';
import Init from './pages/Init';
import PageNotFound from './pages/PageNotFound';
import Login from './pages/Login';
import ForgotPassword from './pages/ForgotPassword';
import ForgotPasswordEmailSent from './pages/ForgotPasswordEmailSent'
import VerifyResetPassword from './pages/VerifyResetPassword';
import ResetPassword from './pages/ResetPassword';
import ResetPasswordInvalid from './pages/ResetPasswordInvalid';
import ResetPasswordSuccess from './pages/ResetPasswordSuccess'
import Register from './pages/Register';
import RegisterEmailInput from './pages/RegisterEmailInput';
import RegisterEmailInvalidLink from './pages/RegisterEmailInvalidLink';
import RegisterEmailSent from './pages/RegisterEmailSent';
import RegisterSuccess from './pages/RegisterSuccess';
import VerifyEmail from './pages/VerifyEmail';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Home from './pages/Home';
import CourseEnroll from './pages/CourseEnroll';
import CourseLessonStart from './pages/CourseLessonStart';
import CourseQuiz from './pages/CourseQuiz';
import CourseQuizResult from './pages/CourseQuizResult';
import CourseFinished from './pages/CourseFinished';
import ProfileAdminCourseList from './pages/ProfileAdminCourseList';
import ProfileAdminNewCoursePart1 from './pages/ProfileAdminNewCoursePart1';
import ProfileAdminNewCoursePart2 from './pages/ProfileAdminNewCoursePart2';
import ProfileAdminNewCoursePart3 from './pages/ProfileAdminNewCoursePart3';
import ProfileAdminNewCoursePart4 from './pages/ProfileAdminNewCoursePart4';
import ProfileAdminUserList from './pages/ProfileAdminUserList';
import ProfileAdminCourseUserList from './pages/ProfileAdminCourseUserList';
import ProfileAdminGroupList from './pages/ProfileAdminGroupList';
import ProfileAdminViewStudent from './pages/ProfileAdminViewStudent';
import ProfileStudent from './pages/ProfileStudent';
import ProfileStudentCurrentCourses from './pages/ProfileStudentCurrentCourses';
import ProfileStudentCompletedCourses from './pages/ProfileStudentCompletedCourses';
import ProfileStudentSettings from './pages/ProfileStudentSettings';

ReactDOM.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Init />} />
      <Route path="/login" element={<Login />} />
      <Route path="/forgot_password" element={<ForgotPassword />} />
      <Route path='/reset_password' element={<ResetPassword />} />
      <Route path='/forgot_password_email_sent' element={<ForgotPasswordEmailSent />} />
      <Route path='/reset_password_invalid' element={<ResetPasswordInvalid />} />
      <Route path='/verify_reset_password' element={<VerifyResetPassword />} />
      <Route path='/reset_password_success' element={<ResetPasswordSuccess />} />
      <Route path="/register" element={<Register />} />
      <Route path="/register_email_input" element={<RegisterEmailInput />} />
      <Route path="/register_invalid_link" element={<RegisterEmailInvalidLink />} />
      <Route path="/register_email_sent" element={<RegisterEmailSent />} />
      <Route path="/register_success" element={<RegisterSuccess />} />
      <Route path="/verify_email" element={<VerifyEmail />} />
      <Route path="/policy" element={<PrivacyPolicy />} />
      <Route path="/home" element={<Home />} />
      <Route path="/course_enroll" element={<CourseEnroll />} />
      <Route path="/course_lesson_start" element={<CourseLessonStart />} />
      <Route path="/course_quiz" element={<CourseQuiz />} />
      <Route path="/course_quiz_result" element={<CourseQuizResult />} />
      <Route path="/course_finished" element={<CourseFinished />} />
      <Route path="/profile_admin_course_list" element={<ProfileAdminCourseList />} />
      <Route path="/profile_admin_new_course_1" element={<ProfileAdminNewCoursePart1 />} />
      <Route path="/profile_admin_new_course_2" element={<ProfileAdminNewCoursePart2 />} />
      <Route path="/profile_admin_new_course_3" element={<ProfileAdminNewCoursePart3 />} />
      <Route path="/profile_admin_new_course_4" element={<ProfileAdminNewCoursePart4 />} />
      <Route path="/profile_admin_user_list" element={<ProfileAdminUserList />} />
      <Route path="/profile_admin_course_user_list" element={<ProfileAdminCourseUserList />} />
      <Route path="/profile_admin_group_list" element={<ProfileAdminGroupList />} />
      <Route path="/profile_admin_view_student" element={<ProfileAdminViewStudent />} />
      <Route path="/profile_student" element={<ProfileStudent />} />
      <Route path="/profile_student_current_courses" element={<ProfileStudentCurrentCourses />} />
      <Route path="/profile_student_completed_courses" element={<ProfileStudentCompletedCourses />} />
      <Route path="/profile_student_settings" element={<ProfileStudentSettings />} />
      <Route path="/404" element={<PageNotFound />} />
    </Routes>
  </BrowserRouter>,
  document.getElementById('root')
);
