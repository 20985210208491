import React from 'react';
import { useScroll, useScrollSequence } from 'react-scroll-hooks';
import icon_checkbox_complete from '../res/icon_checkbox_complete.svg'
import icon_checkbox_incomplete from '../res/icon_checkbox_incomplete.svg'
import divide_line_short from '../res/divide_line_short.svg'

//ref : https://www.npmjs.com/package/react-scroll-hooks

function CourseNaviBigBox(props) {
  const parentCallbackLesson = props.parentCallbackLesson
  const parentCallbackQuiz = props.parentCallbackQuiz

  const lessonsArr = props.lessonsArr
  const quizesArr = props.quizesArr

  const lessonProgressArr = props.lessonProgressArr
  const quizProgressArr = props.quizProgressArr

  const containerRef = React.useRef();

  const { createScrollRef, next, previous, goToPosition, active } = useScrollSequence({
    initialActive: 0,
    verticalOffset: 0,
    scrollSpeed: 50,
    containerRef
  });

  const callbackQuiz = (item_id, index) => {
    parentCallbackQuiz(item_id)
    goToPosition(index)
  }

  const callbackLesson = (item_id, index) => {
    parentCallbackLesson(item_id)
    if (quizesArr) {
      goToPosition(index + quizesArr.length)
    } else {
      goToPosition(index)
    }
  }

  const getLessonProgress = (lesson_id) => {
    return lessonProgressArr.find(lesson_progress => lesson_progress.lesson_id === lesson_id)
  }

  return (
    <nav className="course_navi_big_box" ref={containerRef}>
      <h2 className="course_navi_big_box_title">
        Overview
      </h2>

      {
        lessonsArr ?
          lessonsArr.map((lesson, lessonIndex) => {
            //console.log(lessonsArr, lessonProgressArr)
            return (
              <div key={lessonIndex}>
                {/* LESSON */}
                <button key={lessonIndex} onClick={e => callbackLesson(lesson.id, lessonIndex)} className="lesson_box boxes">

                  {/* LESSON PROGRESS */}
                  {lessonProgressArr ?
                    (getLessonProgress(lesson.id) ?
                      <div>
                        {
                          getLessonProgress(lesson.id).is_completed ?
                            <img src={icon_checkbox_complete} className="icon_checkbox_navbar" /> :
                            <img src={icon_checkbox_incomplete} className="icon_checkbox_navbar" />
                        }
                      </div> :
                      <img src={icon_checkbox_incomplete} className="icon_checkbox_navbar" />) :
                    <img src={icon_checkbox_incomplete} className="icon_checkbox_navbar" />}
                  <div className='lesson_box_title'>{lesson.title}</div>

                </button>

                {lesson.videos ? lesson.videos.map((video, videoIndex) => {
                  return (
                    <div key={videoIndex} className="lesson_obj lesson_detail_box boxes">

                      {lessonProgressArr
                        ? (
                          getLessonProgress(lesson.id) && getLessonProgress(lesson.id).video_progress && getLessonProgress(lesson.id).video_progress[videoIndex]
                            ? <div>
                              {
                                getLessonProgress(lesson.id).video_progress[videoIndex].is_completed ?
                                  <img src={icon_checkbox_complete} className="icon_checkbox_navbar" /> :
                                  <img src={icon_checkbox_incomplete} className="icon_checkbox_navbar" />
                              }
                            </div>
                            : <img src={icon_checkbox_incomplete} className="icon_checkbox_navbar" />
                        )
                        : <img src={icon_checkbox_incomplete} className="icon_checkbox_navbar" />}

                      Video {videoIndex + 1}
                    </div>
                  )
                }) : ""}

                {lesson.quizzes ? lesson.quizzes.map((quiz, quizIndex) => {
                  return (

                    <div key={quizIndex} className="lesson_obj lesson_detail_box boxes" >

                      {lessonProgressArr
                        ? (
                          getLessonProgress(lesson.id) && getLessonProgress(lesson.id).quiz_progress && getLessonProgress(lesson.id).quiz_progress[quizIndex]
                            ? <div>
                              {
                                getLessonProgress(lesson.id).quiz_progress[quizIndex].is_passed ?
                                  <img src={icon_checkbox_complete} className="icon_checkbox_navbar" /> :
                                  <img src={icon_checkbox_incomplete} className="icon_checkbox_navbar" />
                              }
                            </div>
                            : <img src={icon_checkbox_incomplete} className="icon_checkbox_navbar" />
                        )
                        : <img src={icon_checkbox_incomplete} className="icon_checkbox_navbar" />}

                      Quiz {quizIndex + 1} : {quiz?.questions?.length} ข้อ
                    </div>
                  )
                }) : ""}
                <img className='divide_line' src={divide_line_short} />

              </div>
            )
          }) : ""
      }

      {/* {quizesArr ? <span><div className="course_enroll_paragraph_title">
        แบบทดสอบ
      </div>


        {quizesArr ? (quizesArr.map((item, index) => {
          return (
            <button key={item.id} {...createScrollRef()}
              className="lesson_select_text lesson_box boxes"
              onClick={e => callbackQuiz(item.id, index)}>

              {quizProgressArr
                ? (
                  quizProgressArr[index]
                    ? <div className={quizProgressArr[index].is_passed ? "square_check_lessons_completed" : "square_check_lessons"}></div>
                    : <div className={"square_check_lessons"} />
                )
                : <div className={"square_check_lessons"} />}

              <div><span className="lesson_select_text lesson_title" >{item.title}</span> <br />
                <div>
                  <span className="lesson_select_text lesson_obj" >จำนวน : {item.questions ? item.questions.length : "0"} ข้อ</span>
                </div>
              </div>
            </button>
          )
        })
        ) : ""} </span> : ""}

      {lessonsArr ? <span>  <div className="course_enroll_paragraph_title">
        บทเรียน
      </div>
        {lessonsArr ? (lessonsArr.map((item, index) => {
          return (
            <button key={item.id} {...createScrollRef()} className="lesson_select_text lesson_box boxes" onClick={e => callbackLesson(item.id, index)}>
              {lessonProgressArr ? (lessonProgressArr[index] ? <div className={lessonProgressArr[index].is_completed ? "square_check_lessons_completed" : "square_check_lessons"} ></div> : <div className={"square_check_lessons"} ></div>) : <div className={"square_check_lessons"} ></div>}

              <div><span className="lesson_select_text lesson_title" >{item.title}</span> <br />
                <div>
                  <span className="lesson_select_text lesson_obj" >จำนวน Video : {item.videos ? item.videos.length : "0"}</span> <br />
                </div>
              </div>
            </button>
          )
        })
        ) : ""} </span> : ""} */}

    </nav>
  );

}

export default CourseNaviBigBox;