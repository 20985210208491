import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import Configuration from '../functions/Configuration';
import recheckAccessTokenIsExpired from '../functions/recheckAccessTokenIsExpired';
import getCurrentUserType from '../functions/getCurrentUserType';
import SimplifyDateString from '../functions/SimplifyDateString';
import AlertDialog from '../components/AlertDialog';
import ConfirmDialog from '../components/ConfirmDialog';
import ProfileMenu from '../components/ProfileMenu';
import UserGroupAdminDropdown from '../components/UserGroupAdminDropdown';
import NavBar from '../components/NavBar';
import HomeFooter from '../components/HomeFooter';

function ProfileAdminGroupList() {
  const [is_loaded, setIsLoaded] = useState(false)
  const [user_group_list, setUserGroupList] = useState([
      {
        id: "aaaaaaaaa",
        name:"aaa",
        description: "abadaf",
        total_students: 435,
      },
      {
        id: "aaaaaxvcxvaaaa",
        name:"acvcxvxaa",
        description: "abaxcxvcxvdaf",
        total_students: 4545,
      },
      {
        id: "aaaaaaafevaa",
        name:"aaacvcv",
        description: "abadacvcxvf",
        total_students: 444,
      },
    ])

  const [dialog_confirm_on, setDialogConfirmOn] = useState(false)
  const [dialog_data, setDialogData] = useState(false)

  const [dialog_alert_on, setDialogAlertOn] = useState(false)
  const [error_msg, setErrorMsg] = useState("")

  const navigate = useNavigate();

  const activateDeleteDialog = (course_id) => {
    setDialogConfirmOn(true)
    setDialogData(course_id)
  }

  const cancelDeleteDialog = () => {
    setDialogConfirmOn(false)
  }

  const closeAlertDialog = () => {
    setDialogAlertOn(false)
  }

   useEffect(
    () => {

      if (recheckAccessTokenIsExpired() || getCurrentUserType() !== "admin") {
        navigate('/');
      }

      setIsLoaded(true)

      //reloadTable()

    },
    []
  )

    return (
      <main>
        <NavBar isCourse={true}/>
        <div className='container'>
      <div className={"course_body_profile"}>

        {dialog_alert_on ? <AlertDialog alert_title={error_msg} alert_msg={""} parentCallback={closeAlertDialog}/> : ""}

        {dialog_confirm_on ? <ConfirmDialog confirm_title={"คุณต้องการจะลบใช่หรือไม่?"} confirm_msg={""} dialog_data={dialog_data} parentCallbackCancel={cancelDeleteDialog}/> : ""}

        <div className="boxes">
          <ProfileMenu selected_menu_init={2}/>

          <div className="admin_profile_detail_box">

          {is_loaded ? <div>

            <div className="boxes">

              <div className="admin_profile_detail_title">
                <h2>จัดการกลุ่มนักเรียน</h2>
              </div>

              <div className="admin_profile_detail_button_container">
                <button className="admin_profile_detail_button">สร้างกลุ่มผู้เรียน</button>
              </div>

            </div>

            <div className="course_filter_input_body_admin">
              <select className="course_list_search_1 filter_course_label_txt_box course_filter_input_body_1" name="filter">
                <option disabled value="null" hidden>Published?</option>
                <optgroup label="Published?">
                  <option value="published">Published = Yes</option>
                  <option value="unpublished">Published = No</option>
                </optgroup>
              </select>

              <label className="label_icon label_icon_search course_filter_input_body_3">
                  <input className="filter_course_label_txt_box_white insider_txt_box_with_icon" 
                  name="author" 
                  placeholder="ค้นหาตาม Author"
                  />
                </label>
            </div>

        <table className="course_list_table">
         <thead className="course_list_thread">
           <tr className="course_list_tr">
             <th className="course_list_th"></th>
             <th className="course_list_th">ชื่อกลุ่ม</th>
             <th className="course_list_th">คำอธิบาย</th>
             <th className="course_list_th">จำนวนนักเรียน</th>
             <th className="course_list_th">Actions</th>
          </tr>
         </thead>
         <tbody className="course_list_tbody">
          {user_group_list ? (user_group_list.length > 0 ? (user_group_list.map((key, index) => {
            //console.log(key)
            return (
              <tr className="course_list_tr" key={key.id}>
                <td className="course_list_td"></td>
                <td className="course_list_td">{key.name}</td>
                <td className="course_list_td">{key.description}</td>
                <td className="course_list_td">{key.total_students}</td>
                <td className="course_list_td"><UserGroupAdminDropdown groupID={key.id}/></td>
              </tr>
            );
          })) : (<tr className="course_list_tr">
                <td className="course_list_td"></td>
                <td className="course_list_td">ไม่พบรายการใดๆ</td>
                <td className="course_list_td"></td>
                <td className="course_list_td"></td>
                <td className="course_list_td"></td>
              </tr>)
          ) : 
              (<tr className="course_list_tr">
                <td className="course_list_td"></td>
                <td className="course_list_td">Loading...</td>
                <td className="course_list_td"></td>
                <td className="course_list_td"></td>
                <td className="course_list_td"></td>
              </tr>)}
          </tbody>
        </table>

          </div> : ""}

          </div>
        </div>
      
        
      </div>
      <HomeFooter/>
      </div>
      </main>
    );
  
}

export default ProfileAdminGroupList;
