import jwt_decode from "jwt-decode";

function recheckAccessTokenIsExpired() {
  let access_token_data = window.localStorage.getItem("access_token_data");

    if (access_token_data == null) { //one tab logged out, be sure to log out for all
        return true

    } else { //in case if token exists but can still be expired

        let decodedToken = jwt_decode(access_token_data);

        let expiredDate_check = new Date(parseInt(decodedToken['exp'])*1000);
        let now_check = new Date();

        if (now_check > expiredDate_check) { //token expiration
            return true
        }
    }
    //token valid
    return false
}

export default recheckAccessTokenIsExpired;