import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import Configuration from '../functions/Configuration';
import recheckAccessTokenIsExpired from '../functions/recheckAccessTokenIsExpired';
import CourseIconItem from '../components/CourseIconItem';
import ProfileMenu from '../components/ProfileMenu';
import HomeFooter from '../components/HomeFooter';
import AlertDialog from '../components/AlertDialog';
import NavBar from '../components/NavBar';

function ProfileStudentCompletedCourses() {
  const [is_loaded, setIsLoaded] = useState(false)
  const [courses, setArrayCourses] = useState([])

  const navigate = useNavigate();

  const [dialog_alert_on, setDialogAlertOn] = useState(false)
  const [error_msg, setErrorMsg] = useState("")

  const closeAlertDialog = () => {
    setDialogAlertOn(false)
  }

   useEffect(
    () => {

      if (recheckAccessTokenIsExpired()) {
        navigate('/');
      }

      let access_token_data = window.localStorage.getItem("access_token_data");
      let access_token_data_type = window.localStorage.getItem("access_token_data_type");

      const url = Configuration("user_host")+"/api/v1/user/complete-course";

      const fetchData = async () => {
        try {
          const {data: response} = await axios({
            method: "get",
            url: url,
            headers: { "Authorization": access_token_data_type + " " + access_token_data },
          });
          let the_data = response.data
          //console.log(the_data)
          if (the_data) {
            let the_length = the_data.length
            let rows = Math.ceil(the_length / 3)
            let the_display_data = []
            for (let r = 1; r <= rows; r++) {
              let the_array = []
              for (let each_data_i in the_data) {
                if (each_data_i < r*3 && each_data_i >= (r-1)*3) {
                  let each_data = the_data[each_data_i]
                  the_array.push(each_data)
                }
              }
              the_display_data.push(the_array)
            }
            setArrayCourses(the_display_data)
          } else {
            setArrayCourses([])
          }
          
          setIsLoaded(true)
          
        } catch (error) {
          if (error.response.status < 500) {
            setErrorMsg("Load Courses Error")
          } else {
            setErrorMsg("Internal Server Error")
          }
          setDialogAlertOn(true)
        }
      };

      fetchData()

    },
    []
  )

    return (
      <main>
        <NavBar isCourse={true}/>
        <div className='container'>
    <div className={"course_body_back "}>
      <div className={"course_body_profile"}>

        {dialog_alert_on ? <AlertDialog alert_title={error_msg} alert_msg={""} parentCallback={closeAlertDialog}/> : ""}

        <div className="boxes">
          <ProfileMenu selected_menu_init={2}/>

          <div className="profile_detail_box">
            {is_loaded ? <div>
                
                { courses.length > 0 ? 
            <div>
              <h4 className="card_header_txt">คอร์สที่เรียนจบแล้ว</h4>
              { courses.map((item,index) => {
                return (
                    <div key={index} className="home_list_item_box">
                      { item.map((sub_item,sub_index) => {
                        return (
                            <div key={sub_item.id} className="home_list_item">
                            <CourseIconItem displayOn={"completed_course"} courseData={sub_item} is_in_profile={true}/>
                            </div>
                          )
                      })}
                    </div>
                  )
                })}
              </div>
            : <h4 className="card_header_txt">คอร์สที่เรียนจบแล้วขณะนี้ยังไม่มี</h4>}

            </div> : <h4 className="card_header_txt">Loading...</h4>}
          </div>
        </div>
      </div>
        <HomeFooter/>
      </div>
      </div>
      </main>
    );
  
}

export default ProfileStudentCompletedCourses;
