import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import Configuration from '../functions/Configuration';
import OutsiderNavbar from '../components/OutsiderNavbar';
import WelcomeBox from '../components/WelcomeBox';

function RegisterEmailInvalidLink() {
  const [input_email, setEmail] = useState("")

  const [is_error, setIsError] = useState(false)
  const [error_msg, setErrorMsg] = useState("")

  const navigate = useNavigate();

  const handleSubmit = () => {
    navigate('/register_email_input');
  }

  useEffect(
    () => {
      document.body.style.background = "#503e9d"
    },
    []
  )

  return (
    <div>

      <div>

        <OutsiderNavbar />

        <div className="boxes">
          <div className="init_form_box init_form_box_mini">
            <h4 className="outsider_txt">ขณะนี้ Link สำหรับการสร้างบัญชีผู้ใช้<br />
              ของท่านหมดอายุแล้ว</h4>

            <br />

            <h4 className="outsider_txt">คลิก <span onClick={handleSubmit}><u>ที่นี่</u></span> เพื่อส่ง Link อีเมลยืนยันใหม่อีกครั้ง
            </h4>

          </div>
          <WelcomeBox isRegis={true} />

        </div>

      </div>

    </div>
  );

}

export default RegisterEmailInvalidLink;
