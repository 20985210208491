import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import getCurrentUserType from '../functions/getCurrentUserType';
import logo_filled from '../res/logo.svg';
//import NavBarDropdown from './NavBarDropdown';
import Logout from '../functions/Logout';

function NavBar(props) {
  const [is_vulcan_hero, setIsVulcanHero] = useState(false)
  const [is_admin, setIsAdmin] = useState(false)
  const isCourse = props.isCourse

  const navigate = useNavigate();
  const location = useLocation();

   const backToHome = () => {
    if (location.pathname === '/home'){
      navigate(0)
    }else{
       navigate('/home');
    }
   }

   const toProfile = () => {
       navigate('/profile_student');
   }

   const toAdmin = () => {
       navigate('/profile_admin_course_list');
   }

   const logOut = () => {
       if (Logout()) {
        navigate('/');
       }
   }

   const externalLink = (link) => {
       window.open(link, '_blank').focus();
   }

   useEffect(
    () => {
      if (getCurrentUserType() === "vulcan_hero") {
        setIsVulcanHero(true)
      } else if (getCurrentUserType() === "admin") {
        setIsAdmin(true)
      }
    },
    []
  )

    return (
      <nav>

        <div className={"navbar navbar2 "+(isCourse ? "navbar_course" : "")}><button className="navbar2_title" onClick={backToHome}><img src={logo_filled} alt="Vulcan Coalition (กลับหน้าแรก)" width="200" height="76"/> </button>
        <div className="navbar2_right">
        <button className="navbar_item navbar2_item" onClick={backToHome}>หน้าแรก</button>
        {/*<NavBarDropdown/>*/}
        {is_vulcan_hero ? <button className="navbar_item navbar2_item" onClick={() => externalLink("https://collab.lab.ai/")}>ทำงานกันต่อ</button> : ""}
        {is_admin ? <button className="navbar_item navbar2_item" onClick={toAdmin}>หน้าแอดมิน</button> : <button className="navbar_item navbar2_item" onClick={toProfile}>บัญชีของฉัน</button>}
        <button className="navbar_item navbar2_item" onClick={logOut}>ออกจากระบบ</button>
        </div>
        </div>

      </nav>
    );
  
}

export default NavBar;