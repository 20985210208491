import React, { useState, useEffect } from 'react';

function CustomizingTextArea(props) {
  const parentCallback = props.parentCallback
  const initTxt = props.initTxt
  const componentName = props.componentName
  const componentID = props.componentID
  const [input_state, setInputState] = useState("");
  const [component_name, setComponentName] = useState("txt_area");
  const [component_id, setComponentID] = useState("the_name");

  const getSelectionText = (el) => {
    //references : 
    //https://stackoverflow.com/questions/3964710/replacing-selected-text-in-the-textarea
    //https://stackoverflow.com/questions/5379120/get-the-highlighted-selected-text
    
    let text = "";
    let selection = window.getSelection();
    
    if (window.getSelection) {
      text = selection.toString();
    } else if (document.selection && document.selection.type !== "Control") {
      text = document.selection.createRange().text;
    }

    let start = 0, end = 0, normalizedValue, range, textInputRange, len, endRange;

    if (typeof el.selectionStart == "number" && typeof el.selectionEnd == "number") {
        start = el.selectionStart;
        end = el.selectionEnd;
    } else {
        range = document.selection.createRange();

        if (range && range.parentElement() === el) {
            len = el.value.length;
            normalizedValue = el.value.replace(/\r\n/g, "\n");

            // Create a working TextRange that lives only in the input
            textInputRange = el.createTextRange();
            textInputRange.moveToBookmark(range.getBookmark());

            // Check if the start and end of the selection are at the very end
            // of the input, since moveStart/moveEnd doesn't return what we want
            // in those cases
            endRange = el.createTextRange();
            endRange.collapse(false);

            if (textInputRange.compareEndPoints("StartToEnd", endRange) > -1) {
                start = end = len;
            } else {
                start = -textInputRange.moveStart("character", -len);
                start += normalizedValue.slice(0, start).split("\n").length - 1;

                if (textInputRange.compareEndPoints("EndToEnd", endRange) > -1) {
                    end = len;
                } else {
                    end = -textInputRange.moveEnd("character", -len);
                    end += normalizedValue.slice(0, end).split("\n").length - 1;
                }
            }
        }
    }

    return {
      "start":start,
      "end":end,
      "text":text,
    };
  }

  const boldText = () => {
    let el = document.getElementById(component_id);
    let selected_txt_json = getSelectionText(el)
    let val = el.value;
    el.value = val.slice(0, selected_txt_json.start) + "[**"+selected_txt_json.text+"**]" + val.slice(selected_txt_json.end);
    parentCallback(el.name, el.value)
   } 

  const italicText = () => {
    let el = document.getElementById(component_id);
    let selected_txt_json = getSelectionText(el)
    let val = el.value;
    el.value = val.slice(0, selected_txt_json.start) + "[*"+selected_txt_json.text+"*]" + val.slice(selected_txt_json.end);
    parentCallback(el.name, el.value)
   }

  const underlineText = () => {
    let el = document.getElementById(component_id);
    let selected_txt_json = getSelectionText(el)
    let val = el.value;
    el.value = val.slice(0, selected_txt_json.start) + "[__"+selected_txt_json.text+"__]" + val.slice(selected_txt_json.end);
    parentCallback(el.name, el.value)
   }  

  useEffect(
    () => {
      if (initTxt) {
        setInputState(initTxt)
      }
      if (componentName) {
        setComponentName(componentName)
      }
      if (componentID) {
        setComponentID(componentID)
      }
    },
    []
  )

    return (
      <div className={"customizing_txt_area_box"}>
          <div className="customize_btn_container">
            <button className="customize_btn" onClick={boldText}><b>B</b></button>
            <button className="customize_btn" onClick={italicText}><i>I</i></button>
            <button className="customize_btn" onClick={underlineText}><u>U</u></button>
          </div>
            <textarea id={component_id} className="customize_txt_area" name={component_name} defaultValue={input_state} rows="6" onChange={(e) => {parentCallback(e.target.name, e.target.value)}}></textarea>
        </div>
    );
  
}

export default CustomizingTextArea;