import React, { useState, useEffect, useRef } from 'react';

function FirstLoginDialog(props) {
  const parentCallback = props.parentCallback
  const check_data = props.check_data

  const [is_checked, setIsChecked] = useState(false)
  const [chosen, setChosen] = useState('');

  const dialogRef = useRef()

  const callBack = () => {
    document.body.style.overflow = "visible"
    parentCallback(chosen)
  }

  const setCheckBoxValue = (value, check) => {
    if (check) {
      setIsChecked(true)
    }
    //console.log(value)
    setChosen(parseInt(value))
  }

  useEffect(
    () => {
      let top  = window.pageYOffset || document.documentElement.scrollTop
      document.getElementById("confirm_box_object").style.top = (top+(window.screen.height/5.5))+"px"
      document.body.style.overflow = "hidden"
      document.getElementById("confirm_box_overlay_object").style.top = (top)+"px"
      document.getElementById("confirm_box_overlay_object").style.height = (window.screen.height)+"px"
    },
    []
  )

  useEffect(()=>{
    dialogRef.current?.focus()
  },[])

    return (
      <div>
      <div className={"confirm_box_overlay"} id={"confirm_box_overlay_object"}>

      </div>
      <div aria-modal='true' className={"confirm_box_first_login"} id={"confirm_box_object"}>
        <br/>
        <h2 ref={dialogRef} className="confirm_txt" tabIndex="0">กรุณาเลือกฟีเจอร์อำนวยความสะดวกที่สอดคล้องกับรูปแบบการเรียนรู้ของท่าน</h2><br/>

        <label className="first_login_label">
            <input className="obj_checkbox_dark" type="checkbox" name={check_data[0].name} value={check_data[0].id} checked={chosen === check_data[0].id} onChange={e => setCheckBoxValue(e.target.value, e.target.checked)}/>
              <span className="obj_checkbox_label_dark"><b>ข้าพเจ้าไม่มีความจำเป็นต้องใช้ฟีเจอร์อำนวยความสะดวก</b></span><br/>
              <span className="obj_checkbox_label_dark_small">เหมาะสำหรับผู้เรียนที่สามารถเข้าถึงข้อมูลประเภทภาพ เสียง และข้อความได้ตามปกติ</span><br/>
            </label><br/><br/>

        <label className="first_login_label">
            <input className="obj_checkbox_dark" type="checkbox" name={check_data[1].name} value={check_data[1].id} checked={chosen === check_data[1].id} onChange={e => setCheckBoxValue(e.target.value, e.target.checked)}/>
              <span className="obj_checkbox_label_dark"><b>ข้าพเจ้าต้องการใช้ Screen Reader</b></span><br/>
              <span className="obj_checkbox_label_dark_small">เหมาะสำหรับผู้เรียนกลุ่มตาบอดและสายตาเลือนรางที่มีข้อจำกัดด้านการเข้าถึงข้อมูลด้านรูปภาพ</span><br/>
            </label><br/><br/>

        <label className="first_login_label">
            <input className="obj_checkbox_dark" type="checkbox" name={check_data[2].name} value={check_data[2].id} checked={chosen === check_data[2].id} onChange={e => setCheckBoxValue(e.target.value, e.target.checked)}/>
              <span className="obj_checkbox_label_dark"><b>ข้าพเจ้าต้องการใช้ล่ามภาษามือ</b></span><br/>
              <span className="obj_checkbox_label_dark_small">เหมาะสำหรับผู้เรียนกลุ่มหูหนวกหรือหูตึงที่มีความจำเป็นต้องใช้ล่ามภาษามือ</span><br/>
            </label><br/><br/>

            <br/>
            <h6 className="confirm_txt_small">หมายเหตุ : ท่านสามารถเปลี่ยนฟีเจอร์อำนวยความสะดวกได้ที่หน้า “Account Setting” ได้ตลอดเวลา</h6>

        {is_checked ? <input className="confirm_solo_btn confirm_solo_btn_first_login confirm_solo_btn_good" type="button" value="ยืนยัน" onClick={callBack}/> :
        <input className="confirm_solo_btn confirm_solo_btn_first_login confirm_solo_btn_bad" type="button" value="ยืนยัน" disabled/>}
      </div>
      </div>
    );
}


export default FirstLoginDialog