import React from 'react';

function CoursePageNameBox(props) {
  const courseName = props.courseName
  const lessonName = props.lessonName
  const quizName = props.quizName

  const courseCallback = props.courseCallback
  const lessonCallback = props.lessonCallback

  const type = props.type

  return (
    <div className="course_page_name_box course_enroll_title">
      {type === "lesson" ?
        <span>
          <button className={"navi_back"} onClick={courseCallback}>{courseName}</button>
          <span className="greater_arrow">{">"}</span>
          <span className="lesson_name">{lessonName}</span>
        </span> :
        <span>
          <button className={"navi_back"} onClick={courseCallback}>{courseName}</button>
          <span className="greater_arrow">{">"}</span>
          <button className={"navi_back"} onClick={lessonCallback}>{lessonName}</button>
          <span className="greater_arrow">{">"}</span>
          <span className="lesson_name">{quizName}</span>
        </span>}
    </div>
  );

}

export default CoursePageNameBox;