import React, { useState, useEffect } from 'react';
import GetCurrentDateTimeString from '../functions/GetCurrentDateTimeString';
import IsLeapYear from '../functions/IsLeapYear';

function DateDropdownEdit(props) {
  const parentCallback = props.parentCallback
  const classNames = props.classNames
  const valueName = props.valueName
  const valueType = props.valueType
  const initDisplay = props.initDisplay

  const initValue = props.initValue

  const preSelectYear = props.preSelectYear
  const preSelectMonth = props.preSelectMonth

  const [selections, setSelections] = useState([]);

  useEffect(
    () => {
      let current_time_obj = new Date(GetCurrentDateTimeString())
      let current_year = current_time_obj.getFullYear()

      if (valueType === "year") {
        let years = []
        for (let i = current_year; i >= current_year-200; i--) {
              years.push({
                value: parseInt(i), 
                display: i,
              })
          }
        setSelections(years)
      } else if (valueType === "month") {
        let months = []
        let monthNames = ["ม.ค.", "ก.พ.", "มี.ค.", "เม.ย.", "พ.ค.", "มิ.ย.",
          "ก.ค.", "ส.ค.", "ก.ย.", "ต.ค.", "พ.ย.", "ธ.ค."
        ]
        for (let j in monthNames) {
          months.push({
            value: parseInt(j)+1, 
            display: monthNames[j],
          })
        }
        setSelections(months)
      } else if (valueType === "date" && preSelectYear && preSelectMonth) {
        let dates = []
        let total_days = 31
        let preSelectMonthInt = parseInt(preSelectMonth)
        if (preSelectMonthInt === 4 || preSelectMonthInt === 6 || preSelectMonthInt === 9 || preSelectMonthInt === 11) {
          total_days = 30
        } else if (preSelectMonthInt === 2) {
          if (IsLeapYear(preSelectYear)) {
            total_days = 29
          } else {
            total_days = 28
          }
        }
        for (let k = 1; k <= total_days; k++) {
          dates.push({
            value: parseInt(k), 
            display: k,
          })
        }
        setSelections(dates)
      }

    },
    []
  )

    return (
      <select className={classNames} name={valueName} value={initValue ? initValue : "null"} onChange={e => parentCallback(valueType, e.target.value)}>
                <option disabled value="null" hidden>{initDisplay}</option>
                <optgroup label={initDisplay}>
                { selections ? (selections.map((item,index) => {
                      return (
                          <option key={index} value={item.value}>{item.display}</option>
                        )
                    })
                    ) : ""}
                </optgroup>
      </select>
    );
}


export default DateDropdownEdit