import React, { useCallback, useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import Configuration from '../functions/Configuration';
import recheckAccessTokenIsExpired from '../functions/recheckAccessTokenIsExpired';
import getCurrentUserType from '../functions/getCurrentUserType';
import GetUrlHashValue from '../functions/GetUrlHashValue';
import YoutubeConvertToEmbed from '../functions/YoutubeConvertToEmbed';
import AlertDialog from '../components/AlertDialog';
import ConfirmDialog from '../components/ConfirmDialog';
import ProfileMenu from '../components/ProfileMenu';
import NewCourseHeader from '../components/NewCourseHeader';
import NewCourseProgress from '../components/NewCourseProgress';
import NewCourseNaviBox from '../components/NewCourseNaviBox';
import CustomizingTextArea from '../components/CustomizingTextArea';
import NavBar from '../components/NavBar';
import HomeFooter from '../components/HomeFooter';

function ProfileAdminNewCoursePart1() {
  const init_input_state = { 
    name: "", 
    course_title: "",
    course_subtitle: "",
    author: "",
    language: "null",
    category: "null",
    sub_category: "null",
    is_certificated: "null",
    duration: 0,
    is_recommended: "null",
    overview_desc: "",
    learning_desc: "",
    assignment_desc: "",
    course_type: "",
    course_app: "null", //video or exam
    display_to: 1, //1, 2, 3, 4
    thumbnail: null, //{storage_id: '', source: ''},
    teaser: null, //{storage_id: '', source: '', media_type: 'video', video_type: 'upload'},
    certificate_template: null, //is_certificated must be true, {storage_id: '', source. ''},
    status: "unpublished",
  }

  const [input_state, setInputState] = useState(init_input_state);

  const [yt_teaser, setYoutubeTeaser] = useState("");

  const [customizeBoxOn, setcustomizeBoxOn] = useState(false);
  const [thumbnailOn, setThumbnailOn] = useState(false);
  const [teaserOn, setTeaserOn] = useState(false);
  const [certificateOn, setCertificateOn] = useState(false);

  const [disability_input_enum, setDisabilityInputEnum] = useState([]);
  const [enum_loaded, setEnumLoaded] = useState(false);

  const [categories_data, setCategoriesData] = useState([]);
  const [subcategories_data, setSubCategoriesData] = useState([]);

  const [editMode, setEditMode] = useState(false);
  const [course_id, setCourseID] = useState("")

  const [dialog_confirm_on, setDialogConfirmOn] = useState(false)
  const [dialog_data, setDialogData] = useState(false)

  const [dialog_alert_on, setDialogAlertOn] = useState(false)
  const [error_msg, setErrorMsg] = useState("")

  const navigate = useNavigate();

  const submitPostOrEditData = (is_to_next_page) => {
    if (editMode) {
      submitEditData(is_to_next_page)
    } else {
      submitData(is_to_next_page)
    }
  }

  const submitData = (is_to_next_page) => {
    let access_token_data = window.localStorage.getItem("access_token_data");
    let access_token_data_type = window.localStorage.getItem("access_token_data_type");

    const url = Configuration("main_host")+"/api/v1/course";
    let body = input_state

    if (body.sub_category === "null") {
      delete body.sub_category
    }

    //console.log(body)

      const postData = async () => {
        try {
          const response = await axios({
            method: "post",
            url: url,
            data: body,
            headers: { "Authorization": access_token_data_type + " " + access_token_data },
          });
          //console.log(response); //need to get course_id from here
          setInputState(init_input_state)
          setcustomizeBoxOn(false)
          setThumbnailOn(false)
          setTeaserOn(false)
          setCertificateOn(false)
          let remove_cache = function() {
            for (let name in input_state) {
                window.localStorage.removeItem("newcourse_p1_"+name);
            }
            setcustomizeBoxOn(true)
            setThumbnailOn(true)
            setTeaserOn(true)
            setCertificateOn(true)
          }
          remove_cache()

          if (is_to_next_page) {
            toNextPage(response.data.data.id)
          } else {
            backToCourseList()
          }
          
        } catch (error) {
          if (error.response.status < 500) {
            //console.log(error.response.data.error_message)
            setErrorMsg("Input Error")
          } else {
            setErrorMsg("Internal Server Error")
          }
          setDialogAlertOn(true)
          //console.log(error)
        }
      };

      postData()
  }

  const submitEditData = (is_to_next_page) => {
    let access_token_data = window.localStorage.getItem("access_token_data");
    let access_token_data_type = window.localStorage.getItem("access_token_data_type");

    const url = Configuration("main_host")+"/api/v1/course/"+course_id;
    let body = input_state

    if (body.sub_category === "null") {
      delete body.sub_category
    }

    //console.log(body)
    //console.log(url)

      const patchData = async () => {
        try {
          await axios({
            method: "patch",
            url: url,
            data: body,
            headers: { "Authorization": access_token_data_type + " " + access_token_data },
          });
          //console.log(response); //need to get course_id from here
          setInputState(init_input_state)
          setcustomizeBoxOn(false)
          setThumbnailOn(false)
          setTeaserOn(false)
          setCertificateOn(false)

            setcustomizeBoxOn(true)
            setThumbnailOn(true)
            setTeaserOn(true)
            setCertificateOn(true)

          if (is_to_next_page) {
            toNextPage(course_id)
          } else {
            backToCourseList()
          }
          
        } catch (error) {
          if (error.response.status < 500) {
            setErrorMsg("Edit Input Error")
          } else {
            setErrorMsg("Internal Server Error")
          }
          setDialogAlertOn(true)
        }
      };

      patchData()
  }

  const toNextPage = (course_id) => {
      navigate('/profile_admin_new_course_2#id='+course_id);
   } 

  const backToCourseList = () => {
      navigate('/profile_admin_course_list');
   } 

  const setValue = (name, value) => {
    setInputState(prevState => ({
                ...prevState,
                [name]: value
    }));
    if (!editMode) {
      window.localStorage.setItem("newcourse_p1_"+name, value);
    }
   } 

  const setCategoryValue = (name, value) => {
    if (name !== "category") {
      return
    }
      
    setInputState(prevState => ({
                ...prevState,
                category: value,
                sub_category: "null",
    }));
      if (value !== "null") {
        for (let category of categories_data) {
          if (value === category.id) {
            if (category.sub_category) {
              setSubCategoriesData(category.sub_category)
            } else {
              setSubCategoriesData([])
            }
          }
        }
      } else {
        setSubCategoriesData([])
      }

    if (!editMode) {
      window.localStorage.setItem("newcourse_p1_category", value);
      window.localStorage.setItem("newcourse_p1_sub_category", "null");
    }
   }

  const setValueJSON = (name, value) => {
    setInputState(prevState => ({
                ...prevState,
                [name]: value
    }));
    if (!editMode) {
      window.localStorage.setItem("newcourse_p1_"+name, JSON.stringify(value));
    }
   } 

  /*const setValueSimple = (name, value) => {
    setInputState(prevState => ({
                ...prevState,
                [name]: value
    }));
   } */

  const setValueBigBox = useCallback((name, value) => {
    setInputState(prevState => ({
                ...prevState,
                [name]: value
    }));
    if (!editMode) {
      window.localStorage.setItem("newcourse_p1_"+name, value);
    }
  }, []);

  const setBoolValue = (name, value) => {
    let into_bool = (value === "true" || value === true)
    setInputState(prevState => ({
                ...prevState,
                [name]: into_bool
    }));
    if (!editMode) {
      window.localStorage.setItem("newcourse_p1_"+name, into_bool);
    }
   } 

   const setNumValue = (name, value) => {
    setInputState(prevState => ({
                ...prevState,
                [name]: parseInt(value)
    }));
    if (!editMode) {
      window.localStorage.setItem("newcourse_p1_"+name, value);
    }
   } 

   const uploadThumbnail = (files) => {
    let access_token_data = window.localStorage.getItem("access_token_data");
    let access_token_data_type = window.localStorage.getItem("access_token_data_type");

    const url = Configuration("main_host")+"/api/v1/thumbnail/upload";
    let file = files[0]

      const postData = async () => {
        let file_data = new FormData()
        file_data.append("file",file)

        setThumbnailOn(false)

        try {
          const response = await axios({
            method: "post",
            url: url,
            data: file_data,
            headers: { "Content-Type": "multipart/form-data",
            "Authorization": access_token_data_type + " " + access_token_data },
          });
          //console.log(response);

          let the_id = response.data.data.storage_id
          let the_url = response.data.data.source
          let new_thumbnail = {
                    "storage_id": the_id,
                    "source": the_url,
                  }

          setInputState(prevState => ({
                ...prevState,
                  thumbnail: new_thumbnail
          }));
          setThumbnailOn(true)
          if (!editMode) {
            window.localStorage.setItem("newcourse_p1_thumbnail", JSON.stringify(new_thumbnail));
          }
           
        } catch (error) {
          if (error.response.status < 500) {
            setErrorMsg("Upload Error")
          } else {
            setErrorMsg("Internal Server Error")
          }
          setThumbnailOn(true)
          setDialogAlertOn(true)
        }
      };

      postData()
   }

   const uploadTeaser = (files) => {
    let access_token_data = window.localStorage.getItem("access_token_data");
    let access_token_data_type = window.localStorage.getItem("access_token_data_type");

    const url = Configuration("main_host")+"/api/v1/teaser/upload";
    let file = files[0]

    let file_type = "video"

    if (file.name.endsWith(".png") || file.name.endsWith(".PNG") || file.name.endsWith(".jpg") || file.name.endsWith(".JPG") || file.name.endsWith(".jpeg") || file.name.endsWith(".JPEG")) {
      file_type = "image"
    }

      const postData = async () => {
        let file_data = new FormData()
        file_data.append("file",file)
        file_data.append("media_type",file_type)

        setTeaserOn(false)

        try {
          const response = await axios({
            method: "post",
            url: url,
            data: file_data,
            headers: { "Content-Type": "multipart/form-data",
            "Authorization": access_token_data_type + " " + access_token_data },
          });
          //console.log(response);

          let the_id = response.data.data.storage_id
          let the_url = response.data.data.source
          let new_teaser = {
                      "storage_id": the_id,
                      "source": the_url,
                      "media_type": file_type,
                      "video_type": "upload"
                    }

          setInputState(prevState => ({
                  ...prevState,
                    teaser: new_teaser
            }));
          
          setTeaserOn(true)
          if (!editMode) {
            window.localStorage.setItem("newcourse_p1_teaser", JSON.stringify(new_teaser))
          }
          
        } catch (error) {
          if (error.response.status < 500) {
            setErrorMsg("Upload Error")
          } else {
            setErrorMsg("Internal Server Error")
          }
          setTeaserOn(true)
          setDialogAlertOn(true)
        }
      };

      postData()
   }

   const putYoutubeTeaser = () => {
      setTeaserOn(false)

      let new_teaser = {
                      "storage_id": "yt_upload",
                      "source": yt_teaser,
                      "media_type": "video",
                      "video_type": "youtube"
                    }

      setInputState(prevState => ({
                  ...prevState,
                    teaser: new_teaser
            }));

      if (!editMode) {
            window.localStorage.setItem("newcourse_p1_teaser", JSON.stringify(new_teaser))
          }

      setTeaserOn(true)
   }

   const uploadCertificate = (files) => {
    let access_token_data = window.localStorage.getItem("access_token_data");
    let access_token_data_type = window.localStorage.getItem("access_token_data_type");

    const url = Configuration("main_host")+"/api/v1/certificate-template/upload";
    let file = files[0]

      const postData = async () => {
        let file_data = new FormData()
        file_data.append("file",file)

        setCertificateOn(false)

        try {
          const response = await axios({
            method: "post",
            url: url,
            data: file_data,
            headers: { "Content-Type": "multipart/form-data",
            "Authorization": access_token_data_type + " " + access_token_data },
          });
          //console.log(response);

          let the_id = response.data.data.storage_id
          let the_url = response.data.data.source
          let new_certificate = {
                    "storage_id": the_id,
                    "source": the_url,
                  }

          setInputState(prevState => ({
                ...prevState,
                  certificate_template: new_certificate
          }));
          setCertificateOn(true)
          if (!editMode) {
            window.localStorage.setItem("newcourse_p1_certificate_template", JSON.stringify(new_certificate));
          }
           
        } catch (error) {
          if (error.response.status < 500) {
            setErrorMsg("Upload Error")
          } else {
            setErrorMsg("Internal Server Error")
          }
          setCertificateOn(true)
          setDialogAlertOn(true)
        }
      };

      postData()
   }

   const cancelThumbnail = (storage_id) => {
    let access_token_data = window.localStorage.getItem("access_token_data");
    let access_token_data_type = window.localStorage.getItem("access_token_data_type");

    const url = Configuration("main_host")+"/api/v1/thumbnail/"+storage_id+"/cancel-upload";

      setThumbnailOn(false)

      const postData = async () => {
        try {
          await axios({
            method: "post",
            url: url,
            headers: { "Authorization": access_token_data_type + " " + access_token_data },
          });
          //console.log(response)

          setInputState(prevState => ({
                ...prevState,
                  thumbnail: init_input_state.thumbnail
          }));
          setThumbnailOn(true)
          window.localStorage.removeItem("newcourse_p1_thumbnail");
          
        } catch (error) {
          if (error.response.status < 500) {
            setErrorMsg("Cancelation Error")
          } else {
            setErrorMsg("Internal Server Error")
          }
          setDialogAlertOn(true)
        }
      };

      postData()
   }

   const cancelTeaser = (storage_id) => {
    let access_token_data = window.localStorage.getItem("access_token_data");
    let access_token_data_type = window.localStorage.getItem("access_token_data_type");

    const url = Configuration("main_host")+"/api/v1/teaser/"+storage_id+"/cancel-upload";

      setTeaserOn(false)

      const postData = async () => {
        try {
          await axios({
            method: "post",
            url: url,
            headers: { "Authorization": access_token_data_type + " " + access_token_data },
          });
          //console.log(response)

          setInputState(prevState => ({
                ...prevState,
                  teaser: init_input_state.teaser
          }));
          setTeaserOn(true)
          window.localStorage.removeItem("newcourse_p1_teaser");
          
        } catch (error) {
          if (error.response.status < 500) {
            setErrorMsg("Cancelation Error")
          } else {
            setErrorMsg("Internal Server Error")
          }
          setDialogAlertOn(true)
        }
      };

      if (input_state.teaser.media_type === "upload") {
        postData()
      } else {
        setInputState(prevState => ({
                ...prevState,
                  teaser: init_input_state.teaser
          }));
          setTeaserOn(true)
          window.localStorage.removeItem("newcourse_p1_teaser");
      }
      
   }

   const cancelCertificate = (storage_id) => {
    let access_token_data = window.localStorage.getItem("access_token_data");
    let access_token_data_type = window.localStorage.getItem("access_token_data_type");

    const url = Configuration("main_host")+"/api/v1/certificate-template/"+storage_id+"/cancel-upload";

      setCertificateOn(false)

      const postData = async () => {
        try {
          await axios({
            method: "post",
            url: url,
            headers: { "Authorization": access_token_data_type + " " + access_token_data },
          });
          //console.log(response)

          setInputState(prevState => ({
                ...prevState,
                  certificate_template: init_input_state.certificate_template
          }));
          setCertificateOn(true)
          window.localStorage.removeItem("newcourse_p1_certificate_template");
          
        } catch (error) {
          if (error.response.status < 500) {
            setErrorMsg("Cancelation Error")
          } else {
            setErrorMsg("Internal Server Error")
          }
          setDialogAlertOn(true)
        }
      };

      postData()
   }

   const deleteThumbnail = (storage_id) => {
    let access_token_data = window.localStorage.getItem("access_token_data");
    let access_token_data_type = window.localStorage.getItem("access_token_data_type");

    const url = Configuration("main_host")+"/api/v1/thumbnail/"+storage_id;

      setThumbnailOn(false)

      const deleteData = async () => {
        try {
          await axios({
            method: "delete",
            url: url,
            headers: { "Authorization": access_token_data_type + " " + access_token_data },
          });
          //console.log(response)
          setInputState(prevState => ({
                ...prevState,
                  thumbnail: init_input_state.thumbnail
          }));
          setThumbnailOn(true)
          setDialogConfirmOn(false)
          window.localStorage.removeItem("newcourse_p1_thumbnail");
          
        } catch (error) {
          if (error.response.status < 500) {
            setErrorMsg("Delete Error")
          } else {
            setErrorMsg("Internal Server Error")
          }
          setDialogConfirmOn(false)
          setDialogAlertOn(true)
        }
      };

      deleteData()
   }

   const deleteTeaser = (storage_id) => {
    let access_token_data = window.localStorage.getItem("access_token_data");
    let access_token_data_type = window.localStorage.getItem("access_token_data_type");

    const url = Configuration("main_host")+"/api/v1/teaser/"+storage_id;

      setTeaserOn(false)

      const deleteData = async () => {
        try {
          await axios({
            method: "delete",
            url: url,
            headers: { "Authorization": access_token_data_type + " " + access_token_data },
          });
          //console.log(response)
          setInputState(prevState => ({
                ...prevState,
                  teaser: init_input_state.teaser
          }));
          setTeaserOn(true)
          setDialogConfirmOn(false)
          window.localStorage.removeItem("newcourse_p1_teaser");
          
        } catch (error) {
          if (error.response.status < 500) {
            setErrorMsg("Delete Error")
          } else {
            setErrorMsg("Internal Server Error")
          }
          setDialogConfirmOn(false)
          setDialogAlertOn(true)
        }
      };

      if (input_state.teaser.media_type === "upload") {
        deleteData()
      } else {
        setInputState(prevState => ({
                ...prevState,
                  teaser: init_input_state.teaser
          }));
          setTeaserOn(true)
          setDialogConfirmOn(false)
      }
   }

  const deleteCertificate = (storage_id) => {
    let access_token_data = window.localStorage.getItem("access_token_data");
    let access_token_data_type = window.localStorage.getItem("access_token_data_type");

    const url = Configuration("main_host")+"/api/v1/certificate-template/"+storage_id;

      setCertificateOn(false)

      const deleteData = async () => {
        try {
          await axios({
            method: "delete",
            url: url,
            headers: { "Authorization": access_token_data_type + " " + access_token_data },
          });
          //console.log(response)
          setInputState(prevState => ({
                ...prevState,
                  certificate_template: init_input_state.certificate_template
          }));
          setCertificateOn(true)
          setDialogConfirmOn(false)
          window.localStorage.removeItem("newcourse_p1_certificate_template");
          
        } catch (error) {
          if (error.response.status < 500) {
            setErrorMsg("Delete Error")
          } else {
            setErrorMsg("Internal Server Error")
          }
          setDialogConfirmOn(false)
          setDialogAlertOn(true)
        }
      };

      deleteData()
   }

  const activateDeleteDialog = (to_delete_id, delete_data_type) => {
    setDialogConfirmOn(true)
    setDialogData({
      "id": to_delete_id,
      "type": delete_data_type,
    })
  }

  const cancelDeleteDialog = () => {
    setDialogConfirmOn(false)
  }

  const closeAlertDialog = () => {
    setDialogAlertOn(false)
  }

  const deleteSomething = (deletion_data) => {
    if (deletion_data.type === "thumbnail") {
      deleteThumbnail(deletion_data.id)
    } else if (deletion_data.type === "teaser") {
      deleteTeaser(deletion_data.id)
    } else if (deletion_data.type === "certificate_template") {
      deleteCertificate(deletion_data.id)
    } 
  }

   useEffect(
    () => {
      let is_edit_mode = GetUrlHashValue("edit")
      let isEdit = false
      let get_course_id = ""
      if (is_edit_mode === "true") {
        setEditMode(true)
        isEdit = true

        get_course_id = GetUrlHashValue("id")
        setCourseID(get_course_id)
      }

      if (recheckAccessTokenIsExpired() || getCurrentUserType() !== "admin") {
        navigate('/');
      }

      let enable_customize_boxes = function() {
        setcustomizeBoxOn(true)
        setThumbnailOn(true)
        setTeaserOn(true)
        setCertificateOn(true)
      }

      let load_caches = function(categories_data_load) {

        for (let name in input_state) {

            let cache = window.localStorage.getItem("newcourse_p1_"+name);
            //console.log(cache, name)
            if (cache) {
              if (name === "duration") {
                setNumValue(name, cache)
              } else if (name === "is_certificated" || name === "is_recommended") {
                setBoolValue(name, cache)
              } else if (name === "thumbnail" || name === "teaser" || name === "certificate_template") {
                let json = JSON.parse(cache)
                setValueJSON(name, json)
              } else if (name === "category") {

                  if (cache !== "null") {
                    for (let category of categories_data_load) {
                      if (cache === category.id) {
                        if (category.sub_category) {
                          setSubCategoriesData(category.sub_category)
                        } else {
                          setSubCategoriesData([])
                        }
                      }
                    }
                  } else {
                          setSubCategoriesData([])
                  }

                  setValue(name, cache)
                
              } else {
                setValue(name, cache)
              }
            }
          
        }

        setEnumLoaded(true)

        enable_customize_boxes()

      }

      let access_token_data = window.localStorage.getItem("access_token_data");
      let access_token_data_type = window.localStorage.getItem("access_token_data_type");

      const urlx = Configuration("user_host")+"/api/v1/enum/display-feature-info";

      const fetchDataX = async () => {
        try {
          const {data: response} = await axios.get(urlx);
          let the_data = response.data
          the_data.splice(2, 0, {
            id: parseInt(the_data[0].id)+parseInt(the_data[1].id),
            name: the_data[0].name +" + "+ the_data[1].name,
          })
          setDisabilityInputEnum(the_data)
          //console.log(the_data)
          fetchDataX2()
            
        } catch (error) {
          if (error.response.status < 500) {
            setErrorMsg("Enum Load Error")
          } else {
            setErrorMsg("Internal Server Error")
          }
        }
      };

      let urlx2 = Configuration("main_host")+"/api/v1/course/categories";

      const fetchDataX2 = async () => {
        try {
          const {data: response} = await axios({
            method: "get",
            url: urlx2,
            headers: { "Authorization": access_token_data_type + " " + access_token_data },
          });
          const the_data = response.data
          setCategoriesData(the_data)

          if (isEdit) {
            fetchData(the_data)
          } else {
            load_caches(the_data)
          }

        } catch (error) {
          if (error.response.status < 500) {
            setErrorMsg("Category Load Error")
          } else {
            setErrorMsg("Internal Server Error")
          }
          setDialogAlertOn(true)
        }
      };

      const url = Configuration("main_host")+"/api/v1/course/"+get_course_id;

      const fetchData = async (categories_data_load) => {
        try {
          const {data: response} = await axios({
            method: "get",
            url: url,
            headers: { "Authorization": access_token_data_type + " " + access_token_data },
          });
          let the_data = response.data

                if (the_data.category !== "null") {
                    for (let category of categories_data_load) {
                      if (the_data.category === category.id) {
                        if (category.sub_category) {
                          setSubCategoriesData(category.sub_category)
                        } else {
                          setSubCategoriesData([])
                        }
                      }
                    }
                  } else {
                          setSubCategoriesData([])
                  }

          setInputState(the_data)

          setEnumLoaded(true)

        } catch (error) {
          if (error.response.status < 500) {
            setErrorMsg("Reload Error")
          } else {
            setErrorMsg("Internal Server Error")
          }
        }

        enable_customize_boxes()
      };

      fetchDataX()

    },
    []
  )

    return (
      <main>
        <NavBar isCourse={true}/>
        <div className='container'>
      <div className={"course_body_profile"}>

      {dialog_alert_on ? <AlertDialog alert_title={error_msg} alert_msg={""} parentCallback={closeAlertDialog}/> : ""}

      {dialog_confirm_on ? <ConfirmDialog confirm_title={"คุณต้องการจะลบใช่หรือไม่?"} confirm_msg={""} dialog_data={dialog_data} parentCallback={deleteSomething} parentCallbackCancel={cancelDeleteDialog}/> : ""}

        <div className="boxes">
          <ProfileMenu isAdmin={true}/>

          <div className="admin_profile_detail_box">
            
            <NewCourseHeader parentCallback={submitPostOrEditData}/>

            <NewCourseProgress progress={1}/>

            <div className="boxes">
            <h4 className="new_course_title">Section 1 : Introduction</h4> <NewCourseNaviBox parentCallback={submitPostOrEditData}/>
            </div>

            <h5 className="new_course_label_txt">Course Name</h5>
            <input className="new_course_label_txt_box" type="text" name="name" defaultValue={input_state.name} onChange={e => setValue(e.target.name, e.target.value)}/>

            <h5 className="new_course_label_txt">Course Title</h5>
            <input className="new_course_label_txt_box" type="text" name="course_title" defaultValue={input_state.course_title} onChange={e => setValue(e.target.name, e.target.value)}/>

            <h5 className="new_course_label_txt">Course Subtitle</h5>
            <input className="new_course_label_txt_box" type="text" name="course_subtitle" defaultValue={input_state.course_subtitle} onChange={e => setValue(e.target.name, e.target.value)}/>

            <h5 className="new_course_label_txt">Course Instructor</h5>
            <input className="new_course_label_txt_box" type="text" name="author" defaultValue={input_state.author} onChange={e => setValue(e.target.name, e.target.value)}/>

            <h5 className="new_course_label_txt">Basic Information</h5>
            <div className="course_add_txt_box_triple">
            <select className="new_course_label_txt_box course_add_txt_box_in_triple_1" name="language" value={input_state.language} onChange={e => setValue(e.target.name, e.target.value)}>
                <option disabled value="null" hidden>Language</option>
                <optgroup label="Language">
                  <option value="thai">Thai</option>
                  <option value="english">English</option>
                </optgroup>
              </select>
            <select className="new_course_label_txt_box course_add_txt_box_in_triple_2" name="category" value={input_state.category} onChange={e => setCategoryValue(e.target.name, e.target.value)}>
                <option disabled value="null" hidden>Catagory</option>
                <optgroup label="Catagory">
                { categories_data.map((item,index) => {
                      return (
                          <option key={index} value={item.id}>{item.display_name}</option>
                        )
                      })}
                </optgroup>
              </select>
            
            <select className="new_course_label_txt_box course_add_txt_box_in_triple_3" name="sub_category" value={input_state.sub_category} onChange={e => setValue(e.target.name, e.target.value)}>
                <option disabled value="null" hidden>Sub-Catagory</option>
                <optgroup label="Sub-Catagory">
                { subcategories_data.map((item,index) => {
                      return (
                          <option key={index} value={item.id}>{item.display_name}</option>
                        )
                      })}
                </optgroup>
              </select>
            </div>

            <div className="course_add_txt_box_double">
              <div className="course_add_txt_box_in_double_1 boxes">
                <div className="new_course_label_txt horizontal_txtbox_left">Duration (Hours)</div>
                <input className="new_course_label_txt_box horizontal_txtbox_right" type="number" min="0" name="duration" value={input_state.duration} onChange={e => setNumValue(e.target.name, e.target.value)}/>
              </div>

              <select className="new_course_label_txt_box new_course_label_txt_box_small course_add_txt_box_in_double_2" name="is_recommended" value={input_state.is_recommended} onChange={e => setBoolValue(e.target.name, e.target.value)}>
                <option disabled value="null" hidden>Recommended course</option>
                <optgroup label="Recommended course">
                  <option value="true">Recommended = Yes</option>
                  <option value="false">Recommended = No</option>
                </optgroup>
              </select>
            </div>

            <div className="course_add_txt_box_triple">
              <select className="new_course_label_txt_box new_course_label_txt_box_small course_add_txt_box_in_triple_1" name="course_app" value={input_state.course_app} onChange={e => setValue(e.target.name, e.target.value)}>
                <option disabled value="null" hidden>Course App</option>
                <optgroup label="Course App">
                  <option value="video">Video</option>
                  <option value="exam">Exam</option>
                </optgroup>
              </select>

              <select className="new_course_label_txt_box new_course_label_txt_box_small course_add_txt_box_in_triple_2" name="display_to" value={input_state.display_to} onChange={e => setNumValue(e.target.name, e.target.value)}>
                <option disabled value="null" hidden>Display To</option>
                <optgroup label="Display To">
                { enum_loaded ? (disability_input_enum.map((item,index) => {
                      return (
                          <option key={index} value={item.id}>{item.name}</option>
                        )
                    })
                    ) : ""}
                </optgroup>
              </select>

              <select className="new_course_label_txt_box new_course_label_txt_box_small course_add_txt_box_in_triple_3" name="is_certificated" value={input_state.is_certificated} onChange={e => setBoolValue(e.target.name, e.target.value)}>
                <option disabled value="null" hidden>Certificate</option>
                <optgroup label="Certificate">
                  <option value="true">Certificate = Yes</option>
                  <option value="false">Certificate = No</option>
                </optgroup>
              </select>
            </div>

            <br/>

            {customizeBoxOn ? <span>
            <h5 className="new_course_label_txt">Course Overview / Description</h5>
            <CustomizingTextArea componentID={"description_box"} componentName={"overview_desc"} initTxt={input_state.overview_desc} parentCallback={setValueBigBox}/>

            <h5 className="new_course_label_txt">What you’ll learn in this course</h5>
            <CustomizingTextArea componentID={"to_learn_box"} componentName={"learning_desc"} initTxt={input_state.learning_desc} parentCallback={setValueBigBox}/>

            <h5 className="new_course_label_txt">Assignment & Grading Explanation</h5>
            <CustomizingTextArea componentID={"assignment_explain_box"} componentName={"assignment_desc"} initTxt={input_state.assignment_desc} parentCallback={setValueBigBox}/>

            </span> : ""}

            <br/>

            <h5 className="new_course_label_txt">Thumbnail picture [.jpg / .png]</h5>
            {thumbnailOn ? 
            <div className="course_add_txt_box_double">
              <input className={"btn_upload horizontal_txtbox_left"} type="file" onChange={e => uploadThumbnail(e.target.files)}/>
              {input_state.thumbnail ? <img className="preview_img_upload" alt="thumbnail" src={input_state.thumbnail.source}/> : <span className="no_upload">Preview</span>}
            </div> : ""}

           {input_state.thumbnail ? (!editMode ? <button className="admin_profile_detail_button" onClick={() => cancelThumbnail(input_state.thumbnail.storage_id)}>Cancel</button> : <button className="admin_profile_detail_button" onClick={() => activateDeleteDialog(input_state.thumbnail.storage_id, "thumbnail")}>Delete</button>) : ""}

            <h5 className="new_course_label_txt">Video course teaser / Cover picture on course overview page [.mp4 /.jpg / .png]</h5>
            {teaserOn ? 
            <div className="course_add_txt_box_double">
              <input className={"btn_upload horizontal_txtbox_left"} type="file" onChange={e => uploadTeaser(e.target.files)}/>
              {input_state.teaser ? (input_state.teaser.media_type === "video" ? (input_state.teaser.video_type === "upload" ? <video className={"preview_img_upload"}
              src={input_state.teaser.source}
             controls /> : <iframe title="preview_img_upload" className="preview_img_upload" src={YoutubeConvertToEmbed(input_state.teaser.source)} allowFullScreen></iframe>)
              : <img className="preview_img_upload" alt="teaser" src={input_state.teaser.source}/>) : <span className="no_upload">Preview</span>}
            </div> : ""}
            <br/>
            <div className="boxes yt_boxes">
                <div className="new_course_label_txt horizontal_txtbox_left">Youtube URL</div>
                <input className="new_course_label_txt_box horizontal_txtbox_right" onChange={e => setYoutubeTeaser(e.target.value)}/>
                <button className="admin_profile_detail_button_3" onClick={putYoutubeTeaser}>Use</button>
              </div>

            {input_state.teaser ? (!editMode ? <button className="admin_profile_detail_button" onClick={() => cancelTeaser(input_state.teaser.storage_id)}>Cancel</button> : <button className="admin_profile_detail_button" onClick={() => activateDeleteDialog(input_state.teaser.storage_id, "teaser")}>Delete</button>) : ""}

            {input_state.is_certificated === true ? <div>

            <h5 className="new_course_label_txt">Certificate Template [.pdf]</h5>
            {certificateOn ? 
            <div className="course_add_txt_box_double">
              <input className={"btn_upload horizontal_txtbox_left"} type="file" onChange={e => uploadCertificate(e.target.files)}/>
              {input_state.certificate_template ? <span className="no_upload">{input_state.certificate_template.source}</span> : <span className="no_upload">Not Uploaded</span>}
            </div> : ""}

           {input_state.certificate_template ? (!editMode ? <button className="admin_profile_detail_button" onClick={() => cancelCertificate(input_state.certificate_template.storage_id)}>Cancel</button> : <button className="admin_profile_detail_button" onClick={() => activateDeleteDialog(input_state.certificate_template.storage_id, "certificate_template")}>Delete</button>) : ""}

            </div> : ""}

          </div>
        </div>
        
        
      </div>
      <HomeFooter/>
      </div>
      </main>
    );
  
}

export default ProfileAdminNewCoursePart1;
