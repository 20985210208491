import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

function CourseAdminDropdown(props) {
  const parentCallbackDuplicate = props.parentCallbackDuplicate
  const parentCallbackDelete = props.parentCallbackDelete
  const course_id = props.courseID
  const [is_open, setOpen] = useState(false)

  const navigate = useNavigate();

  const handleClick = () => {
    setOpen(!is_open)
  }

  const goToEditCourse1 = (course_id) => {
      navigate('/profile_admin_new_course_1#id='+course_id+"&edit=true");
   } 

  const goToEditCourse2 = (course_id) => {
      navigate('/profile_admin_new_course_2#id='+course_id);
   } 

  const goToEditCourse3 = (course_id) => {
      navigate('/profile_admin_new_course_3#id='+course_id);
   } 

  const goToEditCourse4 = (course_id) => {
      navigate('/profile_admin_new_course_4#id='+course_id);
   } 

  const duplicateDialog = (course_id) => {
          parentCallbackDuplicate(course_id)
          setOpen(!is_open)
  }

  const deleteDialog = (course_id) => {
          parentCallbackDelete(course_id)
          setOpen(!is_open)
  }

    return (
      <span>
      <button className="navbar_item_admin navbar2_item" onClick={handleClick}>

        &#9660;

      </button>
      {is_open ? <div className={"dropdown-content-admin-course"}>
          <br/>
          <a className="navbar2_hover_menu_item" onClick={e => goToEditCourse1(course_id)}>Edit Page 1</a><br/>
          <a className="navbar2_hover_menu_item" onClick={e => goToEditCourse2(course_id)}>Edit Page 2</a><br/>
          <a className="navbar2_hover_menu_item" onClick={e => goToEditCourse3(course_id)}>Edit Page 3</a><br/>
          <a className="navbar2_hover_menu_item" onClick={e => goToEditCourse4(course_id)}>Preview/Publish</a><br/>
          <a className="navbar2_hover_menu_item" onClick={e => duplicateDialog(course_id)}>Duplicate</a><br/>
          <a className="navbar2_hover_menu_item" onClick={e => deleteDialog(course_id)}>Delete</a><br/>
        </div> : ""}
      </span>
    );
}


export default CourseAdminDropdown