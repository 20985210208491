import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import Configuration from '../functions/Configuration';
import recheckAccessTokenIsExpired from '../functions/recheckAccessTokenIsExpired';
import getCurrentUserType from '../functions/getCurrentUserType';
import SimplifyDateString from '../functions/SimplifyDateString';
import AlertDialog from '../components/AlertDialog';
import ConfirmDialog from '../components/ConfirmDialog';
import ProfileMenu from '../components/ProfileMenu';
import CourseAdminDropdown from '../components/CourseAdminDropdown';
import NavBar from '../components/NavBar';
import HomeFooter from '../components/HomeFooter';

function ProfileAdminCourseList() {
  const [is_loaded, setIsLoaded] = useState(false)
  const [course_list, setCourseList] = useState([])

  const [filter_publish, setFilterPublish] = useState("null")
  const [filter_author, setFilterAuthor] = useState("")

  const [dialog_confirm_on, setDialogConfirmOn] = useState(false)
  const [dialog_data, setDialogData] = useState(false)

  const [disability_input_enum, setDisabilityInputEnum] = useState([]);

  const [dialog_alert_on, setDialogAlertOn] = useState(false)
  const [error_msg, setErrorMsg] = useState("")

  const navigate = useNavigate();

  const goToNewCourse = () => {
       navigate('/profile_admin_new_course_1');
   }

  const goToManageStudents = (course_id) => {
      navigate('/profile_admin_course_user_list#id='+course_id);
   } 

  const refreshTable = () => {
    setIsLoaded(false)
    reloadTable(filter_publish, filter_author)
  }

  const selectFilterPublish = (value) => {
    setIsLoaded(false)
    setFilterPublish(value)
    reloadTable(value, filter_author)
  }

  const selectFilterAuthor = (value) => {
    setIsLoaded(false)
    setFilterAuthor(value)
    reloadTable(filter_publish, value)
  }

  const activateDeleteDialog = (course_id) => {
    setDialogConfirmOn(true)
    setDialogData(course_id)
  }

  const duplicateCourse = (course_id) => {
    let access_token_data = window.localStorage.getItem("access_token_data");
    let access_token_data_type = window.localStorage.getItem("access_token_data_type");

    const url = Configuration("main_host")+"/api/v1/course/"+course_id+"/duplicate";

      const postData = async () => {
        try {
          await axios({
            method: "post",
            url: url,
            headers: { "Authorization": access_token_data_type + " " + access_token_data },
          });
          refreshTable()
          //setDialogConfirmOn(false)
        } catch (error) {
          if (error.response.status < 500) {
            setErrorMsg("Duplicate Course Error")
          } else {
            setErrorMsg("Internal Server Error")
          }
          setDialogAlertOn(true)
        }
      };

      postData()
  }

  const cancelDeleteDialog = () => {
    setDialogConfirmOn(false)
  }

  const closeAlertDialog = () => {
    setDialogAlertOn(false)
  }

  const deleteCourse = (course_id) => {
    let access_token_data = window.localStorage.getItem("access_token_data");
    let access_token_data_type = window.localStorage.getItem("access_token_data_type");

    const url = Configuration("main_host")+"/api/v1/course/"+course_id;

      const deleteData = async () => {
        try {
          await axios({
            method: "delete",
            url: url,
            headers: { "Authorization": access_token_data_type + " " + access_token_data },
          });
          refreshTable()
          setDialogConfirmOn(false)
        } catch (error) {
          if (error.response.status < 500) {
            setErrorMsg("Delete Course Error")
          } else {
            setErrorMsg("Internal Server Error")
          }
          setDialogAlertOn(true)
        }
      };

      deleteData()
  }

  const reloadTable = (status, author) => {
    let access_token_data = window.localStorage.getItem("access_token_data");
    let access_token_data_type = window.localStorage.getItem("access_token_data_type");

    let url = Configuration("main_host")+"/api/v1/course?";

    if (status) {
      if (status !== "null" && status !== "") {
        url += "&status="+status
      }
    } else if (filter_publish !== "null" && filter_publish !== "") {
      url += "&status="+filter_publish
    }

    if (author) {
      if (author !== "") {
        url += "&author="+author
      }
    } else if (filter_author !== "") {
      url += "&author="+filter_author
    }

      const fetchData = async () => {
        try {
          const {data: response} = await axios({
            method: "get",
            url: url,
            headers: { "Authorization": access_token_data_type + " " + access_token_data },
          });
          if (!response.data) {
            setCourseList([])
          } else {
            setCourseList(response.data)
          }
          
          fetchData2()
        } catch (error) {
          if (error.response.status < 500) {
            setErrorMsg("Table Load Error")
          } else {
            setErrorMsg("Internal Server Error")
          }
          setDialogAlertOn(true)
        }
      };

      const url2 = Configuration("user_host")+"/api/v1/enum/display-feature-info";

      const fetchData2 = async () => {
        try {
          const {data: response} = await axios.get(url2);
          let the_data = response.data
          the_data.splice(2, 0, {
            id: parseInt(the_data[0].id)+parseInt(the_data[1].id),
            name: the_data[0].name +" + "+ the_data[1].name,
          })
          setDisabilityInputEnum(the_data)
          //console.log(the_data)
          setIsLoaded(true)
            
        } catch (error) {
          if (error.response.status < 500) {
            setErrorMsg("Enum Load Error")
          } else {
            setErrorMsg("Internal Server Error")
          }
        }
      };

      fetchData()
  }

  const getDisplayToValueAsName = (value) => {
    for (let data_enum of disability_input_enum) {
      if (data_enum.id === value) {
        return data_enum.name
      }
    }
  }

   useEffect(
    () => {

      if (recheckAccessTokenIsExpired() || getCurrentUserType() !== "admin") {
        navigate('/');
      }

      reloadTable(undefined, undefined)

    },
    []
  )

    return (
      <main>
        <NavBar isCourse={true}/>
        <div className='container'>
      <div className={"course_body_profile"}>

        {dialog_alert_on ? <AlertDialog alert_title={error_msg} alert_msg={""} parentCallback={closeAlertDialog}/> : ""}

        {dialog_confirm_on ? <ConfirmDialog confirm_title={"คุณต้องการจะลบใช่หรือไม่?"} confirm_msg={""} dialog_data={dialog_data} parentCallback={deleteCourse} parentCallbackCancel={cancelDeleteDialog}/> : ""}

        <div className="boxes">
          <ProfileMenu/>

          <div className="admin_profile_detail_box">

          {is_loaded ? <div>
            
            <div className="boxes">

              <div className="admin_profile_detail_title">
                <h2>Courses</h2>
                <h4>Create and Manage courses in Vulcan Academy</h4>
              </div>

              <div className="admin_profile_detail_button_container">
                <button className="admin_profile_detail_button" onClick={goToNewCourse}>New Courses</button>
              </div>

            </div>

            <div className="course_filter_input_body_admin">
              <select className="course_list_search_1 filter_course_label_txt_box course_filter_input_body_1" name="filter" defaultValue={filter_publish} onChange={e => selectFilterPublish(e.target.value)}>
                <option disabled value="null" hidden>Published?</option>
                <optgroup label="Published?">
                  <option value="null">Show All</option>
                  <option value="published">Published = Yes</option>
                  <option value="unpublished">Published = No</option>
                </optgroup>
              </select>

              <label className="label_icon label_icon_search course_filter_input_body_3">
                  <input className="filter_course_label_txt_box_white insider_txt_box_with_icon" 
                  name="author" 
                  defaultValue={filter_author}
                  placeholder="ค้นหาตาม Author"
                  onChange={e => selectFilterAuthor(e.target.value)}
                  />
                </label>
            </div>

        <table className="course_list_table">
         <thead className="course_list_thread">
           <tr className="course_list_tr">
             <th className="course_list_th"></th>
             <th className="course_list_th">Course Title</th>
             <th className="course_list_th">Author</th>
             <th className="course_list_th">Creation Date</th>
             <th className="course_list_th">Display To</th>
             <th className="course_list_th">Status</th>
             <th className="course_list_th">Actions</th>
          </tr>
         </thead>
         <tbody className="course_list_tbody">
          {course_list ? (course_list.length > 0 ? (course_list.map((key, index) => {
            //console.log(key)
            return (
              <tr className="course_list_tr" key={key.id}>
                <td className="course_list_td"><img alt="course_thumbnail" className="small_thumbnail" width="75" height="50" src={key.thumbnail.source}/></td>
                <td className="course_list_td"><button className="course_name_btn" onClick={e => goToManageStudents(key.id)}>{key.course_title}</button></td>
                <td className="course_list_td">{key.author}</td>
                <td className="course_list_td">{SimplifyDateString(key.created_at)}</td>
                <td className="course_list_td">{getDisplayToValueAsName(key.display_to)}</td>
                <td className="course_list_td">{Configuration("proper_text_display")[key.status]}</td>
                <td className="course_list_td"><CourseAdminDropdown courseID={key.id} parentCallbackDuplicate={duplicateCourse} parentCallbackDelete={activateDeleteDialog}/></td>
              </tr>
            );
          })) : (<tr className="course_list_tr">
                <td className="course_list_td"></td>
                <td className="course_list_td">ไม่พบรายการใดๆ</td>
                <td className="course_list_td"></td>
                <td className="course_list_td"></td>
                <td className="course_list_td"></td>
                <td className="course_list_td"></td>
                <td className="course_list_td"></td>
              </tr>)
          ) : 
              (<tr className="course_list_tr">
                <td className="course_list_td"></td>
                <td className="course_list_td">Loading...</td>
                <td className="course_list_td"></td>
                <td className="course_list_td"></td>
                <td className="course_list_td"></td>
                <td className="course_list_td"></td>
                <td className="course_list_td"></td>
              </tr>)}
          </tbody>
        </table>

          </div> : ""}

          </div>
        </div>
      
        
      </div>
      <HomeFooter/>
      </div>
      </main>
    );
  
}

export default ProfileAdminCourseList;
