import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import axios from 'axios';
import Configuration from '../functions/Configuration';
import OutsiderNavbar from '../components/OutsiderNavbar';
import WelcomeBox from '../components/WelcomeBox';
import password_show from '../res/password_show.svg';
import password_hide from '../res/password_hide.svg';

function ResetPassword() {
    const { state } = useLocation()

    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")

    const [passwordState, setPasswordState] = useState({
        passwordType: "password",
        passwordEyeSrc: password_show
    })
    const [passwordConfirmState, setPasswordConfirmState] = useState({  
        passwordType: "password",
        passwordEyeSrc: password_show
      })

    const [is_error, setIsError] = useState(false)
    const [error_msg, setErrorMsg] = useState("")

    const navigate = useNavigate();

    const handleSubmitResetPassword = () => {

        if (!password) {
            setIsError(true)
            setErrorMsg("กรุณากรอกรหัสผ่าน")
            return
        } else if (!confirmPassword) {
            setIsError(true)
            setErrorMsg("กรุณากรอกยืนยันรหัสผ่าน")
            return
        } else if (password !== confirmPassword) {
            setIsError(true)
            setErrorMsg("รหัสผ่าน และ ยืนยันรหัสผ่าน ไม่ตรงกัน")
            return
        } else if (password.length < 6) {
            setIsError(true)
            setErrorMsg("รหัสผ่านของคุณต้องมีความยาวไม่น้อยกว่า 6 ตัว")
            return
        }

        const url = Configuration("user_host") + "/api/v1/login/confirm-password-reset";

        const postData = async () => {
            let passwordBody = {
                "password": password
            }

            try {
                const response = await axios({
                    method: "post",
                    url: url,
                    data: passwordBody,
                    headers: { "Content-Type": "application/json", "Authorization": "Bearer " + state.token },
                });

                setIsError(false)
                navigate('/reset_password_success');


            } catch (error) {
                if (error.response.status < 500) {
                    setErrorMsg("Invalid Password")
                } else {
                    setErrorMsg("Internal Server Error")
                }

                setIsError(true)
            }
        };

        postData()

    }

    const onClickPasswordEye = () => {
        if (passwordState.passwordType == "password") {
            setPasswordState({
                passwordEyeSrc: password_hide,
                passwordType: "text",
            })
        } else {
            setPasswordState({
                passwordEyeSrc: password_show,
                passwordType: "password",
            })
        }
    }

    const onClickPasswordConfirmEye = () => {
        if (passwordConfirmState.passwordType == "password") {
            setPasswordConfirmState({
                passwordEyeSrc: password_hide,
                passwordType: "text",
            })
        } else {
            setPasswordConfirmState({
                passwordEyeSrc: password_show,
                passwordType: "password",
            })
        }
    }

    const _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleSubmitResetPassword()
        }
    }

    useEffect(
        () => {
            document.body.style.background = "#503e9d"
            document.body.style.padding = "0 0 0 0";
        },
        []
    )

    return (
        <div>

            <div>

                <OutsiderNavbar />

                <div className="boxes">

                    <div className="init_form_box">
                        <h4 className="outsider_txt outsider_txt_bold">กรุณาตั้งรหัสผ่านใหม่</h4>
                        <h6 className="outsider_txt outsider_txt_last">รหัสผ่านของคุณต้องมีความยาวไม่น้อยกว่า 6 ตัว</h6>

                        {is_error ? <h6 className="outsider_login_error">{error_msg}</h6> : ""}

                        <label className="label_icon label_icon_password">
                            <input className="outsider_txt_box outsider_txt_box_login" type={passwordState.passwordType} name="password" placeholder="รหัสผ่านใหม่" onChange={e => setPassword(e.target.value)} onKeyDown={_handleKeyDown} />
                            <img className='password_eye' alt="show password" src={passwordState.passwordEyeSrc} onClick={onClickPasswordEye} />
                        </label>
                        <br />
                        <label className="label_icon label_icon_password">
                            <input className="outsider_txt_box outsider_txt_box_login" type={passwordConfirmState.passwordType} name="confirm_password" placeholder="ยืนยันรหัสผ่าน" onChange={e => setConfirmPassword(e.target.value)} onKeyDown={_handleKeyDown} />
                            <img className='password_eye' alt="show password" src={passwordConfirmState.passwordEyeSrc} onClick={onClickPasswordConfirmEye} />
                        </label>
                        <br />

                        <div className="outsider_btns">
                            <input className="outsider_btn_base outsider_btn_regis" type="submit" value="เปลี่ยนรหัสผ่าน" onClick={handleSubmitResetPassword} />
                        </div>

                    </div>

                    <WelcomeBox isRegis={false} />

                </div>

            </div>

        </div>
    );

}

export default ResetPassword;