import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import jwt_decode from "jwt-decode";
import Configuration from '../functions/Configuration';
import OutsiderNavbar from '../components/OutsiderNavbar';
import WelcomeBox from '../components/WelcomeBox';
import password_show from '../res/password_show.svg';
import password_hide from '../res/password_hide.svg';

function Login() {
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")

  const [passwordState, setPasswordState] = useState({  
      passwordType: "password",
      passwordEyeSrc: password_show
  })

  const [is_error, setIsError] = useState(false)
  const [error_msg, setErrorMsg] = useState("")

  const navigate = useNavigate();

  const handleSubmit = () => {
    const url = Configuration("user_host") + "/api/v1/login/login";

    const postData = async () => {
      let form_data = new FormData()
      form_data.append("username", username)
      form_data.append("password", password)

      try {
        const response = await axios({
          method: "post",
          url: url,
          data: form_data,
          headers: { "Content-Type": "multipart/form-data" },
        });
        //console.log(response);

        let access_token = response.data.data.access_token
        let refresh_token = response.data.data.refresh_token
        let token_type = response.data.data.token_type

        window.localStorage.setItem("access_token_data", access_token);
        window.localStorage.setItem("refresh_token_data", refresh_token);
        window.localStorage.setItem("access_token_data_type", token_type);

        setIsError(false)

        let decoded_data = jwt_decode(access_token);
        /* **** access_token data , how it looks
          {
            "user_id": "str",
            "email": "str",
            "first_name": "str ",
            "last_name": "str",
            "user_type": "admin"/"vulcan_hero"/"general_user",
            "exp": timestamp_raw
          }

          for refresh_token :
          {
            "user_id": "str",
            "user_type": "admin"/"vulcan_hero"/"general_user",
            "exp": timestamp_raw
          }
        */

        if (decoded_data["user_type"] === "admin") {
          navigate('/profile_admin_course_list');
        } else {
          navigate('/home');
        }

      } catch (error) {
        if (error.response.status < 500) {
          setErrorMsg("Invalid Username or Password")
        } else {
          setErrorMsg("Internal Server Error")
        }

        setIsError(true)
      }
    };

    postData()

  }

  const onClickPasswordEye = () => {
    if(passwordState.passwordType == "password"){
      setPasswordState({
        passwordEyeSrc: password_hide,
        passwordType: "text",
      })
    }else{
      setPasswordState({
        passwordEyeSrc: password_show,
        passwordType: "password",
      })
    }
  }

  const goToRegister = () => {
    //navigate('/register');
    navigate('/register_email_input');
  }

  const goToForgotPassword = () => {
    navigate('/forgot_password');
  }

  const _handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSubmit()
    }
  }

  useEffect(
    () => {
      document.body.style.background = "#503e9d"
      document.body.style.padding = "0 0 0 0";

      let user_email = window.localStorage.getItem("register_email");
      if (user_email) {
        window.localStorage.removeItem("register_email")
      }
    },
    []
  )

  return (
    <div>

      <div>

        <OutsiderNavbar />

        <div className="boxes">

          <div className="init_form_box">
            <h4 className="outsider_txt outsider_txt_bold">เข้าสู่ระบบ Vulcan Academy</h4>
            <h6 className="outsider_txt outsider_txt_last">หากเป็นผู้ใช้ใหม่ <button className="regis_link" onClick={goToRegister}>สร้างบัญชีใหม่</button></h6>

            {is_error ? <h6 className="outsider_login_error">{error_msg}</h6> : ""}

            <label className="label_icon label_icon_email">
              <input className="outsider_txt_box outsider_txt_box_login" type="email" name="email" placeholder="อีเมล" onChange={e => setUsername(e.target.value)} onKeyDown={_handleKeyDown} />
            </label>
            <br />
            <label className="label_icon label_icon_password">
                <input className="outsider_txt_box outsider_txt_box_login" type={passwordState.passwordType} name="password" placeholder="รหัสผ่าน" onChange={e => setPassword(e.target.value)} onKeyDown={_handleKeyDown} />
                <img className='password_eye' alt="show password" src={passwordState.passwordEyeSrc} onClick={onClickPasswordEye}/>
            </label>
            <button className="outsider_btn_pwd_new" onClick={goToForgotPassword}>ลืมรหัสผ่าน</button>
            <br />

            <div className="outsider_btns">
              <input className="outsider_btn_base outsider_btn_regis" type="submit" value="เข้าสู่ระบบ" onClick={handleSubmit} />
            </div>

          </div>

          <WelcomeBox isRegis={false} />

        </div>

      </div>

    </div>
  );

}

export default Login;