function getValueOutOfID(json_obj, id_to_match) {
  for (let i in json_obj) {
    let item = json_obj[i]
    if (item.id === id_to_match) {
      return item.name
    }
  }
  return ""
}

export default getValueOutOfID;