import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import Configuration from '../functions/Configuration';
import recheckAccessTokenIsExpired from '../functions/recheckAccessTokenIsExpired';
import getCurrentUserType from '../functions/getCurrentUserType';
import AlertDialog from '../components/AlertDialog';
import GetUrlHashValue from '../functions/GetUrlHashValue';
import ProfileMenu from '../components/ProfileMenu';
import NewCourseHeader from '../components/NewCourseHeader';
import NewCourseProgress from '../components/NewCourseProgress';
import RenderMarkdownHTML from '../functions/RenderMarkdownHTML';
import YoutubeConvertToEmbed from '../functions/YoutubeConvertToEmbed';
import created_check from '../res/created_check.svg';
import NavBar from '../components/NavBar';
import HomeFooter from '../components/HomeFooter';

function ProfileAdminNewCoursePart3() {
  const [course_id, setCourseID] = useState("")
  const [selected_lesson_id, setSelectedLessonID] = useState("")
  const [selected_quiz_id, setSelectedQuizID] = useState("")
  const [selected_question_id, setSelectedQuestionID] = useState("")
  const [selected_choice_id, setSelectedChoiceID] = useState("")

  const [lessons, setLessons] = useState([]);
  const [lessons_loaded, setLessonsLoaded] = useState(false);

  const [quizes_data, setQuizesData] = useState([]);
  const [quizes_loaded, setQuizesLoaded] = useState(false)

  const [questions_data, setQuestionsData] = useState([]);
  const [questions_loaded, setQuestionsLoaded] = useState(false)

  const [choices_data, setChoicesData] = useState([]);
  const [choices_loaded, setChoicesLoaded] = useState(false)

  const [course_data, setCourseData] = useState(undefined);
  const [course_loaded, setCourseLoaded] = useState(false);

  const [dialog_alert_on, setDialogAlertOn] = useState(false)
  const [error_msg, setErrorMsg] = useState("")

  const navigate = useNavigate();

  const selectLessonAndLoadQuizesList = (lesson_id) => {
    setSelectedLessonID(lesson_id)
    setSelectedQuizID("")
    setSelectedQuestionID("")
    setSelectedChoiceID("")
    setQuizesLoaded(false)
    setQuizesData([])

    let access_token_data = window.localStorage.getItem("access_token_data");
    let access_token_data_type = window.localStorage.getItem("access_token_data_type");

    const url = Configuration("video_host")+"/api/v1/course/"+course_id+"/lesson/"+lesson_id+"/quiz";

      const fetchData = async () => {
        try {
          const {data: response} = await axios({
            method: "get",
            url: url,
            headers: { "Authorization": access_token_data_type + " " + access_token_data },
          });
          let the_data = response.data
          //console.log(the_data)
          if (!the_data) {
            setQuizesData([])
          } else {
            setQuizesData(the_data)
          }

          setQuizesLoaded(true)
          
        } catch (error) {
          if (error.response.status < 500) {
            setErrorMsg("Select Load Error")
          } else {
            setErrorMsg("Internal Server Error")
          }
          setDialogAlertOn(true)
        }
      };

      fetchData()
  }

  const selectQuizAndLoadQuestionsList = (quiz_id) => {
    setSelectedQuizID("")
    setSelectedQuestionID("")
    setSelectedChoiceID("")
    setQuestionsLoaded(false)
    setQuestionsData([])

    let access_token_data = window.localStorage.getItem("access_token_data");
    let access_token_data_type = window.localStorage.getItem("access_token_data_type");

    const url = Configuration("video_host")+"/api/v1/course/"+course_id+"/lesson/"+selected_lesson_id+"/quiz/"+quiz_id;

      const fetchData = async () => {
        try {
          await axios({
            method: "get",
            url: url,
            headers: { "Authorization": access_token_data_type + " " + access_token_data },
          });
          setSelectedQuizID(quiz_id) //here load

          fetchData2()
          
        } catch (error) {
          if (error.response.status < 500) {
            setErrorMsg("Select Load Error")
          } else {
            setErrorMsg("Internal Server Error")
          }
          setDialogAlertOn(true)
        }
      };

    const url2 = Configuration("video_host")+"/api/v1/course/"+course_id+"/lesson/"+selected_lesson_id+"/quiz/"+quiz_id+"/question";

      const fetchData2 = async () => {
        try {
          const {data: response} = await axios({
            method: "get",
            url: url2,
            headers: { "Authorization": access_token_data_type + " " + access_token_data },
          });
          let the_data = response.data
          //console.log(the_data)
          if (!the_data) {
            setQuestionsData([])
          } else {
            setQuestionsData(the_data)
          }

          setQuestionsLoaded(true)
          
        } catch (error) {
          if (error.response.status < 500) {
            setErrorMsg("Select Load Error")
          } else {
            setErrorMsg("Internal Server Error")
          }
          setDialogAlertOn(true)
        }
      };

      fetchData()
  }

  const selectQuestionAndLoadChoicesList = (question_id) => {
    setSelectedQuestionID("")
    setSelectedChoiceID("")
    setChoicesLoaded(false)
    setChoicesLoaded([])

    let access_token_data = window.localStorage.getItem("access_token_data");
    let access_token_data_type = window.localStorage.getItem("access_token_data_type");

    const url = Configuration("video_host")+"/api/v1/course/"+course_id+"/lesson/"+selected_lesson_id+"/quiz/"+selected_quiz_id+"/question/"+question_id;

      const fetchData = async () => {
        try {
          await axios({
            method: "get",
            url: url,
            headers: { "Authorization": access_token_data_type + " " + access_token_data },
          });
          setSelectedQuestionID(question_id) //here load

          fetchData2()
          
        } catch (error) {
          if (error.response.status < 500) {
            setErrorMsg("Select Load Error")
          } else {
            setErrorMsg("Internal Server Error")
          }
          setDialogAlertOn(true)
        }
      };

    const url2 = Configuration("video_host")+"/api/v1/course/"+course_id+"/lesson/"+selected_lesson_id+"/quiz/"+selected_quiz_id+"/question/"+question_id+"/choice";

      const fetchData2 = async () => {
        try {
          const {data: response} = await axios({
            method: "get",
            url: url2,
            headers: { "Authorization": access_token_data_type + " " + access_token_data },
          });
          let the_data = response.data
          //console.log(the_data)
          if (!the_data) {
            setChoicesData([])
          } else {
            setChoicesData(the_data)
          }

          setChoicesLoaded(true)
          
        } catch (error) {
          if (error.response.status < 500) {
            setErrorMsg("Select Load Error")
          } else {
            setErrorMsg("Internal Server Error")
          }
          setDialogAlertOn(true)
        }
      };

      fetchData()
  }

  const selectChoice = (choice_id) => {
    setSelectedChoiceID("")

    let access_token_data = window.localStorage.getItem("access_token_data");
    let access_token_data_type = window.localStorage.getItem("access_token_data_type");

    const url = Configuration("video_host")+"/api/v1/course/"+course_id+"/lesson/"+selected_lesson_id+"/quiz/"+selected_quiz_id+"/question/"+selected_question_id+"/choice/"+choice_id;

      const fetchData = async () => {
        try {
          await axios({
            method: "get",
            url: url,
            headers: { "Authorization": access_token_data_type + " " + access_token_data },
          });
          setSelectedChoiceID(choice_id) //here load

          //console.log(choice_id)
          
        } catch (error) {
          if (error.response.status < 500) {
            setErrorMsg("Select Load Error")
          } else {
            setErrorMsg("Internal Server Error")
          }
          setDialogAlertOn(true)
        }
      };

      fetchData()
  }

   const reloadLessonsList = (course_id) => {
    let access_token_data = window.localStorage.getItem("access_token_data");
    let access_token_data_type = window.localStorage.getItem("access_token_data_type");

    const url = Configuration("video_host")+"/api/v1/course/"+course_id+"/lesson";

      const fetchData = async () => {
        try {
          const {data: response} = await axios({
            method: "get",
            url: url,
            headers: { "Authorization": access_token_data_type + " " + access_token_data },
          });
          let the_data = response.data.lessons
          if (!the_data) {
            setLessons([])
          } else {
            setLessons(the_data)
          }
          setLessonsLoaded(true)
        } catch (error) {
          if (error.response.status < 500) {
            setErrorMsg("Reload Error")
          } else {
            setErrorMsg("Internal Server Error")
          }
          setDialogAlertOn(true)
        }
      };

      fetchData()
   }

   const backToCourseList = () => {
      navigate('/profile_admin_course_list');
   } 

   const publishCourse = () => {
    let access_token_data = window.localStorage.getItem("access_token_data");
    let access_token_data_type = window.localStorage.getItem("access_token_data_type");

    const url = Configuration("main_host")+"/api/v1/course/"+course_id+"/publish";

      const patchData = async () => {
        try {
          await axios({
            method: "patch",
            url: url,
            headers: { "Authorization": access_token_data_type + " " + access_token_data },
          });
          //console.log(response);

          backToCourseList()
          
        } catch (error) {
          if (error.response.status < 500) {
            setErrorMsg("Publish Error")
          } else {
            setErrorMsg("Internal Server Error")
          }
          setDialogAlertOn(true)
        }
      };

      patchData()
   }

   const unpublishCourse = () => {
    let access_token_data = window.localStorage.getItem("access_token_data");
    let access_token_data_type = window.localStorage.getItem("access_token_data_type");

    const url = Configuration("main_host")+"/api/v1/course/"+course_id+"/unpublish";

      const patchData = async () => {
        try {
          await axios({
            method: "patch",
            url: url,
            headers: { "Authorization": access_token_data_type + " " + access_token_data },
          });
          //console.log(response);

          backToCourseList()
          
        } catch (error) {
          if (error.response.status < 500) {
            setErrorMsg("Unpublish Error")
          } else {
            setErrorMsg("Internal Server Error")
          }
          setDialogAlertOn(true)
        }
      };

      patchData()
   }

   const closeAlertDialog = () => {
      setDialogAlertOn(false)
    }

   useEffect(
    () => {

      let get_course_id = GetUrlHashValue("id")
      setCourseID(get_course_id)

      document.body.style.background = "#503e9d"
      document.body.style.padding = "20px 2% 20px 2%";
      document.getElementById("root").style.background = "#503e9d"
      document.getElementById("root").style.borderRadius  = "20px"

      if (recheckAccessTokenIsExpired() || getCurrentUserType() !== "admin") {
        navigate('/');
      }

      let access_token_data = window.localStorage.getItem("access_token_data");
      let access_token_data_type = window.localStorage.getItem("access_token_data_type");

      const url = Configuration("main_host")+"/api/v1/course/"+get_course_id;

      const fetchData = async () => {
        try {
          const {data: response} = await axios({
            method: "get",
            url: url,
            headers: { "Authorization": access_token_data_type + " " + access_token_data },
          });
          var the_data = response.data
          setCourseData(the_data)
          setCourseLoaded(true)
            
        } catch (error) {
          if (error.response.status < 500) {
            setErrorMsg("Course Load Error")
          } else {
            setErrorMsg("Internal Server Error")
          }
        }

        reloadLessonsList(get_course_id)
      };

      fetchData()

    },
    []
  )

    return (
      <main>
        <NavBar isCourse={true}/>
        <div className='container'>
      <div className={"course_body_profile"}>

        {dialog_alert_on ? <AlertDialog alert_title={error_msg} alert_msg={""} parentCallback={closeAlertDialog}/> : ""}

        <div className="boxes">
          <ProfileMenu isAdmin={true}/>

          <div className="admin_profile_detail_box">
            
            <NewCourseHeader parentCallback={backToCourseList}/>

            <NewCourseProgress progress={4}/>

            <div className="boxes">
            <h4 className="new_course_title">Section 4 : Preview and Publish</h4>
            </div>

            {course_loaded ? <div>
            <p><b>Course Name :</b> {course_data.name}</p>

            <p><b>Course Title :</b> {course_data.course_title}</p>

            <p><b>Course Subtitle :</b> {course_data.course_subtitle}</p>

            <p><b>Course Instructor :</b> {course_data.author}</p>

            <p><b>Language :</b> {Configuration("proper_text_display")[course_data.language]}</p>

            <p><b>Catagory :</b> {Configuration("proper_text_display")[course_data.category]}</p>

            <p><b>Certificate :</b> {course_data.is_certificated ? "Yes" : "No"}</p>

            <p><b>Duration (Hours) :</b> {course_data.duration+""}</p>

            <p><b>Recommended course :</b> {course_data.is_recommended ? "Yes" : "No"}</p>

            <p><b>Course App :</b> {Configuration("proper_text_display")[course_data.course_app]}</p>

            <p><b>Course Overview / Description :</b></p>
            <p className="course_enroll_paragraph" dangerouslySetInnerHTML={{__html: RenderMarkdownHTML(course_data.overview_desc, true)}}></p>

            <p><b>What you’ll learn in this course :</b></p>
            <p className="course_enroll_paragraph" dangerouslySetInnerHTML={{__html: RenderMarkdownHTML(course_data.learning_desc, true)}}></p>

            <p><b>Assignment & Grading Explanation :</b></p>
            <p className="course_enroll_paragraph" dangerouslySetInnerHTML={{__html: RenderMarkdownHTML(course_data.assignment_desc, true)}}></p>

            {course_data.thumbnail ? <div>
              <p><b>Thumbnail :</b></p>
                <img className="preview_img_upload" alt="thumbnail" src={course_data.thumbnail.source}/><br/><br/>
              </div> : ""}

            {course_data.teaser ? <div>
              <p><b>Teaser :</b></p>
              {course_data.teaser.media_type === "video" ? (course_data.teaser.video_type === "upload" ? <video className={"preview_img_upload"}
              src={course_data.teaser.source}
             controls /> : <iframe title="preview_img_upload" className="preview_img_upload" src={YoutubeConvertToEmbed(course_data.teaser.source)} allowFullScreen></iframe>)
              : <img className="preview_img_upload" alt="teaser" src={course_data.teaser.source}/>}<br/><br/>
              </div> : ""}

              </div> : ""}

            {lessons_loaded ? <div>
              {lessons.length > 0 ? <h5 className="lesson_list_header">Lessons list :</h5> : <h5 className="lesson_list_header">There are currently no lessons,<br/> you can add it from page 2.</h5>}
              { 
                 lessons.map((item,index) => {
                    return (
                      <div className="course_edit_box_item" key={item.id+"_"+index}>
                      <p className="lesson_edit_select"><b><img alt="a created lesson" src={created_check} width="30" height="30"/> {item.title}</b> <br/>{item.videos ? item.videos.length+" Videos" : "0 Videos"} <span className="edit_nor_delete"><button className="edit_select" onClick={e => selectLessonAndLoadQuizesList(item.id)}>&#9660;</button></span></p>

                      {selected_lesson_id !== "" && selected_lesson_id === item.id && quizes_loaded ? <div>

                        <p><b>Description :</b> {item.description}</p>

                        <p><b>Videos :</b></p>
                        {
                          item.videos ? (
                              item.videos.map((item_v,index_v) => {
                                return(<p key={item_v.storage_id+"_"+index_v}>
                                  - {item_v.title}
                              </p>)})
                            ) : "<No Videos>"
                        }

                        <p><b>Materials :</b></p>
                        {
                          item.materials ? (
                              item.materials.map((item_m,index_m) => {
                                return(<p key={item_m.storage_id+"_"+index_m}>
                                  - {item_m.title}
                              </p>)})
                            ) : "<No Materials>"
                        }

                        {quizes_data.length > 0 ? <h5 className="new_course_label_txt quiz_list_header">Quizes :</h5> : ""}

                        {
                          quizes_data.map((item2,index2) => {
                            return (
                                <div className="course_edit_box_item" key={item2.id+"_"+index2}>
                                <p className="quiz_edit_select"><img alt="a created quiz" src={created_check} width="30" height="30"/> <b>{item2.title}</b> <br/>{item2.min_score_percent+"% Minimum Score"} <span className="edit_nor_delete"><button className="edit_select" onClick={e => selectQuizAndLoadQuestionsList(item2.id)}>&#9660;</button></span></p>

                                {selected_quiz_id !== "" && selected_quiz_id === item2.id && questions_loaded ? <div>

                                <p><b>Description :</b> {item2.description}</p>

                                <p><b>Minimum Score :</b> {item2.min_score_percent+""}</p>

                                <p><b>Randomize Questions' Orders :</b> {item2.is_random_questions ? "Yes" : "No"}</p>

                                <p><b>Randomize Choices' Orders :</b> {item2.is_random_choices ? "Yes" : "No"}</p>

                                  {questions_data.length > 0 ? <h5 className="new_course_label_txt question_list_header">Questions :</h5> : ""}

                                  {
                                    questions_data.map((item3,index3) => {
                                      return (
                                        <div className="course_edit_box_item" key={item3.id+"_"+index3}>
                                        <p className="question_edit_select"><img alt="a created question" src={created_check} width="30" height="30"/> <b>{item3.question}</b> <br/>{item3.type === "mcq" ? "<choices>" : "<text>"} <span className="edit_nor_delete"><button className="edit_select" onClick={e => selectQuestionAndLoadChoicesList(item3.id)}>&#9660;</button></span></p>

                                        {selected_question_id !== "" && selected_question_id === item3.id && choices_loaded ? <div>

                                          <p><b>Order :</b> {item3.order+""}</p>

                                          <p><b>Video Source :</b> {item3.video_source !== "" ? item3.video_source : "<None>"}</p>

                                          <p><b>Type :</b> {item3.type === "mcq" ? "Multiple Choice Question" : "Text Filling Question"}</p>

                                          {item3.type === "mcq" && choices_data.length > 0 ? <h5 className="new_course_label_txt choice_list_header">Choices :</h5> : ""}

                                          { item3.type === "mcq" ? (
                                            choices_data.map((item4,index4) => {
                                              return (
                                                  <div className="course_edit_box_item" key={item4.id+"_"+index4}>
                                                  <p className={"choice_edit_select "+(item4.is_correct ? "choice_edit_select_correct" : "choice_edit_select_wrong")}><img alt="a created choice" src={created_check} width="30" height="30"/> <b>{item4.choice}</b> <br/>{item4.is_correct ? "<correct answer>" : "<wrong answer>"} <span className="edit_nor_delete"><button className="edit_select" onClick={e => selectChoice(item4.id)}>&#9660;</button></span></p>
                                                    
                                                  {selected_choice_id !== "" && selected_choice_id === item4.id ? <div>

                                                    <p><b>Order :</b> {item4.order+""}</p>

                                                    <p><b>Video Source :</b> {item4.video_source !== "" ? item4.video_source : "<None>"}</p>

                                                    <p><b>Is it correct? :</b> {item4.is_correct ? "Correct answer" : "Wrong answer"}</p>

                                                  </div> : ""}

                                                  </div>
                                                )})
                                            ) : ""
                                          }

                                          </div> : ""}

                                        </div>)})
                                  }

                                  </div> : ""}

                                </div>)})
                        }

                        </div> : ""}

                      </div>)})
               }
              </div> : ""}

              {course_loaded ? <div>
            <div className="course_detail_button_container">
                    <button className="course_detail_button_items_left" onClick={backToCourseList}>Save Draft</button>
                    {course_data.status !== "published" ? <button className="course_detail_button_items_right" onClick={publishCourse}>Publish</button> : <button className="course_detail_button_items_right" onClick={unpublishCourse}>Unpublish</button>}
             </div>
             </div> : ""}

            <br/>

          </div>
        </div>
        
        
      </div>
      <HomeFooter/>
      </div>
      </main>
    );
  
}

export default ProfileAdminNewCoursePart3;
