import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Configuration from '../functions/Configuration';
import getCurrentUserType from '../functions/getCurrentUserType';
import getTokenData from '../functions/getTokenData';
//import Logout from '../functions/Logout';

function ProfileMenu(props) {
  const [username, setUsername] = useState("")
  const [user_type, setUserType] = useState(false)
  const [selected_menu, setMenuSelection] = useState(0)

  const selected_menu_init = props.selected_menu_init

  const navigate = useNavigate();

   /*const logOut = () => {
       if (Logout()) {
        navigate('/');
       }
   }*/

   const toPage = (page_url) => {
      navigate(page_url);
   }

   useEffect(
    () => {
      if (selected_menu_init) {
        setMenuSelection(selected_menu_init)
      } else {
        setMenuSelection(0)
      }
      
      setUserType(getCurrentUserType())
      setUsername(getTokenData("email"))
    },
    []
    )

    return (
      <div className="profile_menu_box">
          <b>บัญชีผู้ใช้ :</b> <br/>{username}
          <br/><br/>
          <b>{Configuration("proper_text_display")[user_type]}</b>
          <br/>
          {user_type !== "admin" ? 
          <div className="profile_menu">
            <button className={"profile_menu_item "+(selected_menu === 0 ? "profile_menu_item_selected" : "")} onClick={() => toPage('/profile_student')}>ข้อมูลของฉัน</button>
          	<button className={"profile_menu_item "+(selected_menu === 1 ? "profile_menu_item_selected" : "")} onClick={() => toPage('/profile_student_current_courses')}>คอร์สเรียนของฉัน</button>
          	<button className={"profile_menu_item "+(selected_menu === 2 ? "profile_menu_item_selected" : "")} onClick={() => toPage('/profile_student_completed_courses')}>คอร์สที่เรียนจบแล้ว</button>
            <button className={"profile_menu_item "+(selected_menu === 3 ? "profile_menu_item_selected" : "")} onClick={() => toPage('/profile_student_settings')}>ตั้งค่า</button>
          </div> :
          <div className="profile_menu">
            <button className={"profile_menu_item "+(selected_menu === 0 ? "profile_menu_item_selected" : "")} onClick={() => toPage('/profile_admin_course_list')}>Courses management</button>
            <button className={"profile_menu_item "+(selected_menu === 1 ? "profile_menu_item_selected" : "")} onClick={() => toPage('/profile_admin_user_list')}>User management</button>
            <button className={"profile_menu_item "+(selected_menu === 2 ? "profile_menu_item_selected" : "")} onClick={() => toPage('/profile_admin_group_list')}>Group management</button>
          </div>
          }

      </div>
    );
  
}

export default ProfileMenu;