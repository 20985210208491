import jwt_decode from "jwt-decode";

function getCurrentUserType() {
  let access_token_data = window.localStorage.getItem("access_token_data");

    if (access_token_data == null) { //one tab logged out, be sure to log out for all
        return null

    } else { //in case if token exists but can still be expired

        let decodedToken = jwt_decode(access_token_data);

        return decodedToken["user_type"]
    }
}

export default getCurrentUserType;