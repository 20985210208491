import React, { useEffect, useRef } from 'react';

function ConfirmDialog(props) {
  const parentCallback = props.parentCallback
  const parentCallbackCancel = props.parentCallbackCancel
  const confirm_title = props.confirm_title
  const confirm_msg = props.confirm_msg
  const dialog_data = props.dialog_data

  const dialogRef = useRef()

  const callBackYes = (dialog_data) => {
    document.body.style.overflow = "visible"
    parentCallback(dialog_data)
  }

  const callBackNo = () => {
    document.body.style.overflow = "visible"
    parentCallbackCancel()
  }

  useEffect(
    () => {
      //window.scrollTo(0, 0);
      let top  = window.pageYOffset || document.documentElement.scrollTop
      document.getElementById("confirm_box_object").style.top = (top+(window.screen.height/3))+"px"
      document.body.style.overflow = "hidden"
      document.getElementById("confirm_box_overlay_object").style.top = (top)+"px"
      document.getElementById("confirm_box_overlay_object").style.height = (window.screen.height)+"px"
    },
    []
  )
  useEffect(()=>{
    dialogRef.current?.focus()
  },[])

    return (
      <div>
      <div className={"confirm_box_overlay"} id={"confirm_box_overlay_object"}>

      </div>
      <div aria-modal='true' className={"confirm_box"} id={"confirm_box_object"}>
        <br/>
        <h2 dialogRef={dialogRef} className="confirm_txt" tabIndex="0">{confirm_title}</h2>
        {confirm_msg !== "" ? <span><label className="confirm_msg"> {confirm_msg} </label>
        <br/></span> : ""}
        <input className="confirm_yes_btn" type="button" value="ใช่" onClick={() => callBackYes(dialog_data)}/>
        <input className="confirm_no_btn" type="button" value="ไม่ใช่" onClick={callBackNo}/>
      </div>
      </div>
    );
}


export default ConfirmDialog