//use for check session
import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import recheckAccessTokenIsExpired from '../functions/recheckAccessTokenIsExpired';
import getCurrentUserType from '../functions/getCurrentUserType';

function Init() {

  const navigate = useNavigate();

  useEffect(
    () => {

      if (recheckAccessTokenIsExpired()) {
        navigate('/login');
      } else {
        if (getCurrentUserType() === "admin") {
          navigate('/profile_admin_course_list');
        } else {
            navigate('/home');
        }
      }
      
    },
    []
  )

    return (
      <div>

      </div>
    );

}

export default Init;