import React, { useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import axios from 'axios';

function PageNotFound() {
  const navigate = useNavigate();

  const backToLogin = () => {
    navigate('/login');
   } 

  useEffect(
    () => {
      document.body.style.background = "#503e9d"
      
    },
    []
  )

    return (
      <div>

        <div>

          <div className="boxes">

            <div className="msg_regis_box">  
              <h1 className="outsider_txt outsider_txt_regis_success">ขณะนี้ หน้านี้ยังไม่ได้จัดทำขึ้น ขออภัยในความไม่สะดวก</h1><br/><br/>

              <div className="outsider_btns">
                <input className="outsider_btn_base outsider_btn_regis_success" type="submit" value="กลับ" onClick={backToLogin}/>
              </div>

            </div>

          </div>

        </div>

      </div>
    );
  
}

export default PageNotFound;
