import React, { useEffect } from 'react';

function NewCourseNaviBox(props) {
  const parentCallback = props.parentCallback

  useEffect(
    () => {
      
    },
    []
  )

    return (
      <div className={"course_navi_small_box_admin"}>
            <button className="course_navi_btn next_btn" onClick={parentCallback}>{">"}</button><span className="navi_txt" onClick={() => parentCallback(true)}>Next</span>
        </div>
    );
  
}

export default NewCourseNaviBox;