import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import getCurrentUserType from '../functions/getCurrentUserType';
import logo from '../res/logo.svg';
import footer_link_www from '../res/footer_link_www.svg';
import footer_link_fb from '../res/footer_link_fb.svg';
import footer_link_ig from '../res/footer_link_ig.svg';
import footer_link_yt from '../res/footer_link_yt.svg';
import footer_link_mail from '../res/footer_link_mail.svg';

function HomeFooter() {
  const navigate = useNavigate();

  const [is_vulcan_hero, setIsVulcanHero] = useState(false)
  const [is_admin, setIsAdmin] = useState(false)

  const backToHome = () => {
       navigate('/home');
       window.location.reload();
   }

  const backToHomeWithFilter = (filter) => {
       navigate('/home#filter='+filter);
       window.location.reload();
   }

  const toPage = (page_url) => {
      navigate(page_url);
   }

  const externalLink = (link) => {
       window.open(link, '_blank').focus();
   }

  useEffect(
    () => {
      if (getCurrentUserType() === "vulcan_hero") {
        setIsVulcanHero(true)
      } else if (getCurrentUserType() === "admin") {
        setIsAdmin(true)
      }
    },
    []
  )

    return (
      <footer className="container home_item footer_container boxes">
        <div className="col-3 footer_box footer_border_right">
          <button className="footer_icon_link_title" onClick={backToHome}><img src={logo} className="footer_icon_link_title_img" alt="Vulcan Coalition (กลับหน้าแรก)" width="200" height="76"/> </button>
          <div className="boxes">
            
            <div className="hidden_label">เว็ยไซต์หลัก</div>
            <div className="footer_div_icon"><button className="footer_icon_link" onClick={() => externalLink("https://www.vulcancoalition.com/")}><img className="footer_icon_img" src={footer_link_www} alt="main webpage" width="30" height="30"/> </button></div>

            <div className="hidden_label">Facebook</div>
            <div className="footer_div_icon"><button className="footer_icon_link" onClick={() => externalLink("https://www.facebook.com/vulcancoalition/")}><img className="footer_icon_img" src={footer_link_fb} alt="facebook page" width="30" height="30"/> </button></div>

            <div className="hidden_label">Instagram</div>
            <div className="footer_div_icon"><button className="footer_icon_link" onClick={() => externalLink("https://www.instagram.com/vulcancoalition/")}><img className="footer_icon_img" src={footer_link_ig} alt="instagram webpage" width="30" height="30"/> </button></div>

            <div className="hidden_label">Youtube</div>
            <div className="footer_div_icon"><button className="footer_icon_link" onClick={() => externalLink("https://youtube.com/channel/UCkwn5JLKbm12bmCHcv6706Q")}><img className="footer_icon_img" src={footer_link_yt} alt="youtube channel" width="30" height="30"/> </button></div>

            <div className="hidden_label">อีเมลล์</div>
            <div className="footer_div_icon"><button className="footer_icon_link" onClick={() => externalLink("mailto: vulcan@lab.ai")}><img className="footer_icon_img" src={footer_link_mail} alt="send email" width="30" height="30"/> </button></div>

          </div>
          </div>

          <div className="col-3  footer_box">
          <div className="footer_title">หลักสูตร</div>
          <button className="footer_item_link" onClick={() => backToHome()}>หลักสูตรทั้งหมด</button><br/>
          <button className="footer_item_link" onClick={() => backToHomeWithFilter("upskill")}>หลักสูตร Upskill</button><br/>
          <button className="footer_item_link" onClick={() => backToHomeWithFilter("reskill")}>หลักสูตร Reskill</button><br/>
          <button className="footer_item_link" onClick={() => backToHomeWithFilter("working")}>คู่มือการทำงาน</button><br/>
          </div>

          {is_vulcan_hero ? <div className="col-3  footer_box">
          <div className="footer_title">แพลตฟอร์มที่เกี่ยวข้อง</div>
          <button className="footer_item_link" onClick={() => externalLink("https://linkage.lab.ai/")}>Linkage</button><br/>
          <button className="footer_item_link" onClick={() => externalLink("https://unity.lab.ai/")}>Unity</button><br/>
          <button className="footer_item_link" onClick={() => externalLink("https://www.vulcancoalition.com/")}>Collab</button><br/>
          <button className="footer_item_link" onClick={() => externalLink("https://collab.lab.ai/")}>Value</button><br/>
          </div> : ""}

          {is_admin ? <div className="col-3  footer_box">
          <div className="footer_title">ทางลัดการเข้าถึง</div>
          <button className="footer_item_link" onClick={() => toPage('/profile_admin_course_list')}>จัดการคอร์สเรียน</button><br/>
          <button className="footer_item_link" onClick={() => toPage('/profile_admin_user_list')}>จัดการผู้ใช้งาน</button><br/>
          </div> 
          : <div className="col-3  footer_box">
          <div className="footer_title">ทางลัดการเข้าถึง</div>
          <button className="footer_item_link" onClick={() => toPage('/profile_student')}>บัญชีของฉัน</button><br/>
          <button className="footer_item_link" onClick={() => toPage('/profile_student_current_courses')}>คอร์สเรียนของฉัน</button><br/>
          <button className="footer_item_link" onClick={() => toPage('/profile_student_completed_courses')}>คอร์สเรียนของฉันที่จบแล้ว</button><br/>
          <button className="footer_item_link" onClick={() => toPage('/profile_student_settings')}>ตั้งค่า</button><br/>
          </div> }
        </footer>
    );
  
}

export default HomeFooter;