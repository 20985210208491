import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import Configuration from '../functions/Configuration';
import recheckAccessTokenIsExpired from '../functions/recheckAccessTokenIsExpired';
import getCurrentUserType from '../functions/getCurrentUserType';
import GetUrlHashValue from '../functions/GetUrlHashValue';
import AlertDialog from '../components/AlertDialog';
import ConfirmDialog from '../components/ConfirmDialog';
import ProfileMenu from '../components/ProfileMenu';
import NewCourseHeader from '../components/NewCourseHeader';
import NewCourseProgress from '../components/NewCourseProgress';
import NewCourseNaviBox from '../components/NewCourseNaviBox';
import created_check from '../res/created_check.svg';
import edit_btn_icon from '../res/edit_btn_icon.svg';
import delete_btn_icon from '../res/delete_btn_icon.svg';
import link_url_icon from '../res/link_url_icon.svg';
import NavBar from '../components/NavBar';
import HomeFooter from '../components/HomeFooter';

function ProfileAdminNewCoursePart3() {
  const [course_id, setCourseID] = useState("")
  const [selected_lesson_id, setSelectedLessonID] = useState("")
  const [selected_quiz_id, setSelectedQuizID] = useState("")
  const [selected_question_id, setSelectedQuestionID] = useState("")
  const [selected_choice_id, setSelectedChoiceID] = useState("")

  const [lessons, setLessons] = useState([]);
  const [lessons_loaded, setLessonsLoaded] = useState(false);

  const [is_add_quiz_mode, setAddQuizMode] = useState(false)
  const [quizes_data, setQuizesData] = useState([]);
  const [quizes_loaded, setQuizesLoaded] = useState(false)

  const [is_add_question_mode, setAddQuestionMode] = useState(false)
  const [questions_data, setQuestionsData] = useState([]);
  const [questions_loaded, setQuestionsLoaded] = useState(false)

  const [is_add_choice_mode, setAddChoiceMode] = useState(false)
  const [choices_data, setChoicesData] = useState([]);
  const [choices_loaded, setChoicesLoaded] = useState(false)

  /*const [edit_quiz_mode_id, setEditQuizModeID] = useState("");
  const [edit_question_mode_id, setEditQuestionModeID] = useState("");
  const [edit_choice_mode_id, setEditChoiceModeID] = useState("");*/

  const [dialog_confirm_on, setDialogConfirmOn] = useState(false)
  const [dialog_data, setDialogData] = useState(false)

  const [dialog_alert_on, setDialogAlertOn] = useState(false)
  const [error_msg, setErrorMsg] = useState("")

  const navigate = useNavigate();

  const init_input_state_quiz = {
    title: "",
    description: "",
    is_random_questions: true,
    is_random_choices: true,
    min_score_percent: 50,
  }

  const init_input_state_question = {
    question: "",
    type: "mcq",
    order: 1,
    video_source: "",
  }

  const init_input_state_choice = {
    choice: "",
    order: 1,
    video_source: "",
    is_correct: false,
  }

  const [new_quiz_input, setNewQuizInput] = useState(init_input_state_quiz);
  const [new_question_input, setNewQuestionInput] = useState(init_input_state_question);
  const [new_choice_input, setNewChoiceInput] = useState(init_input_state_choice);

  const [edit_quiz_input, setEditQuizInput] = useState(init_input_state_quiz);
  const [edit_question_input, setEditQuestionInput] = useState(init_input_state_question);
  const [edit_choice_input, setEditChoiceInput] = useState(init_input_state_choice);

  const nextPage = () => {
    navigate('/profile_admin_new_course_4#id='+course_id);
  }

  const selectLessonAndLoadQuizesList = (lesson_id) => {
    setSelectedLessonID(lesson_id)
    setSelectedQuizID("")
    setSelectedQuestionID("")
    setSelectedChoiceID("")
    setAddQuizMode(false)
    setQuizesLoaded(false)
    setQuizesData([])

    let access_token_data = window.localStorage.getItem("access_token_data");
    let access_token_data_type = window.localStorage.getItem("access_token_data_type");

    const url = Configuration("video_host")+"/api/v1/course/"+course_id+"/lesson/"+lesson_id+"/quiz";

      const fetchData = async () => {
        try {
          const {data: response} = await axios({
            method: "get",
            url: url,
            headers: { "Authorization": access_token_data_type + " " + access_token_data },
          });
          let the_data = response.data
          //console.log(the_data)
          if (!the_data) {
            setQuizesData([])
          } else {
            setQuizesData(the_data)
          }

          setQuizesLoaded(true)
          
        } catch (error) {
          if (error.response.status < 500) {
            setErrorMsg("Select Load Error")
          } else {
            setErrorMsg("Internal Server Error")
          }
          setDialogAlertOn(true)
        }
      };

      fetchData()
  }

  const cancelSelectQuiz = () => {
    setSelectedQuizID("")
    setSelectedQuestionID("")
    setSelectedChoiceID("")
  }

  const cancelSelectQuestion = () => {
    setSelectedQuestionID("")
    setSelectedChoiceID("")
  }

  const cancelSelectChoice = () => {
    setSelectedChoiceID("")
  }

  const selectQuizAndLoadQuestionsList = (quiz_id) => {
    setSelectedQuizID("")
    setSelectedQuestionID("")
    setSelectedChoiceID("")
    setAddQuestionMode(false)
    setQuestionsLoaded(false)
    setQuestionsData([])

    let access_token_data = window.localStorage.getItem("access_token_data");
    let access_token_data_type = window.localStorage.getItem("access_token_data_type");

    const url = Configuration("video_host")+"/api/v1/course/"+course_id+"/lesson/"+selected_lesson_id+"/quiz/"+quiz_id;

      const fetchData = async () => {
        try {
          const {data: response} = await axios({
            method: "get",
            url: url,
            headers: { "Authorization": access_token_data_type + " " + access_token_data },
          });
          let the_data = response.data
          setEditQuizInput(the_data)

          setSelectedQuizID(quiz_id) //here load

          fetchData2()
          
        } catch (error) {
          if (error.response.status < 500) {
            setErrorMsg("Select Load Error")
          } else {
            setErrorMsg("Internal Server Error")
          }
          setDialogAlertOn(true)
        }
      };

    const url2 = Configuration("video_host")+"/api/v1/course/"+course_id+"/lesson/"+selected_lesson_id+"/quiz/"+quiz_id+"/question";

      const fetchData2 = async () => {
        try {
          const {data: response} = await axios({
            method: "get",
            url: url2,
            headers: { "Authorization": access_token_data_type + " " + access_token_data },
          });
          let the_data = response.data
          //console.log(the_data)
          if (!the_data) {
            setQuestionsData([])
          } else {
            setQuestionsData(the_data)
          }

          setQuestionsLoaded(true)
          
        } catch (error) {
          if (error.response.status < 500) {
            setErrorMsg("Select Load Error")
          } else {
            setErrorMsg("Internal Server Error")
          }
          setDialogAlertOn(true)
        }
      };

      fetchData()
  }

  const selectQuestionAndLoadChoicesList = (question_id) => {
    setSelectedQuestionID("")
    setSelectedChoiceID("")
    setAddChoiceMode(false)
    setChoicesLoaded(false)
    setChoicesLoaded([])

    let access_token_data = window.localStorage.getItem("access_token_data");
    let access_token_data_type = window.localStorage.getItem("access_token_data_type");

    const url = Configuration("video_host")+"/api/v1/course/"+course_id+"/lesson/"+selected_lesson_id+"/quiz/"+selected_quiz_id+"/question/"+question_id;

      const fetchData = async () => {
        try {
          const {data: response} = await axios({
            method: "get",
            url: url,
            headers: { "Authorization": access_token_data_type + " " + access_token_data },
          });
          let the_data = response.data
          setEditQuestionInput(the_data)

          setSelectedQuestionID(question_id) //here load

          fetchData2()
          
        } catch (error) {
          if (error.response.status < 500) {
            setErrorMsg("Select Load Error")
          } else {
            setErrorMsg("Internal Server Error")
          }
          setDialogAlertOn(true)
        }
      };

    const url2 = Configuration("video_host")+"/api/v1/course/"+course_id+"/lesson/"+selected_lesson_id+"/quiz/"+selected_quiz_id+"/question/"+question_id+"/choice";

      const fetchData2 = async () => {
        try {
          const {data: response} = await axios({
            method: "get",
            url: url2,
            headers: { "Authorization": access_token_data_type + " " + access_token_data },
          });
          let the_data = response.data
          //console.log(the_data)
          if (!the_data) {
            setChoicesData([])
          } else {
            setChoicesData(the_data)
          }

          setChoicesLoaded(true)
          
        } catch (error) {
          if (error.response.status < 500) {
            setErrorMsg("Select Load Error")
          } else {
            setErrorMsg("Internal Server Error")
          }
          setDialogAlertOn(true)
        }
      };

      fetchData()
  }

  const selectChoice = (choice_id) => {
    setSelectedChoiceID("")
    setAddChoiceMode(false)

    let access_token_data = window.localStorage.getItem("access_token_data");
    let access_token_data_type = window.localStorage.getItem("access_token_data_type");

    const url = Configuration("video_host")+"/api/v1/course/"+course_id+"/lesson/"+selected_lesson_id+"/quiz/"+selected_quiz_id+"/question/"+selected_question_id+"/choice/"+choice_id;

      const fetchData = async () => {
        try {
          const {data: response} = await axios({
            method: "get",
            url: url,
            headers: { "Authorization": access_token_data_type + " " + access_token_data },
          });
          let the_data = response.data
          setEditChoiceInput(the_data)

          setSelectedChoiceID(choice_id) //here load

          //console.log(choice_id)
          
        } catch (error) {
          if (error.response.status < 500) {
            setErrorMsg("Select Load Error")
          } else {
            setErrorMsg("Internal Server Error")
          }
          setDialogAlertOn(true)
        }
      };

      fetchData()
  }

  /*
  const deselectLesson = () => {
    setSelectedLessonID("")
    setSelectedQuizID("")
    setSelectedQuestionID("")
    setSelectedChoiceID("")
    setAddQuizMode(false)
    setQuizesData([])
    setQuizesLoaded(false)
    setAddQuestionMode(false)
    setQuestionsData([])
    setQuestionsLoaded(false)
    setAddChoiceMode(false)
    setChoicesData([])
    setChoicesLoaded(false)
  }*/

  const enableAddQuiz = () => {
    let load_caches = function() {

        for (let name in new_quiz_input) {

            let cache = window.localStorage.getItem("newcourse_p3_quiz_"+name);
            //console.log(cache, name)
            if (cache) {
              if (name === "is_random_questions" || name === "is_random_choices") {
                setBoolValueQuiz(name, cache)
              } else if (name === "min_score_percent") {
                setNumValueQuiz(name, cache)
              } else {
                setValueQuiz(name, cache)
              }
            }
          
        }

      }
    load_caches()
    setAddQuizMode(true)
  }

  const disableAddQuiz = () => {
    setAddQuizMode(false)
    setNewQuizInput(init_input_state_quiz)
  }

  const enableAddQuestion = () => {
    setNewQuestionInput(prevState => ({
                ...prevState,
                order: questions_data.length+1
    }));
    let load_caches = function() {

        for (let name in new_question_input) {

            let cache = window.localStorage.getItem("newcourse_p3_question_"+name);
            //console.log(cache, name)
            if (cache) {
              if (name === "order") {
                setNumValueQuestion(name, cache)
              } else {
                setValueQuestion(name, cache)
              }
            }
          
        }

      }
    load_caches()
    setAddQuestionMode(true)
  }

  const disableAddQuestion = () => {
    setAddQuestionMode(false)
    setNewQuestionInput(init_input_state_question)
  }

  const enableAddChoice = () => {
    setNewChoiceInput(prevState => ({
                ...prevState,
                order: choices_data.length+1
    }));
    let load_caches = function() {

        for (let name in new_choice_input) {

            let cache = window.localStorage.getItem("newcourse_p3_choice_"+name);
            //console.log(cache, name)
            if (cache) {
              if (name === "is_correct") {
                setBoolValueChoice(name, cache)
              } else if (name === "order") {
                setNumValueChoice(name, cache)
              } else {
                setValueChoice(name, cache)
              }
            }
          
        }

      }
    load_caches()
    setAddChoiceMode(true)
  }

  const disableAddChoice = () => {
    setAddChoiceMode(false)
    setNewChoiceInput(init_input_state_choice)
  }

  const addQuiz = () => {
    let access_token_data = window.localStorage.getItem("access_token_data");
    let access_token_data_type = window.localStorage.getItem("access_token_data_type");

    const url = Configuration("video_host")+"/api/v1/course/"+course_id+"/lesson/"+selected_lesson_id+"/quiz";
    let body = new_quiz_input

    //console.log(body)

      const postData = async () => {
        try {
          await axios({
            method: "post",
            url: url,
            data: body,
            headers: { "Authorization": access_token_data_type + " " + access_token_data },
          });
          //console.log(response);
          setNewQuizInput(init_input_state_quiz)
          let remove_cache = function() {
            for (let name in new_quiz_input) {
                window.localStorage.removeItem("newcourse_p3_quiz_"+name);
            }
          }
          remove_cache()

          selectLessonAndLoadQuizesList(selected_lesson_id)
          
        } catch (error) {
          if (error.response.status < 500) {
            setErrorMsg("Add Error")
          } else {
            setErrorMsg("Internal Server Error")
          }
          setDialogAlertOn(true)
        }
      };

      postData()

  }

  const editQuiz = (quiz_id) => {
    let access_token_data = window.localStorage.getItem("access_token_data");
    let access_token_data_type = window.localStorage.getItem("access_token_data_type");

    const url = Configuration("video_host")+"/api/v1/course/"+course_id+"/lesson/"+selected_lesson_id+"/quiz/"+quiz_id;
    let body = edit_quiz_input

    //console.log(body)

      const patchData = async () => {
        try {
          await axios({
            method: "patch",
            url: url,
            data: body,
            headers: { "Authorization": access_token_data_type + " " + access_token_data },
          });
          //console.log(response);

          selectLessonAndLoadQuizesList(selected_lesson_id)
          
        } catch (error) {
          if (error.response.status < 500) {
            setErrorMsg("Edit Error")
          } else {
            setErrorMsg("Internal Server Error")
          }
          setDialogAlertOn(true)
        }
      };

      patchData()

  }

  const deleteQuiz = (quiz_id) => {
    let access_token_data = window.localStorage.getItem("access_token_data");
    let access_token_data_type = window.localStorage.getItem("access_token_data_type");

    const url = Configuration("video_host")+"/api/v1/course/"+course_id+"/lesson/"+selected_lesson_id+"/quiz/"+quiz_id;

      const fetchData = async () => {
        try {
          await axios({
            method: "delete",
            url: url,
            headers: { "Authorization": access_token_data_type + " " + access_token_data },
          });
          //console.log(response)

          selectLessonAndLoadQuizesList(selected_lesson_id)
          setDialogConfirmOn(false)
          
        } catch (error) {
          if (error.response.status < 500) {
            setErrorMsg("Delete Error")
          } else {
            setErrorMsg("Internal Server Error")
          }
          setDialogAlertOn(true)
        }
      };

      fetchData()
   }

  const addQuestion = () => {
    let access_token_data = window.localStorage.getItem("access_token_data");
    let access_token_data_type = window.localStorage.getItem("access_token_data_type");

    const url = Configuration("video_host")+"/api/v1/course/"+course_id+"/lesson/"+selected_lesson_id+"/quiz/"+selected_quiz_id+"/question";
    let body = new_question_input

    //console.log(url)

    //console.log(body)

      const postData = async () => {
        try {
          await axios({
            method: "post",
            url: url,
            data: body,
            headers: { "Authorization": access_token_data_type + " " + access_token_data },
          });
          //console.log(response);
          setNewQuestionInput(init_input_state_question)
          let remove_cache = function() {
            for (let name in new_question_input) {
                window.localStorage.removeItem("newcourse_p3_question_"+name);
            }
          }
          remove_cache()

          selectQuizAndLoadQuestionsList(selected_quiz_id)
          
        } catch (error) {
          if (error.response.status < 500) {
            setErrorMsg("Add Error")
          } else {
            setErrorMsg("Internal Server Error")
          }
          setDialogAlertOn(true)
        }
      };

      postData()

   } 

  const editQuestion = (question_id) => {
    let access_token_data = window.localStorage.getItem("access_token_data");
    let access_token_data_type = window.localStorage.getItem("access_token_data_type");

    const url = Configuration("video_host")+"/api/v1/course/"+course_id+"/lesson/"+selected_lesson_id+"/quiz/"+selected_quiz_id+"/question/"+question_id;
    let body = edit_question_input

    //console.log(body)

      const patchData = async () => {
        try {
          await axios({
            method: "patch",
            url: url,
            data: body,
            headers: { "Authorization": access_token_data_type + " " + access_token_data },
          });
          //console.log(response);

          selectQuizAndLoadQuestionsList(selected_quiz_id)
          
        } catch (error) {
          if (error.response.status < 500) {
            setErrorMsg("Edit Error")
          } else {
            setErrorMsg("Internal Server Error")
          }
          setDialogAlertOn(true)
        }
      };

      patchData()

  }

  const deleteQuestion = (question_id) => {
    let access_token_data = window.localStorage.getItem("access_token_data");
    let access_token_data_type = window.localStorage.getItem("access_token_data_type");

    const url = Configuration("video_host")+"/api/v1/course/"+course_id+"/lesson/"+selected_lesson_id+"/quiz/"+selected_quiz_id+"/question/"+question_id;

      const fetchData = async () => {
        try {
          await axios({
            method: "delete",
            url: url,
            headers: { "Authorization": access_token_data_type + " " + access_token_data },
          });
          //console.log(response)

          selectQuizAndLoadQuestionsList(selected_quiz_id)
          setDialogConfirmOn(false)
          
        } catch (error) {
          if (error.response.status < 500) {
            setErrorMsg("Delete Error")
          } else {
            setErrorMsg("Internal Server Error")
          }
          setDialogAlertOn(true)
        }
      };

      fetchData()
   }

  const addChoice = () => {
    let access_token_data = window.localStorage.getItem("access_token_data");
    let access_token_data_type = window.localStorage.getItem("access_token_data_type");

    const url = Configuration("video_host")+"/api/v1/course/"+course_id+"/lesson/"+selected_lesson_id+"/quiz/"+selected_quiz_id+"/question/"+selected_question_id+"/choice";
    let body = new_choice_input

    //console.log(url)

    //console.log(body)

      const postData = async () => {
        try {
          await axios({
            method: "post",
            url: url,
            data: body,
            headers: { "Authorization": access_token_data_type + " " + access_token_data },
          });
          //console.log(response);
          setNewChoiceInput(init_input_state_choice)
          let remove_cache = function() {
            for (let name in new_choice_input) {
                window.localStorage.removeItem("newcourse_p3_choice_"+name);
            }
          }
          remove_cache()

          selectQuestionAndLoadChoicesList(selected_question_id)
          
        } catch (error) {
          if (error.response.status < 500) {
            setErrorMsg("Add Error")
          } else {
            setErrorMsg("Internal Server Error")
          }
          setDialogAlertOn(true)
        }
      };

      if (body.is_correct) {
        uncorrectPreviousCorrectChoice(postData)
      } else {
        postData()
      }

   } 

  const editChoice = (choice_id) => {
    let access_token_data = window.localStorage.getItem("access_token_data");
    let access_token_data_type = window.localStorage.getItem("access_token_data_type");

    const url = Configuration("video_host")+"/api/v1/course/"+course_id+"/lesson/"+selected_lesson_id+"/quiz/"+selected_quiz_id+"/question/"+selected_question_id+"/choice/"+choice_id;
    let body = edit_choice_input

    //console.log(body)

      const patchData = async () => {
        try {
          await axios({
            method: "patch",
            url: url,
            data: body,
            headers: { "Authorization": access_token_data_type + " " + access_token_data },
          });
          //console.log(response);

          selectQuestionAndLoadChoicesList(selected_question_id)
          
        } catch (error) {
          if (error.response.status < 500) {
            setErrorMsg("Edit Error")
          } else {
            setErrorMsg("Internal Server Error")
          }
          setDialogAlertOn(true)
        }
      };

      if (body.is_correct) {
        uncorrectPreviousCorrectChoice(patchData)
      } else {
        patchData()
      }

  }

  const deleteChoice = (choice_id) => {
    let access_token_data = window.localStorage.getItem("access_token_data");
    let access_token_data_type = window.localStorage.getItem("access_token_data_type");

    const url = Configuration("video_host")+"/api/v1/course/"+course_id+"/lesson/"+selected_lesson_id+"/quiz/"+selected_quiz_id+"/question/"+selected_question_id+"/choice/"+choice_id;

      const fetchData = async () => {
        try {
          await axios({
            method: "delete",
            url: url,
            headers: { "Authorization": access_token_data_type + " " + access_token_data },
          });
          //console.log(response)

          selectQuestionAndLoadChoicesList(selected_question_id)
          setDialogConfirmOn(false)
          
        } catch (error) {
          if (error.response.status < 500) {
            setErrorMsg("Delete Error")
          } else {
            setErrorMsg("Internal Server Error")
          }
          setDialogAlertOn(true)
        }
      };

      fetchData()
   }

  const uncorrectPreviousCorrectChoice = (nextFunction) => {
    let access_token_data = window.localStorage.getItem("access_token_data");
    let access_token_data_type = window.localStorage.getItem("access_token_data_type");

    let choice_id = ""
    let has_previous = false
    for (let i in choices_data) {
      let is_correct = choices_data[i].is_correct
      if (is_correct) {
        choice_id = choices_data[i].id
        has_previous = true
        break
      }
    }

    if (!has_previous) {
      nextFunction()
      return
    }

    const url = Configuration("video_host")+"/api/v1/course/"+course_id+"/lesson/"+selected_lesson_id+"/quiz/"+selected_quiz_id+"/question/"+selected_question_id+"/choice/"+choice_id;
    let body = {
      is_correct: false,
    }

    //console.log(body)

      const patchData = async () => {
        try {
          await axios({
            method: "patch",
            url: url,
            data: body,
            headers: { "Authorization": access_token_data_type + " " + access_token_data },
          });
          //console.log(response);

          nextFunction()
          
        } catch (error) {
          if (error.response.status < 500) {
            setErrorMsg("Edit Error")
          } else {
            setErrorMsg("Internal Server Error")
          }
          setDialogAlertOn(true)
        }
      };

      patchData()
  }

  const setValueQuiz = (name, value) => {
    setNewQuizInput(prevState => ({
                ...prevState,
                [name]: value
    }));
    window.localStorage.setItem("newcourse_p3_quiz_"+name, value);
   } 

  const setValueQuizEdit = (name, value) => {
    setEditQuizInput(prevState => ({
                ...prevState,
                [name]: value
    }));
   } 

/*  const setValueSimpleQuiz = (name, value) => {
    setNewQuizInput(prevState => ({
                ...prevState,
                [name]: value
    }));
   } 

  const setValueBigBoxQuiz = useCallback((name, value) => {
    setNewQuizInput(prevState => ({
                ...prevState,
                [name]: value
    }));
    window.localStorage.setItem("newcourse_p3_"+name, value);
  }, []);*/

  const setBoolValueQuiz = (name, value) => {
    let into_bool = (value === "true" || value === true)
    setNewQuizInput(prevState => ({
                ...prevState,
                [name]: into_bool
    }));
    window.localStorage.setItem("newcourse_p3_quiz_"+name, into_bool);
   } 

  const setBoolValueQuizEdit = (name, value) => {
    let into_bool = (value === "true" || value === true)
    setEditQuizInput(prevState => ({
                ...prevState,
                [name]: into_bool
    }));
   } 

   const setNumValueQuiz = (name, value) => {
    setNewQuizInput(prevState => ({
                ...prevState,
                [name]: parseInt(value)
    }));
    window.localStorage.setItem("newcourse_p3_quiz_"+name, value);
   } 

   const setNumValueQuizEdit = (name, value) => {
    setEditQuizInput(prevState => ({
                ...prevState,
                [name]: parseInt(value)
    }));
   } 

   //....

  const setValueQuestion = (name, value) => {
    setNewQuestionInput(prevState => ({
                ...prevState,
                [name]: value
    }));
    window.localStorage.setItem("newcourse_p3_question_"+name, value);
   } 

  const setValueQuestionEdit = (name, value) => {
    setEditQuestionInput(prevState => ({
                ...prevState,
                [name]: value
    }));
   } 

/*  const setValueSimpleQuestion = (name, value) => {
    setNewQuestionInput(prevState => ({
                ...prevState,
                [name]: value
    }));
   } 

  const setValueBigBoxQuestion = useCallback((name, value) => {
    setNewQuestionInput(prevState => ({
                ...prevState,
                [name]: value
    }));
    window.localStorage.setItem("newcourse_p3_"+name, value);
  }, []);

  const setBoolValueQuestion = (name, value) => {
    let into_bool = (value === "true" || value === true)
    setNewQuestionInput(prevState => ({
                ...prevState,
                [name]: into_bool
    }));
    window.localStorage.setItem("newcourse_p3_"+name, into_bool);
   } 

   const setBoolValueQuestionEdit = (name, value) => {
    let into_bool = (value === "true" || value === true)
    setEditQuestionInput(prevState => ({
                ...prevState,
                [name]: into_bool
    }));
   } */

   const setNumValueQuestion = (name, value) => {
    setNewQuestionInput(prevState => ({
                ...prevState,
                [name]: parseInt(value)
    }));
    window.localStorage.setItem("newcourse_p3_question_"+name, value);
   } 

   const setNumValueQuestionEdit = (name, value) => {
    setEditQuestionInput(prevState => ({
                ...prevState,
                [name]: parseInt(value)
    }));
   } 

   //....

   const setValueChoice = (name, value) => {
    setNewChoiceInput(prevState => ({
                ...prevState,
                [name]: value
    }));
    window.localStorage.setItem("newcourse_p3_choice_"+name, value);
   } 

   const setValueChoiceEdit = (name, value) => {
    setEditChoiceInput(prevState => ({
                ...prevState,
                [name]: value
    }));
   } 

/*  const setValueSimpleChoice = (name, value) => {
    setNewChoiceInput(prevState => ({
                ...prevState,
                [name]: value
    }));
   } 

  const setValueBigBoxChoice = useCallback((name, value) => {
    setNewChoiceInput(prevState => ({
                ...prevState,
                [name]: value
    }));
    window.localStorage.setItem("newcourse_p3_"+name, value);
  }, []);*/

  const setBoolValueChoice = (name, value) => {
    let into_bool = (value === "true" || value === true)
    setNewChoiceInput(prevState => ({
                ...prevState,
                [name]: into_bool
    }));
    window.localStorage.setItem("newcourse_p3_choice_"+name, into_bool);
   } 

   const setBoolValueChoiceEdit = (name, value) => {
    let into_bool = (value === "true" || value === true)
    setEditChoiceInput(prevState => ({
                ...prevState,
                [name]: into_bool
    }));
   } 

   const setNumValueChoice = (name, value) => {
    setNewChoiceInput(prevState => ({
                ...prevState,
                [name]: parseInt(value)
    }));
    window.localStorage.setItem("newcourse_p3_choice_"+name, value);
   } 

   const setNumValueChoiceEdit = (name, value) => {
    setEditChoiceInput(prevState => ({
                ...prevState,
                [name]: parseInt(value)
    }));
   } 

   //sss

   const reloadLessonsList = (course_id) => {
    let access_token_data = window.localStorage.getItem("access_token_data");
    let access_token_data_type = window.localStorage.getItem("access_token_data_type");

    const url = Configuration("video_host")+"/api/v1/course/"+course_id+"/lesson";

      const fetchData = async () => {
        try {
          const {data: response} = await axios({
            method: "get",
            url: url,
            headers: { "Authorization": access_token_data_type + " " + access_token_data },
          });
          let the_data = response.data.lessons
          if (!the_data) {
            setLessons([])
          } else {
            setLessons(the_data)
          }
          setLessonsLoaded(true)
        } catch (error) {
          if (error.response.status < 500) {
            setErrorMsg("Reload Error")
          } else {
            setErrorMsg("Internal Server Error")
          }
          setDialogAlertOn(true)
        }
      };

      fetchData()
   }

   const backToCourseList = () => {
      navigate('/profile_admin_course_list');
   } 

   const activateDeleteDialog = (to_delete_id, delete_data_type) => {
    setDialogConfirmOn(true)
    setDialogData({
      "id": to_delete_id,
      "type": delete_data_type,
      })
    }

    const cancelDeleteDialog = () => {
      setDialogConfirmOn(false)
    }

    const closeAlertDialog = () => {
      setDialogAlertOn(false)
    }

    const deleteSomething = (deletion_data) => {
      if (deletion_data.type === "quiz") {
        deleteQuiz(deletion_data.id)
      } else if (deletion_data.type === "question") {
        deleteQuestion(deletion_data.id)
      } else if (deletion_data.type === "choice") {
        deleteChoice(deletion_data.id)
      }
    }

   useEffect(
    () => {

      let get_course_id = GetUrlHashValue("id")
      setCourseID(get_course_id)

      document.body.style.background = "#503e9d"
      document.body.style.padding = "20px 2% 20px 2%";
      document.getElementById("root").style.background = "#503e9d"
      document.getElementById("root").style.borderRadius  = "20px"

      if (recheckAccessTokenIsExpired() || getCurrentUserType() !== "admin") {
        navigate('/');
      }

      reloadLessonsList(get_course_id)

    },
    []
  )

    return (
      <main>
        <NavBar isCourse={true}/>
        <div className='container'>
      <div className={"course_body_profile"}>

        {dialog_alert_on ? <AlertDialog alert_title={error_msg} alert_msg={""} parentCallback={closeAlertDialog}/> : ""}

        {dialog_confirm_on ? <ConfirmDialog confirm_title={"คุณต้องการจะลบใช่หรือไม่?"} confirm_msg={""} dialog_data={dialog_data} parentCallback={deleteSomething} parentCallbackCancel={cancelDeleteDialog}/> : ""}

        <div className="boxes">
          <ProfileMenu isAdmin={true}/>

          <div className="admin_profile_detail_box">
            
            <NewCourseHeader parentCallback={backToCourseList}/>

            <NewCourseProgress progress={3}/>

            <div className="boxes">
            <h4 className="new_course_title">Section 3 : Practice Test</h4> <NewCourseNaviBox parentCallback={nextPage}/>
            </div>

            {lessons_loaded ? <div>
              {lessons.length > 0 ? <h5 className="lesson_list_header">Lessons list :</h5> : <h5 className="lesson_list_header">There are currently no lessons,<br/> you can add it from previous page.</h5>}
              { 
                 lessons.map((item,index) => {
                  //console.log(item)
                    return (
                        <div className="course_edit_box_item" key={item.id+"_"+index}>
                          <p className="lesson_edit_select"><b>{item.title}</b> <span className="edit_nor_delete"><button className="edit_select" onClick={e => selectLessonAndLoadQuizesList(item.id)}>&#9660;</button></span></p>
                          {selected_lesson_id !== "" && selected_lesson_id === item.id ? <div>

                          <h5 className="new_course_label_txt quiz_list_header">Quizes :</h5>

                          {quizes_loaded ? <div>
                            { 
                               quizes_data.map((item2,index2) => {
                                //console.log(item)
                                  return (
                                      <div className="course_edit_box_item" key={item2.id+"_"+index2}>
                                        <p className="quiz_edit_select"><img alt="a created quiz" src={created_check} width="30" height="30"/> <b>{item2.title}</b> <br/>{item2.min_score_percent+"% Minimum Score"} <span className="edit_nor_delete"><button className="edit_select" onClick={e => selectQuizAndLoadQuestionsList(item2.id)}><img alt="Edit" src={edit_btn_icon} width="25" height="25"/></button> <button className="delete_select" onClick={() => activateDeleteDialog(item2.id, "quiz")}><img alt="Delete" src={delete_btn_icon} width="25" height="25"/></button></span></p>

                                        {selected_quiz_id !== "" && selected_quiz_id === item2.id ? <div>

                                          <div className="boxes">
                                            <div className="new_course_label_txt horizontal_txtbox_left">Title</div>
                                            <input className="new_course_label_txt_box horizontal_txtbox_right" name="title" defaultValue={edit_quiz_input.title} onChange={e => setValueQuizEdit(e.target.name, e.target.value)}/>
                                          </div>

                                          <br/>

                                          <div className="boxes">
                                            <div className="new_course_label_txt horizontal_txtbox_left">Description</div>
                                            <input className="new_course_label_txt_box horizontal_txtbox_right" name="description" defaultValue={edit_quiz_input.description} onChange={e => setValueQuizEdit(e.target.name, e.target.value)}/>
                                          </div>

                                          <br/>
                                          
                                          <div className="boxes">
                                            <div className="new_course_label_txt horizontal_txtbox_left">Minimum Score</div>
                                            <input className="new_course_label_txt_box horizontal_txtbox_right" type="number" min="0" max="100" name="min_score_percent" defaultValue={edit_quiz_input.min_score_percent} onChange={e => setNumValueQuizEdit(e.target.name, e.target.value)}/>
                                          </div>

                                        <div className="course_add_txt_box_double">
                                          <select className="new_course_label_txt_box new_course_label_txt_box_small course_add_txt_box_in_double_1" name="is_random_questions" defaultValue={edit_quiz_input.is_random_questions} onChange={e => setBoolValueQuizEdit(e.target.name, e.target.value)}>
                                            <option disabled value="null" hidden>Randomize Questions' Orders</option>
                                            <optgroup label="Randomize Questions' Orders">
                                              <option value="true">Randomize Questions' Orders = Yes</option>
                                              <option value="false">Randomize Questions' Orders = No</option>
                                            </optgroup>
                                          </select>

                                          <select className="new_course_label_txt_box new_course_label_txt_box_small course_add_txt_box_in_double_2" name="is_random_choices" defaultValue={edit_quiz_input.is_random_choices} onChange={e => setBoolValueQuizEdit(e.target.name, e.target.value)}>
                                            <option disabled value="null" hidden>Randomize Choices' Orders</option>
                                            <optgroup label="Randomize Choices' Orders">
                                              <option value="true">Randomize Choices' Orders = Yes</option>
                                              <option value="false">Randomize Choices' Orders = No</option>
                                            </optgroup>
                                          </select>
                                        </div>

                                          <div className="course_detail_button_container">
                                                <button className="course_detail_button_items_left" onClick={cancelSelectQuiz}>Cancel</button>
                                                <button className="course_detail_button_items_right" onClick={e => editQuiz(item2.id)}>Edit Quiz</button>
                                              </div>
                                          
                                          <h5 className="new_course_label_txt question_list_header">Questions :</h5>

                                          {questions_loaded ? <div>
                                          { 
                                             questions_data.map((item3,index3) => {
                                              //console.log(item)
                                                return (
                                                    <div className="course_edit_box_item" key={item3.id+"_"+index3}>
                                                      <p className="question_edit_select"><img alt="a created question" src={created_check} width="30" height="30"/> <b>{item3.question}</b> <br/>{item3.type === "mcq" ? "<choices>" : "<text>"} <span className="edit_nor_delete"><button className="edit_select" onClick={e => selectQuestionAndLoadChoicesList(item3.id)}><img alt="Edit" src={edit_btn_icon} width="25" height="25"/></button> <button className="delete_select" onClick={() => activateDeleteDialog(item3.id, "question")}><img alt="Delete" src={delete_btn_icon} width="25" height="25"/></button></span></p>

                                                      {selected_question_id !== "" && selected_question_id === item3.id ? <div>
                                                        
                                                        <div className="boxes">
                                                          <div className="new_course_label_txt horizontal_txtbox_left">Question text</div>
                                                          <input className="new_course_label_txt_box horizontal_txtbox_right" name="question" defaultValue={edit_question_input.question} onChange={e => setValueQuestionEdit(e.target.name, e.target.value)}/>
                                                        </div>

                                                        <br/>

                                                        <div className="boxes">
                                                          <div className="new_course_label_txt horizontal_txtbox_left">Order</div>
                                                          <input className="new_course_label_txt_box horizontal_txtbox_right" type="number" min="1" name="order" defaultValue={edit_question_input.order} onChange={e => setNumValueQuestionEdit(e.target.name, e.target.value)}/>
                                                        </div>

                                                        <br/>

                                                        <div className="boxes">
                                                            <div className="new_course_label_txt horizontal_txtbox_left"><img alt="URL" src={link_url_icon} width="35" height="35"/> Video Source</div>
                                                            <input className="new_course_label_txt_box horizontal_txtbox_right" name="video_source" defaultValue={edit_question_input.video_source} onChange={e => setValueQuestionEdit(e.target.name, e.target.value)}/>
                                                        </div>

                                                        <br/>

                                                        <select className="new_course_label_txt_box new_course_label_txt_box_small" name="type" defaultValue={edit_question_input.type} onChange={e => setValueQuestionEdit(e.target.name, e.target.value)}>
                                                          <option disabled value="null" hidden>Type</option>
                                                          <optgroup label="Type">
                                                            <option value="mcq">Multiple Choice Question</option>
                                                            <option value="text">Text Filling Question</option>
                                                          </optgroup>
                                                        </select>

                                                        <div className="course_detail_button_container">
                                                          <button className="course_detail_button_items_left" onClick={cancelSelectQuestion}>Cancel</button>
                                                          <button className="course_detail_button_items_right" onClick={e => editQuestion(item3.id)}>Edit Question</button>
                                                        </div>

                                                        {edit_question_input.type === "mcq" ? <span><h5 className="new_course_label_txt choice_list_header">Choices :</h5>

                                                        {choices_loaded ? <div>
                                                          {
                                                            choices_data.map((item4,index4) => {
                                                              return (
                                                                  <div className="course_edit_box_item" key={item4.id+"_"+index4}>
                                                                    <p className={"choice_edit_select "+(item4.is_correct ? "choice_edit_select_correct" : "choice_edit_select_wrong")}><img alt="a created choice" src={created_check} width="30" height="30"/> <b>{item4.choice}</b> <br/>{item4.is_correct ? "<correct answer>" : "<wrong answer>"} <span className="edit_nor_delete"><button className="edit_select" onClick={e => selectChoice(item4.id)}><img alt="Edit" src={edit_btn_icon} width="25" height="25"/></button> <button className="delete_select" onClick={() => activateDeleteDialog(item4.id, "choice")}><img alt="Delete" src={delete_btn_icon} width="25" height="25"/></button></span></p>

                                                                    {selected_choice_id !== "" && selected_choice_id === item4.id ? <div>
                                                                    <div className="boxes">
                                                                      <div className="new_course_label_txt horizontal_txtbox_left">Choice text</div>
                                                                      <input className="new_course_label_txt_box horizontal_txtbox_right" name="choice" defaultValue={edit_choice_input.choice} onChange={e => setValueChoiceEdit(e.target.name, e.target.value)}/>
                                                                    </div>

                                                                    <br/>

                                                                    <div className="boxes">
                                                                      <div className="new_course_label_txt horizontal_txtbox_left">Order</div>
                                                                      <input className="new_course_label_txt_box horizontal_txtbox_right" type="number" min="1" name="order" defaultValue={edit_choice_input.order} onChange={e => setNumValueChoiceEdit(e.target.name, e.target.value)}/>
                                                                    </div>
                                                                    
                                                                    <br/>

                                                                    <div className="boxes">
                                                                      <div className="new_course_label_txt horizontal_txtbox_left"><img alt="URL" src={link_url_icon} width="35" height="35"/> Video Source</div>
                                                                      <input className="new_course_label_txt_box horizontal_txtbox_right" name="video_source" defaultValue={edit_choice_input.video_source} onChange={e => setValueChoiceEdit(e.target.name, e.target.value)}/>
                                                                    </div>

                                                                    <br/>

                                                                    <select className="new_course_label_txt_box new_course_label_txt_box_small" name="is_correct" defaultValue={edit_choice_input.is_correct} onChange={e => setBoolValueChoiceEdit(e.target.name, e.target.value)}>
                                                                      <option disabled value="null" hidden>Is it correct?</option>
                                                                      <optgroup label="Is it correct?">
                                                                        <option value="true">Correct answer</option>
                                                                        <option value="false">Wrong answer</option>
                                                                      </optgroup>
                                                                    </select>

                                                                    <div className="course_detail_button_container">
                                                                      <button className="course_detail_button_items_left" onClick={cancelSelectChoice}>Cancel</button>
                                                                      <button className="course_detail_button_items_right" onClick={e => editChoice(item4.id)}>Edit Choice</button>
                                                                    </div>

                                                                    <br/>

                                                                    </div> : ""}

                                                                  </div>
                                                                )
                                                            })
                                                          }

                                                        </div> : ""} 

                                                        {is_add_choice_mode ? "" : <button className="lesson_add_btn choice_add_btn" onClick={enableAddChoice}>+ Add Choice</button>} </span> : ""}

                                                        {is_add_choice_mode ? <div>
                                                          <br/>

                                                          <h6><b>Add New Choice : </b></h6>

                                                          <div className="boxes">
                                                            <div className="new_course_label_txt horizontal_txtbox_left">Choice text</div>
                                                            <input className="new_course_label_txt_box horizontal_txtbox_right" name="choice" defaultValue={new_choice_input.choice} onChange={e => setValueChoice(e.target.name, e.target.value)}/>
                                                          </div>

                                                          <br/>

                                                          <div className="boxes">
                                                            <div className="new_course_label_txt horizontal_txtbox_left">Order</div>
                                                            <input className="new_course_label_txt_box horizontal_txtbox_right" type="number" min="1" name="order" defaultValue={choices_data.length+1} onChange={e => setNumValueChoice(e.target.name, e.target.value)}/>
                                                          </div>
                                                          
                                                          <br/>

                                                          <div className="boxes">
                                                            <div className="new_course_label_txt horizontal_txtbox_left"><img alt="URL" src={link_url_icon} width="35" height="35"/> Video Source </div>
                                                            <input className="new_course_label_txt_box horizontal_txtbox_right" name="video_source" defaultValue={new_choice_input.video_source} onChange={e => setValueChoice(e.target.name, e.target.value)}/>
                                                          </div>

                                                          <br/>

                                                          <select className="new_course_label_txt_box new_course_label_txt_box_small" name="is_correct" defaultValue={new_choice_input.is_correct} onChange={e => setBoolValueChoice(e.target.name, e.target.value)}>
                                                            <option disabled value="null" hidden>Is it correct?</option>
                                                            <optgroup label="Is it correct?">
                                                              <option value="true">Correct answer</option>
                                                              <option value="false">Wrong answer</option>
                                                            </optgroup>
                                                          </select>

                                                          <br/>

                                                          <div className="course_detail_button_container">
                                                                <button className="course_detail_button_items_left" onClick={disableAddChoice}>Cancel</button>
                                                                <button className="course_detail_button_items_right" onClick={e => addChoice()}>Add Choice</button>
                                                              </div>

                                                        </div> : ""}

                                                        <br/>

                                                      </div> : ""}
                                                      
                                                    </div>
                                                  )
                                              })
                                      
                                          }</div>: ""}

                                          {is_add_question_mode ? <div>
                                            <br/>

                                            <h6><b>Add New Question : </b></h6>

                                            <div className="boxes">
                                              <div className="new_course_label_txt horizontal_txtbox_left">Question text</div>
                                              <input className="new_course_label_txt_box horizontal_txtbox_right" name="question" defaultValue={new_question_input.question} onChange={e => setValueQuestion(e.target.name, e.target.value)}/>
                                            </div>

                                            <br/>

                                            <div className="boxes">
                                              <div className="new_course_label_txt horizontal_txtbox_left">Order</div>
                                              <input className="new_course_label_txt_box horizontal_txtbox_right" type="number" min="1" name="order" defaultValue={questions_data.length+1} onChange={e => setNumValueQuestion(e.target.name, e.target.value)}/>
                                            </div>

                                            <br/>

                                            <div className="boxes">
                                              <div className="new_course_label_txt horizontal_txtbox_left"><img alt="URL" src={link_url_icon} width="35" height="35"/> Video Source</div>
                                               <input className="new_course_label_txt_box horizontal_txtbox_right" name="video_source" defaultValue={new_question_input.video_source} onChange={e => setValueQuestion(e.target.name, e.target.value)}/>
                                            </div>

                                            <br/>

                                            <select className="new_course_label_txt_box new_course_label_txt_box_small" name="type" defaultValue={new_question_input.type} onChange={e => setValueQuestion(e.target.name, e.target.value)}>
                                              <option disabled value="null" hidden>Type</option>
                                              <optgroup label="Type">
                                                <option value="mcq">Multiple Choice Question</option>
                                                <option value="text">Text Filling Question</option>
                                              </optgroup>
                                            </select>

                                            <br/>

                                            <div className="course_detail_button_container">
                                                  <button className="course_detail_button_items_left" onClick={disableAddQuestion}>Cancel</button>
                                                  <button className="course_detail_button_items_right" onClick={e => addQuestion()}>Add Question</button>
                                                </div>
                                              
                                              </div> : ""}

                                          {is_add_question_mode ? "" : <button className="lesson_add_btn question_add_btn" onClick={enableAddQuestion}>+ Add Question</button>}

                                          <br/>

                                        </div> : ""}
                                        
                                      </div>
                                    )
                                })
                        
                            }</div>: ""}

                          {is_add_quiz_mode ? "" : <button className="lesson_add_btn quiz_add_btn" onClick={enableAddQuiz}>+ Add Quiz</button>}

                          {is_add_quiz_mode ? <div>

                            <br/>

                            <h6><b>Add New Quiz : </b></h6>

                            <div className="boxes">
                              <div className="new_course_label_txt horizontal_txtbox_left">Title</div>
                              <input className="new_course_label_txt_box horizontal_txtbox_right" name="title" defaultValue={new_quiz_input.title} onChange={e => setValueQuiz(e.target.name, e.target.value)}/>
                            </div>

                            <br/>

                            <div className="boxes">
                              <div className="new_course_label_txt horizontal_txtbox_left">Description</div>
                              <input className="new_course_label_txt_box horizontal_txtbox_right" name="description" defaultValue={new_quiz_input.description} onChange={e => setValueQuiz(e.target.name, e.target.value)}/>
                            </div>

                            <br/>
                            
                            <div className="boxes">
                              <div className="new_course_label_txt horizontal_txtbox_left">Minimum Score</div>
                              <input className="new_course_label_txt_box horizontal_txtbox_right" type="number" min="0" max="100" name="min_score_percent" defaultValue={new_quiz_input.min_score_percent} onChange={e => setNumValueQuiz(e.target.name, e.target.value)}/>
                            </div>

                          <div className="course_add_txt_box_double">
                            <select className="new_course_label_txt_box new_course_label_txt_box_small course_add_txt_box_in_double_1" name="is_random_questions" defaultValue={new_quiz_input.is_random_questions} onChange={e => setBoolValueQuiz(e.target.name, e.target.value)}>
                              <option disabled value="null" hidden>Randomize Questions' Orders</option>
                              <optgroup label="Randomize Questions' Orders">
                                  <option value="true">Randomize Questions' Orders = Yes</option>
                                  <option value="false">Randomize Questions' Orders = No</option>
                              </optgroup>
                            </select>

                            <select className="new_course_label_txt_box new_course_label_txt_box_small course_add_txt_box_in_double_2" name="is_random_choices" defaultValue={new_quiz_input.is_random_choices} onChange={e => setBoolValueQuiz(e.target.name, e.target.value)}>
                              <option disabled value="null" hidden>Randomize Choices' Orders</option>
                              <optgroup label="Randomize Choices' Orders">
                                  <option value="true">Randomize Choices' Orders = Yes</option>
                                  <option value="false">Randomize Choices' Orders = No</option>
                              </optgroup>
                            </select>
                          </div>

                          <br/>

                            <div className="course_detail_button_container">
                                  <button className="course_detail_button_items_left" onClick={disableAddQuiz}>Cancel</button>
                                  <button className="course_detail_button_items_right" onClick={e => addQuiz()}>Add Quiz</button>
                                </div>


                          </div> : ""}

                          <br/>

                          </div> : ""}
                        </div>
                      )
                  })
          
              }</div>: ""}

            <br/>

          </div>
        </div>
        
        
      </div>
      <HomeFooter/>
      </div>
      </main>
    );
  
}

export default ProfileAdminNewCoursePart3;
