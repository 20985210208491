import React, { useCallback, useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import Configuration from '../functions/Configuration';
import recheckAccessTokenIsExpired from '../functions/recheckAccessTokenIsExpired';
import getTokenData from '../functions/getTokenData';
import GetUrlHashValue from '../functions/GetUrlHashValue';
import Shuffle from '../functions/Shuffle';
import NavBar from '../components/NavBar';
import CoursePageNameBox from '../components/CoursePageNameBox';
import CourseNaviBigBox from '../components/CourseNaviBigBox';
import Question from '../components/Question';
import HomeFooter from '../components/HomeFooter';
import AlertDialog from '../components/AlertDialog';
import ConfirmDialog from '../components/ConfirmDialog';
import divide_line_thinner from '../res/divide_line_thinner.svg'


function CourseQuiz() {
  const quizHeaderRef = useRef()
  const quizContainerRef = useRef()
  const [course_id, setCourseID] = useState("")
  const [course_app, setCourseApp] = useState("")
  const [course_title, setCourseTitle] = useState("")
  const [lesson_id, setLessonID] = useState("");
  const [lesson_title, setLessonTitle] = useState("")
  const [quiz_id, setQuizID] = useState("");
  const [lessons, setLessons] = useState([]);

  const [quiz_data, setQuizData] = useState(null);
  const [quizes_data, setQuizesData] = useState([]);
  const [questions_data, setQuestionsData] = useState([]);
  const [is_loaded, setLoaded] = useState(false)

  const [answer, setAnswer] = useState(null)
  const [answers, setAnswers] = useState({
    answers: []
  });
  const [answer_text, setAnswerText] = useState(null)
  const [answers_text, setAnswersText] = useState({
    answers: []
  });
  const [required_choices_count, setRequiredChoicesCount] = useState(0);

  const [quizes_progress, setQuizesProgress] = useState([]);
  const [lesson_progress, setLessonProgress] = useState([]);

  const [is_completed, setIsCompleted] = useState(false)

  const [is_mcq, setIsMCQ] = useState(false)

  //const [choices, setChoices] = useState([])

  const navigate = useNavigate();

  const [dialog_confirm_on, setDialogConfirmOn] = useState(false)
  const [dialog_data, setDialogData] = useState(false)

  const [dialog_alert_on, setDialogAlertOn] = useState(false)
  const [error_msg, setErrorMsg] = useState("")
  const [is_answers_ready, setAnswersReady] = useState(false)

  const closeAlertDialog = () => {
    setDialogAlertOn(false)
  }

  const cancelConfirmDialog = () => {
    setDialogConfirmOn(false)
  }

  const nextQuiz = () => {
    setLoaded(false)
    for (let quiz_index in quizes_data) {
      let quiz = quizes_data[quiz_index]
      //console.log(quiz.id === quiz_id)
      if (quiz.id === quiz_id) {
        let next_quiz = quizes_data[parseInt(quiz_index) + 1]
        //console.log(next_quiz)
        if (next_quiz) {
          navigate('/course_quiz#id=' + course_id + "&course_title=" + course_title + "&lesson_id=" + lesson_id + "&lesson_title=" + lesson_title + "&quiz_id=" + next_quiz.id +"&course_app=" + course_app);
          reloadPage()
          return
        }
      }
    }

    for (let lesson_index in lessons) {
      let lesson = lessons[lesson_index]
      if (lesson.id === lesson_id) {
        let next_lesson = lessons[parseInt(lesson_index) + 1]
        if (next_lesson) {
          navigate('/course_lesson_start#id=' + course_id + "&course_title=" + course_title + "&lesson_id=" + next_lesson.id +"&course_app=" + course_app);
          reloadPage()
          return
        }
      }
    }

    navigate('/course_lesson_start#id=' + course_id + "&course_title=" + course_title + "&lesson_id=" + lessons[0].id + "&course_app=" + course_app);
    reloadPage()
  }

  const confirmSomething = (dialog_data) => {
    //console.log(dialog_data)
    setDialogConfirmOn(false)
    if (dialog_data.type === "back_to_course") {
      navigate('/course_enroll#id=' + dialog_data.course_id + "&course_app=" + course_app);
    } else if (dialog_data.type === "back_to_lesson") {
      navigate('/course_lesson_start#id=' + dialog_data.course_id + "&course_title=" + dialog_data.course_title + "&lesson_id=" + dialog_data.lesson_id+"&course_app=" + course_app);
    } else if (dialog_data.type === "select_lesson") {
      navigate('/course_lesson_start#id=' + dialog_data.course_id + "&course_title=" + dialog_data.course_title + "&lesson_id=" + dialog_data.lesson_id + "&course_app=" + course_app);
    } else if (dialog_data.type === "select_quiz") {
      navigate('/course_quiz#id=' + dialog_data.course_id + "&course_title=" + dialog_data.course_title + "&lesson_id=" + dialog_data.lesson_id + "&lesson_title=" + dialog_data.lesson_title + "&quiz_id=" + dialog_data.quiz_id + "&course_app=" + course_app);
      reloadPage()
    } else {
      setDialogAlertOn(true)
    }
  }

  const submitCourseQuiz = () => {

    // check if all answer is not empty
    if(!is_answers_ready) {
      setDialogAlertOn(true)
      setErrorMsg("กรุณาตอบคำถามให้ครบ")
      return
    }

    let access_token_data = window.localStorage.getItem("access_token_data");
    let access_token_data_type = window.localStorage.getItem("access_token_data_type");

    let user_id = getTokenData("user_id")

    let url
    let body
    if(course_app === "video") {
      url = Configuration("video_host") + "/api/v1/user/" + user_id + "/course/" + course_id + "/lesson/" + lesson_id + "/quiz/" + quiz_id + "/submit-answer";
      body = {
        answers: answers_text.answers.concat(answers.answers)
      }
    }else if(course_app === "exam"){
      url = Configuration("video_host") + "/api/v1/user/" + user_id + "/course/" + course_id + "/lesson/" + lesson_id + "/quiz/" + quiz_id + "/submit-exam-answer";
      body = {
        answers: answers_text.answers.concat(answers.answers)
      }
    }else{
      // TODO handle default
    }
    
    //console.log(body.answers)

    const postData = async () => {
      try {
        await axios({
          method: "post",
          url: url,
          data: body,
          headers: { "Authorization": access_token_data_type + " " + access_token_data },
        });
        //console.log(response.data.data);
        navigate('/course_quiz_result#id=' + course_id + "&course_title=" + course_title + "&lesson_id=" + lesson_id + "&lesson_title=" + lesson_title + "&quiz_id=" + quiz_id + "&is_mcq=" + is_mcq + "&course_app=" + course_app);

      } catch (error) {
        console.log(error)
        if (error.response.status < 500) {
          setErrorMsg("Answers Submit Error")
        } else {
          setErrorMsg("Internal Server Error")
        }
        setDialogAlertOn(true)
      }
    };

    //console.log(answers)
    postData()

  }

  const backToCourseStart = () => {
    if (!is_completed) {
      setDialogData({
        "type": "back_to_course",
        "course_id": course_id,
      })
      setDialogConfirmOn(true)
    } else {
      navigate('/course_enroll#id=' + course_id + "&course_app=" + course_app);
    }
  }

  const backToLessonStart = () => {
    if (!is_completed) {
      setDialogData({
        "type": "back_to_lesson",
        "course_id": course_id,
        "course_title": course_title,
        "lesson_id": lesson_id,
      })
      setDialogConfirmOn(true)
    } else {
      navigate('/course_lesson_start#id=' + course_id + "&course_title=" + course_title + "&lesson_id=" + lesson_id + "&course_app=" + course_app);
    }
  }

  const selectLesson = (lesson_id) => {
    if (!is_completed) {
      setDialogData({
        "type": "select_lesson",
        "course_id": course_id,
        "course_title": course_title,
        "lesson_id": lesson_id,
      })
      setDialogConfirmOn(true)
    } else {
      navigate('/course_lesson_start#id=' + course_id + "&course_title=" + course_title + "&lesson_id=" + lesson_id + "&course_app=" + course_app);
    }
  }

  const selectQuiz = (quiz_id) => {
    if (!is_completed) {
      setDialogData({
        "type": "select_quiz",
        "course_id": course_id,
        "course_title": course_title,
        "lesson_id": lesson_id,
        "lesson_title": lesson_title,
        "quiz_id": quiz_id,
      })
      setDialogConfirmOn(true)
    } else {
      navigate('/course_quiz#id=' + course_id + "&course_title=" + course_title + "&lesson_id=" + lesson_id + "&lesson_title=" + lesson_title + "&quiz_id=" + quiz_id + "&course_app=" + course_app);
      reloadPage()
    }
  }

  const setQuestionAnswer = (question_id, choice_id_or_text, question_type) => {
    if (question_type === "mcq") {
      setAnswer({
        "question_id": question_id,
        "choice_id": choice_id_or_text,
        "question_type": question_type,
      })
    } else {
      setAnswerText({
        "question_id": question_id,
        "text": choice_id_or_text,
        "question_type": question_type,
      })
    }
  }

  useEffect(() => {
    if(answer){
      setAnswers(prevState => ({
        ...prevState,
        answers: [...prevState.answers.filter(item => item.question_id !== answer.question_id), answer]
      }))
    }
  }, [answer])
  // useEffect(() => {
  //   console.log(answers)
  // }, [answers])


  useEffect(() => {
    if(answer_text){
      setAnswersText(prevState => ({
        ...prevState,
        answers: [...prevState.answers.filter(item => item.question_id !== answer_text.question_id), answer_text]
      }));
    }
  }, [answer_text])

  // useEffect(() => {
  //   console.log(answers_text)
  // }, [answers_text])

  useEffect(() => {
    // if question data is already loaded
    if(questions_data){
      let isAnswerTextReady = true
      if(answers_text.answers){
        answers_text.answers.map(item => {
          if(item.text === ""){
            isAnswerTextReady=false
          }
        })
      }
      if(answers.answers.concat(answers_text.answers).length === questions_data.length && isAnswerTextReady){
        setAnswersReady(true)
      }else{
        setAnswersReady(false)
      }
      // console.log(is_answers_ready)
    }
  }, [answers, answers_text, questions_data])

  // useEffect(() => {
  //   console.log(is_answers_ready)
  // },[is_answers_ready])

  const reloadPage = () => {
    let get_course_id = GetUrlHashValue("id")
    setCourseID(get_course_id)

    let get_course_app = GetUrlHashValue("course_app")
    setCourseApp(get_course_app)

    let get_course_title = GetUrlHashValue("course_title")
    setCourseTitle(decodeURIComponent(get_course_title))

    let get_lesson_id = GetUrlHashValue("lesson_id")
    setLessonID(get_lesson_id)

    let get_lesson_title = GetUrlHashValue("lesson_title")
    setLessonTitle(decodeURIComponent(get_lesson_title))

    let get_quiz_id = GetUrlHashValue("quiz_id")
    setQuizID(get_quiz_id)

    let user_id = getTokenData("user_id")

    setIsMCQ(false)

    if (recheckAccessTokenIsExpired()) {
      navigate('/');
    }

    let access_token_data = window.localStorage.getItem("access_token_data");
    let access_token_data_type = window.localStorage.getItem("access_token_data_type");

    const url = Configuration("video_host") + "/api/v1/course/" + get_course_id + "/lesson/" + get_lesson_id + "/quiz";

    const fetchData = async () => {
      try {
        const { data: response } = await axios({
          method: "get",
          url: url,
          headers: { "Authorization": access_token_data_type + " " + access_token_data },
        });
        let the_data = response.data
        //console.log(the_data)
        setQuizesData(the_data)
        fetchData2()
      } catch (error) {
        if (error.response.status < 500) {
          setErrorMsg("Quiz Load Error")
        } else {
          setErrorMsg("Internal Server Error")
        }
        setDialogAlertOn(true)
      }
    };

    const url2 = Configuration("video_host") + "/api/v1/course/" + get_course_id + "/lesson/" + get_lesson_id + "/quiz/" + get_quiz_id;

    const fetchData2 = async () => {
      try {
        const { data: response } = await axios({
          method: "get",
          url: url2,
          headers: { "Authorization": access_token_data_type + " " + access_token_data },
        });
        let the_data = response.data
        //console.log(the_data)
        setQuizData(the_data)
        fetchDataForTheRest(the_data.is_random_questions)

        for (let i in the_data.questions) {
          if (the_data.questions[i].type === "mcq") {
            setIsMCQ(true)
            break
          }
        }

      } catch (error) {
        if (error.response.status < 500) {
          setErrorMsg("Quiz Load Error")
        } else {
          setErrorMsg("Internal Server Error")
        }
        setDialogAlertOn(true)
      }
    };

    const url3 = Configuration("video_host") + "/api/v1/course/" + get_course_id + "/lesson/" + get_lesson_id + "/quiz/" + get_quiz_id + "/question";

    const url4 = Configuration("video_host") + "/api/v1/user/" + user_id + "/course/" + get_course_id + "/lesson/" + get_lesson_id + "/quiz/" + get_quiz_id + "/progress";

    const url5 = Configuration("video_host") + "/api/v1/user/" + user_id + "/course/" + get_course_id + "/lesson/" + get_lesson_id + "/progress";

    const url6 = Configuration("video_host") + "/api/v1/course/" + get_course_id + "/lesson";

    const url7 = Configuration("video_host") + "/api/v1/user/" + user_id + "/course/" + get_course_id + "/progress";

    const fetchDataForTheRest = async (is_random_questions) => {
      try {
        const data_arr = await Promise.all([
          axios({
            method: "get",
            url: url3,
            headers: { "Authorization": access_token_data_type + " " + access_token_data },
          }),
          axios({
            method: "get",
            url: url4,
            headers: { "Authorization": access_token_data_type + " " + access_token_data },
          }),
          axios({
            method: "get",
            url: url5,
            headers: { "Authorization": access_token_data_type + " " + access_token_data },
          }),
          axios({
            method: "get",
            url: url6,
            headers: { "Authorization": access_token_data_type + " " + access_token_data },
          }),
          axios({
            method: "get",
            url: url7,
            headers: { "Authorization": access_token_data_type + " " + access_token_data },
          })

        ]);
        //console.log(data_arr)

        for (let i in data_arr) {
          let index = parseInt(i)
          let response = data_arr[index].data

          if (index === 0) {
            let the_data = response.data
            if (the_data) {
              if (is_random_questions) {
                setQuestionsData(Shuffle(the_data))
              } else {
                setQuestionsData(the_data)
              }
            } else {
              setQuestionsData([])
            }

            let mcq_req = 0
            for (let i in the_data) {
              if (the_data[i].type === "mcq") {
                mcq_req++
              }
            }
            setRequiredChoicesCount(mcq_req)

            //temp
            /*
            for (let i in the_data) {
              let q_id = the_data[i].id
              fetchData4(Configuration("video_host")+"/api/v1/course/"+get_course_id+"/lesson/"+get_lesson_id+"/quiz/"+get_quiz_id+"/question/"+q_id+"/choice")
            }
            setTimeout(() => { 
               setLoaded(true)
            }, 1000);*/

            //end temp
          } else if (index === 1) {
            let the_data = response.data
            setIsCompleted(the_data.is_passed)
          } else if (index === 2) {
            let the_data = response.data
            setQuizesProgress(the_data.quiz_progress)
          } else if (index === 3) {
            let the_data = response.data.lessons
            setLessons(the_data)
          } else if (index === 4) {
            let the_data = response.data
            setLessonProgress(the_data.lesson_progress)
            setLoaded(true)
          }

        }

      } catch (error) {
        if (error.response.status < 500) {
          setErrorMsg("Quiz Load Error")
        } else {
          setErrorMsg("Internal Server Error")
        }
        setDialogAlertOn(true)
      }
    };

    fetchData()
  }

  useEffect(
    () => {
      reloadPage()
    },
    []
  )
  useEffect(()=>{
    if (is_loaded){
      quizHeaderRef.current?.focus()
    }else{
      quizContainerRef.current?.focus()
    }
  },[is_loaded])

  return (
    <main>
      <NavBar isCourse={true} />
      <div className='container'>
        <div className={"course_body_back "}>

          {dialog_alert_on ? <AlertDialog alert_title={error_msg} alert_msg={""} parentCallback={closeAlertDialog} /> : ""}

          {dialog_confirm_on ? <ConfirmDialog confirm_title={"คุณต้องการยกเลิก Quiz นี้ใช่หรือไม่?"} confirm_msg={""} dialog_data={dialog_data} parentCallback={confirmSomething} parentCallbackCancel={cancelConfirmDialog} /> : ""}

          <div className={"course_body"}>

            <div className="home_item boxes course_item_big_box">

              {is_loaded ? <CoursePageNameBox courseName={course_title} courseCallback={backToCourseStart} lessonName={lesson_title} lessonCallback={backToLessonStart} quizName={quiz_data.title} type={"quiz"} /> : ""}

              {/*<CourseNaviSmallBox type={"quizes"}/>*/}

            </div>

            <div ref={quizContainerRef} tabIndex="0" className="home_item boxes course_item_big_box">

              {is_loaded ? <CourseNaviBigBox course_id={course_id} arrayType={"quizes"} lessonsArr={lessons} lessonProgressArr={lesson_progress} quizesArr={quizes_data} quizProgressArr={quizes_progress} parentCallbackLesson={selectLesson} parentCallbackQuiz={selectQuiz} /> : ""}

              {is_loaded ?
                <div className="course_main_box course_main_box_quiz">
                  
                  <h1 ref={quizHeaderRef} tabIndex="0" className="course_enroll_paragraph_title quiz_title">
                    Quiz : {quiz_data.title}
                  </h1>
                  <div className="quiz_description">
                    {quiz_data.description}
                  </div>
                  <img className='divide_line' src={divide_line_thinner} />
                  <br />
                  <br />

                  {!is_completed ? <span>{questions_data ? (questions_data.map((item, index) => {
                    return (
                      <div key={item.id + "_" + item.order}>
                        <Question questionData={item} index_no={index + 1} isRandomChoices={quiz_data.is_random_choices} parentCallback={setQuestionAnswer} />
                      </div>
                    )
                  })
                  ) : ""}

                    {/* {answers.answers.length === required_choices_count ? <button className="quiz_submit_btn" onClick={submitCourseQuiz}>ส่งคำตอบ</button> : <button className="quiz_submit_btn grey_out_button_disabled" disabled>ส่งคำตอบ</button>} */}
                    {is_answers_ready ? <button className="quiz_submit_btn" onClick={submitCourseQuiz}>ส่งคำตอบ</button> : <button className="quiz_submit_btn grey_out_button_disabled" disabled>ส่งคำตอบ</button>}

                  </span> : <span><h6>แบบทดสอบฉบับนี้ได้ทำเสร็จไปแล้ว</h6>

                  <button className="result_btn result_btn_center" onClick={nextQuiz}>ทำแบบทดสอบฉบับถัดไป</button>
                  </span>}

                </div> : ""}

            </div>

            <br />

          </div>
          <HomeFooter />
        </div>
      </div>
    </main>
  );

}

export default CourseQuiz;
