import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import Configuration from '../functions/Configuration';
import recheckAccessTokenIsExpired from '../functions/recheckAccessTokenIsExpired';
import getTokenData from '../functions/getTokenData';
import GetUrlHashValue from '../functions/GetUrlHashValue';
import NavBar from '../components/NavBar';
import CoursePageNameBox from '../components/CoursePageNameBox';
import CourseNaviBigBox from '../components/CourseNaviBigBox';
import HomeFooter from '../components/HomeFooter';
import AlertDialog from '../components/AlertDialog';
import result_fail from '../res/result_fail.svg';
import result_success from '../res/result_success.svg';
import divide_line_thinner from '../res/divide_line_thinner.svg'

function CourseQuizResult() {
  const quizHeaderRef = useRef()
  const quizContainerRef = useRef()
  const [course_id, setCourseID] = useState("")
  const [course_app, setCourseApp] = useState("")
  const [course_title, setCourseTitle] = useState("")
  const [lesson_id, setLessonID] = useState("");
  const [lesson_title, setLessonTitle] = useState("")
  const [quiz_id, setQuizID] = useState("");
  const [lessons, setLessons] = useState([]);

  const [quiz_data, setQuizData] = useState(null);
  const [quizes_data, setQuizesData] = useState([]);
  const [is_loaded, setLoaded] = useState(false)

  const [score_percent, setScore] = useState(0)
  const [score_threshold, setThreshold] = useState(60)
  const [score_count, setScoreCount] = useState(0)
  const [score_full_count, setScoreFullCount] = useState(0)
  const [all_lessons_done, setAllDone] = useState(false)
  const [is_passed, setIsPassed] = useState(false)

  const [is_mcq, setIsMCQ] = useState(false)

  const [quizes_progress, setQuizesProgress] = useState([]);
  const [lesson_progress, setLessonProgress] = useState([]);

  const navigate = useNavigate();

  const [dialog_alert_on, setDialogAlertOn] = useState(false)
  const [error_msg, setErrorMsg] = useState("")

  const closeAlertDialog = () => {
    setDialogAlertOn(false)
  }

  const backToMainPage = () => {
    navigate('/')
  }

  const concludeCourse = () => {
    navigate('/course_finished#id=' + course_id + "&course_app=" + course_app);
  }

  const backToCourseStart = () => {
    navigate('/course_enroll#id=' + course_id + "&course_app=" + course_app);
  }

  const backToLessonStart = () => {
    navigate('/course_lesson_start#id=' + course_id + "&course_title=" + course_title + "&lesson_id=" + lesson_id + "&course_app=" + course_app);
  }

  const retryQuiz = () => {
    navigate('/course_quiz#id=' + course_id + "&course_title=" + course_title + "&lesson_id=" + lesson_id + "&lesson_title=" + lesson_title + "&quiz_id=" + quiz_id + "&course_app=" + course_app);
  }

  const selectLesson = (lesson_id) => {
    navigate('/course_lesson_start#id=' + course_id + "&course_title=" + course_title + "&lesson_id=" + lesson_id + "&course_app=" + course_app);
  }

  const selectQuiz = (quiz_id) => {
    navigate('/course_quiz#id=' + course_id + "&course_title=" + course_title + "&lesson_id=" + lesson_id + "&lesson_title=" + lesson_title + "&quiz_id=" + quiz_id + "&course_app=" + course_app);
  }

  const nextQuiz = () => {
    for (let quiz_index in quizes_data) {
      let quiz = quizes_data[quiz_index]
      //console.log(quiz.id === quiz_id)
      if (quiz.id === quiz_id) {
        let next_quiz = quizes_data[parseInt(quiz_index) + 1]
        //console.log(next_quiz)
        if (next_quiz) {
          navigate('/course_quiz#id=' + course_id + "&course_title=" + course_title + "&lesson_id=" + lesson_id + "&lesson_title=" + lesson_title + "&quiz_id=" + next_quiz.id + "&course_app=" + course_app);
          return
        }
      }
    }

    for (let lesson_index in lessons) {
      let lesson = lessons[lesson_index]
      if (lesson.id === lesson_id) {
        let next_lesson = lessons[parseInt(lesson_index) + 1]
        if (next_lesson) {
          navigate('/course_lesson_start#id=' + course_id + "&course_title=" + course_title + "&lesson_id=" + next_lesson.id + "&course_app=" + course_app);
          return
        }
      }
    }

    navigate('/course_lesson_start#id=' + course_id + "&course_title=" + course_title + "&lesson_id=" + lessons[0].id + "&course_app=" + course_app);
  }

  useEffect(
    () => {
      let get_course_id = GetUrlHashValue("id")
      setCourseID(get_course_id)

      let get_course_app = GetUrlHashValue("course_app")
      setCourseApp(get_course_app)

      let get_course_title = GetUrlHashValue("course_title")
      setCourseTitle(decodeURIComponent(get_course_title))

      let get_lesson_id = GetUrlHashValue("lesson_id")
      setLessonID(get_lesson_id)

      let get_lesson_title = GetUrlHashValue("lesson_title")
      setLessonTitle(decodeURIComponent(get_lesson_title))

      let get_quiz_id = GetUrlHashValue("quiz_id")
      setQuizID(get_quiz_id)

      let get_is_mcq = GetUrlHashValue("is_mcq")
      setIsMCQ(get_is_mcq === "true")

      let user_id = getTokenData("user_id")

      if (recheckAccessTokenIsExpired()) {
        navigate('/');
      }

      let access_token_data = window.localStorage.getItem("access_token_data");
      let access_token_data_type = window.localStorage.getItem("access_token_data_type");

      const url = Configuration("video_host") + "/api/v1/course/" + get_course_id + "/lesson/" + get_lesson_id + "/quiz";

      const url2 = Configuration("video_host") + "/api/v1/course/" + get_course_id + "/lesson/" + get_lesson_id + "/quiz/" + get_quiz_id;

      const url3 = Configuration("video_host") + "/api/v1/user/" + user_id + "/course/" + get_course_id + "/lesson/" + get_lesson_id + "/quiz/" + get_quiz_id + "/progress";

      const url4 = Configuration("video_host") + "/api/v1/user/" + user_id + "/course/" + get_course_id + "/progress";

      const url5 = Configuration("video_host") + "/api/v1/user/" + user_id + "/course/" + get_course_id + "/lesson/" + get_lesson_id + "/progress";

      const url6 = Configuration("video_host") + "/api/v1/course/" + get_course_id + "/lesson";

      const url7 = Configuration("video_host") + "/api/v1/user/" + user_id + "/course/" + get_course_id + "/progress";

      const fetchDataAll = async () => {
        try {
          const data_arr = await Promise.all([
            axios({
              method: "get",
              url: url,
              headers: { "Authorization": access_token_data_type + " " + access_token_data },
            }),
            axios({
              method: "get",
              url: url2,
              headers: { "Authorization": access_token_data_type + " " + access_token_data },
            }),
            axios({
              method: "get",
              url: url3,
              headers: { "Authorization": access_token_data_type + " " + access_token_data },
            }),
            axios({
              method: "get",
              url: url4,
              headers: { "Authorization": access_token_data_type + " " + access_token_data },
            }),
            axios({
              method: "get",
              url: url5,
              headers: { "Authorization": access_token_data_type + " " + access_token_data },
            }),
            axios({
              method: "get",
              url: url6,
              headers: { "Authorization": access_token_data_type + " " + access_token_data },
            }),
            axios({
              method: "get",
              url: url7,
              headers: { "Authorization": access_token_data_type + " " + access_token_data },
            })

          ]);
          //console.log(data_arr)

          for (let i in data_arr) {
            let index = parseInt(i)
            let response = data_arr[index].data

            if (index === 0) {
              let the_data = response.data
              //console.log(the_data)
              setQuizesData(the_data)
            } else if (index === 1) {
              let the_data = response.data
              //console.log(the_data)
              setQuizData(the_data)
              setThreshold(the_data.min_score_percent)
            } else if (index === 2) {
              let the_data = response.data
              //console.log(the_data)
              setScore((Math.round(the_data.score_percent * 1) / 1).toFixed(0))
              setScoreFullCount(the_data.total_score)
              setScoreCount(the_data.score)
              setIsPassed(the_data.is_passed)
            } else if (index === 3) {
              let the_data = response.data
              //console.log(the_data)
              setAllDone(the_data.is_completed)
            } else if (index === 4) {
              let the_data = response.data
              setQuizesProgress(the_data.quiz_progress)
            } else if (index === 5) {
              let the_data = response.data.lessons
              setLessons(the_data)
            } else if (index === 6) {
              let the_data = response.data
              //console.log(the_data.lesson_progress)
              setLessonProgress(the_data.lesson_progress)

              setLoaded(true)
            }

          }

        } catch (error) {
          if (error.response.status < 500) {
            setErrorMsg("Quiz Result Load Error")
          } else {
            setErrorMsg("Internal Server Error")
          }
          setDialogAlertOn(true)
        }
      };

      fetchDataAll()
    },
    []
  )
  useEffect(() => {
    if (is_loaded) {
      quizHeaderRef.current?.focus()
    } else {
      quizContainerRef.current?.focus()
    }
  }, [is_loaded])

  return (
    <main>
      <NavBar isCourse={true} />
      <div className='container'>
        <div className={"course_body_back "}>

          {dialog_alert_on ? <AlertDialog alert_title={error_msg} alert_msg={""} parentCallback={closeAlertDialog} /> : ""}

          <div className={"course_body"}>

            <div className="home_item boxes course_item_big_box">

              {is_loaded ? <CoursePageNameBox courseName={course_title} courseCallback={backToCourseStart} lessonName={lesson_title} lessonCallback={backToLessonStart} quizName={quiz_data.title} type={"quiz"} /> : ""}

              {/*<CourseNaviSmallBox type={"quizes"}/>*/}

            </div>

            <div ref={quizContainerRef} tabIndex="0" className="home_item boxes course_item_big_box">

              {is_loaded ? <CourseNaviBigBox course_id={course_id} arrayType={"quizes"} lessonsArr={lessons} lessonProgressArr={lesson_progress} quizesArr={quizes_data} quizProgressArr={quizes_progress} parentCallbackLesson={selectLesson} parentCallbackQuiz={selectQuiz} /> : ""}

              {
                is_loaded && course_app === "exam" ?
                  <div className="course_main_box course_main_box_quiz">
                    <h1 ref={quizHeaderRef} tabIndex="0" className="course_enroll_paragraph_title quiz_title">
                      Quiz : {quiz_data.title}
                    </h1>
                    <div className="quiz_description">
                      {quiz_data.description}
                    </div>
                    <img className='divide_line' src={divide_line_thinner} />
                    <br />
                    <br />
                    <div className={"result_box " + (is_passed ? "result_pass" : "result_fail")}>
                      <div className="boxes">
                        <img className="result_img" alt="success" src={result_success} width="110" height="100" />
                        <div><h2>คุณทำแบบทดสอบฉบับนี้เสร็จแล้ว</h2>
                          {/* <span className="result_txt">รอแอดมินแจ้งผล</span> */}
                        </div>
                      </div>

                    </div>
                    {
                      all_lessons_done ?
                        <button className="result_btn result_btn_center" onClick={backToMainPage}>คุณทำแบบทดสอบครบทุกฉบับแล้ว กลับหน้าหลัก</button> :
                        <button className="result_btn result_btn_center" onClick={nextQuiz}>ทำแบบทดสอบฉบับถัดไป</button>
                    }
                  </div> : <></>
              }
              {is_loaded && course_app === "video" ?
                <div className="course_main_box course_main_box_quiz">

                  <h1 ref={quizHeaderRef} tabIndex="0" className="course_enroll_paragraph_title quiz_title">
                    Quiz : {quiz_data.title}
                  </h1>
                  <div className="quiz_description">
                    {quiz_data.description}
                  </div>
                  <img className='divide_line' src={divide_line_thinner} />
                  <br />
                  <br />

                  <div className={"result_box " + (is_passed ? "result_pass" : "result_fail")}>
                    <div className="boxes">

                      <div className="result_left">
                        <div className="result_msg">{is_passed ?

                          <div className="boxes">
                            <img className="result_img" alt="success" src={result_success} width="110" height="100" />
                            <div><h2>ขอแสดงความยินดีด้วย!</h2>
                              <span className="result_txt">คุณทำแบบฝึกหัดบทนี้ผ่านแล้ว</span></div>
                          </div>

                          :

                          <div className="boxes">
                            <img className="result_img" alt="fail" src={result_fail} width="110" height="100" />
                            <div><h2>ขอแสดงความเสียใจด้วย</h2>
                              <span className="result_txt">คะแนนของคุณยังไม่ผ่านเกณฑ์ที่กำหนดไว้ <br />
                                กรุณาลองทำใหม่อีกครั้ง</span></div>
                          </div>}

                        </div>

                        {is_mcq ? <div>เกณฑ์การผ่าน : {score_threshold + "% หรือมากกว่า"}</div> : ""}
                      </div>

                      <div className="result_stick">

                      </div>

                      {is_mcq ? <div className="result_right">
                        <div>คะแนนของคุณ</div>
                        <div className={"result_percent " + (is_passed ? "result_percent_pass" : "result_percent_fail")}>{score_percent}%</div>
                        <p className={" " + (is_passed ? "result_percent_pass" : "result_percent_fail")}>ถูก <b>{score_count}/{score_full_count}</b> ข้อ</p>
                      </div> : ""}

                    </div>
                  </div>

                  {is_passed ?
                    (all_lessons_done ? <button className="result_btn result_btn_center" onClick={concludeCourse}>จบ Course</button> : <button className="result_btn result_btn_center" onClick={nextQuiz}>ทำแบบทดสอบฉบับถัดไป</button>)

                    :

                    <div><button className="result_btn result_btn_left" onClick={backToLessonStart}>ทบทวนบทเรียน</button><button className="result_btn result_btn_right" onClick={retryQuiz}>ทำแบบทดสอบอีกครั้ง</button></div>}

                </div> : ""}

            </div>

            <br />
            <br />
            <br />
            <br />
            <br />

          </div>
          <HomeFooter />
        </div>
      </div>
    </main>
  );

}

export default CourseQuizResult;
