function YoutubeConvertToEmbed(youtube_raw_link) {
  var cut_short_true_link = youtube_raw_link
  if (youtube_raw_link.includes("&")) { //prevent aliening url hashes
    cut_short_true_link = (youtube_raw_link.split("&"))[0]
  }
  if (cut_short_true_link.includes("watch?v=")) {
    return cut_short_true_link.replace("watch?v=", "embed/")
  } else {
    return cut_short_true_link
  }
}

export default YoutubeConvertToEmbed;