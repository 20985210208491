import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import Configuration from '../functions/Configuration';
import recheckAccessTokenIsExpired from '../functions/recheckAccessTokenIsExpired';
import getCurrentUserType from '../functions/getCurrentUserType';
import SimplifyDateString from '../functions/SimplifyDateString';
import GetUrlHashValue from '../functions/GetUrlHashValue';
import GetCurrentDateTimeString from '../functions/GetCurrentDateTimeString';
import AlertDialog from '../components/AlertDialog';
import ConfirmDialog from '../components/ConfirmDialog';
import ProfileMenu from '../components/ProfileMenu';
import UserAdminDropdown from '../components/UserAdminDropdown';
import NavBar from '../components/NavBar';
import HomeFooter from '../components/HomeFooter';

function ProfileAdminCourseUserList() {
  const [is_loaded, setIsLoaded] = useState(false)
  const [course_id, setCourseID] = useState("")
  const [course_data, setCourseData] = useState({})
  const [pagination, setPagination] = useState({})
  const [user_list, setUserList] = useState([])
  const [students_overview, setStudentOverview] = useState({})

  const [disability_enum, setDisabilityEnum] = useState([]);

  const [filter_status, setFilterStatus] = useState("null")
  const [filter_handycap, setFilterHandycap] = useState("null")
  const [filter_search, setFilterSearch] = useState("")

  const [dialog_confirm_on, setDialogConfirmOn] = useState(false)
  const [dialog_data, setDialogData] = useState(false)

  const [dialog_alert_on, setDialogAlertOn] = useState(false)
  const [error_msg, setErrorMsg] = useState("")

  const navigate = useNavigate();

  const activateDeleteDialog = (course_id) => {
    setDialogConfirmOn(true)
    setDialogData(course_id)
  }

  const selectFilterStatus = (value) => {
    setIsLoaded(false)
    setFilterStatus(value)
    reloadTable(1, value, filter_handycap, filter_search)
  }

  const selectFilterHandycap = (value) => {
    setIsLoaded(false)
    setFilterHandycap(value)
    reloadTable(1, filter_status, value, filter_search)
  }

  const selectFilterSearch = () => {
    setIsLoaded(false)
    reloadTable(1, filter_status, filter_handycap, filter_search)
  }

  const cancelDeleteDialog = () => {
    setDialogConfirmOn(false)
  }

  const closeAlertDialog = () => {
    setDialogAlertOn(false)
  }

  const exportCSV = (page, status, handycap, search) => {
    let get_course_id = GetUrlHashValue("id")
    setCourseID(get_course_id)

    let access_token_data = window.localStorage.getItem("access_token_data");
    let access_token_data_type = window.localStorage.getItem("access_token_data_type");

    let url = Configuration("user_host")+"/api/v1/user-course/course/"+get_course_id+"/students/export?";

        if (page) {
          url += "&page="+page
        } else {
          url += "&page=1"
        }

        if (status) {
          if (status !== "null" && status !== "") {
            url += "&status="+status
          }
        } else if (filter_status !== "null" && filter_status !== "") {
          url += "&status="+filter_status
        }

        if (handycap) {
          if (handycap !== "null" && handycap !== "") {
            url += "&handycap="+handycap
          }
        } else if (filter_handycap !== "null" && filter_handycap !== "") {
          url += "&handycap="+filter_handycap
        }

        if (search) {
          if (search !== "") {
            url += "&search="+search
          }
        } else if (filter_search !== "") {
          url += "&search="+filter_search
        }

      const fetchData = async () => {
        try {
          const {data: response} = await axios({
            method: "get",
            url: url,
            headers: { "Authorization": access_token_data_type + " " + access_token_data },
          });

          let encodedUri = 'data:text/csv;charset=utf-8,' + encodeURI(response);
          let filename_coursetitle = course_data.course_title.replace(/ /g, "_")
          let datetime = GetCurrentDateTimeString().split(".")[0]
  
          let hiddenElement = document.createElement('a');  
          hiddenElement.href = encodedUri
          hiddenElement.target = '_blank';
          hiddenElement.style.display = "hidden"  
            
          //provide the name for the CSV file to be downloaded  
          hiddenElement.download = filename_coursetitle+'__students__'+datetime+'.csv';  
          document.body.appendChild(hiddenElement);
          hiddenElement.click();  
          document.body.removeChild(hiddenElement);
          
        } catch (error) {
          if (error.response.status < 500) {
            setErrorMsg("CSV Load Error")
          } else {
            setErrorMsg("Internal Server Error")
          }
          setDialogAlertOn(true)
        }
      };

      fetchData()
  }

  const reloadTable = (page, status, handycap, search) => {

      let get_course_id = GetUrlHashValue("id")
      setCourseID(get_course_id)

      let access_token_data = window.localStorage.getItem("access_token_data");
      let access_token_data_type = window.localStorage.getItem("access_token_data_type");

      const url = Configuration("main_host")+"/api/v1/course/"+get_course_id;

      let url2 = Configuration("user_host")+"/api/v1/user-course/course/"+get_course_id+"/students?";

        if (page) {
          url2 += "&page="+page
        } else {
          url2 += "&page=1"
        }

        if (status) {
          if (status !== "null" && status !== "") {
            url2 += "&status="+status
          }
        } else if (filter_status !== "null" && filter_status !== "") {
          url2 += "&status="+filter_status
        }

        if (handycap) {
          if (handycap !== "null" && handycap !== "") {
            url2 += "&handycap="+handycap
          }
        } else if (filter_handycap !== "null" && filter_handycap !== "") {
          url2 += "&handycap="+filter_handycap
        }

        if (search) {
          if (search !== "") {
            url2 += "&search="+search
          }
        } else if (filter_search !== "") {
          url2 += "&search="+filter_search
        }

      const url3 = Configuration("user_host")+"/api/v1/user-course/course/"+get_course_id+"/students-overview";

      const fetchDataAll = async () => {
        try {
          const data_arr = await Promise.all([
            axios({
              method: "get",
              url: url,
              headers: { "Authorization": access_token_data_type + " " + access_token_data },
            }),
            axios({
              method: "get",
              url: url2,
              headers: { "Authorization": access_token_data_type + " " + access_token_data },
            }),
            axios({
              method: "get",
              url: url3,
              headers: { "Authorization": access_token_data_type + " " + access_token_data },
            })

          ]);
          //console.log(data_arr)

          for (let i in data_arr) {
            let index = parseInt(i)
            let response = data_arr[index].data

            if (index === 0) {
              setCourseData(response.data)
              setIsLoaded(true)
            } else if (index === 1) {
              let the_data = response.data
              setPagination(the_data.pagination)
              if (the_data.students) {
                setUserList(the_data.students)
              } else {
                setUserList([])
              }
            } else if (index === 2) {
              let the_data = response.data
              setStudentOverview(the_data)
              setIsLoaded(true)
            }

          }
          
        } catch (error) {
          if (error.response.status < 500) {
            setErrorMsg("Course Load Error")
          } else {
            setErrorMsg("Internal Server Error")
          }
          setDialogAlertOn(true)
        }
      };

      fetchDataAll()

  }

   useEffect(
    () => {

      if (recheckAccessTokenIsExpired() || getCurrentUserType() !== "admin") {
        navigate('/');
      }  

      const url = Configuration("user_host") + "/api/v1/enum/major-disability-info";

      const fetchDataAll = async () => {
        try {
          const data_arr = await Promise.all([
            axios.get(url)
          ]);

          for (let i in data_arr) {
            let index = parseInt(i)
            let response = data_arr[index].data

            if (index === 0) {
              setDisabilityEnum(response.data)
              //console.log(response.data)
            } 

          }

        } catch (error) {
          console.log("error", error);
        }
      };

      fetchDataAll()

      reloadTable(1, undefined, undefined, undefined)

    },
    []
  )

    return (
      <main>
        <NavBar isCourse={true}/>
        <div className='container'>
      <div className={"course_body_profile"}>

        {dialog_alert_on ? <AlertDialog alert_title={error_msg} alert_msg={""} parentCallback={closeAlertDialog}/> : ""}

        {dialog_confirm_on ? <ConfirmDialog confirm_title={"คุณต้องการจะลบใช่หรือไม่?"} confirm_msg={""} dialog_data={dialog_data} parentCallbackCancel={cancelDeleteDialog}/> : ""}

        <div className="boxes">
          <ProfileMenu selected_menu_init={0}/>

          <div className="admin_profile_detail_box">

          {is_loaded ? <div>

            <div className="boxes">

              <div className="admin_profile_detail_title">
                <h2 className="lesson_name_title">{course_data.course_title}</h2>
              </div>

              <div className="admin_profile_detail_button_container">
                <button className="admin_profile_detail_button" onClick={e => exportCSV(pagination.page, filter_status, filter_handycap, filter_search)}>Export CSV</button>
              </div>

            </div>

            <div className="course_number_boxes">

              <div className="course_number_box course_number_box_1">
              จำนวนนักเรียนทั้งหมด<br/>
              <span className="course_number_stat">{students_overview.total_students}</span>
              </div>

              <div className="course_number_box course_number_box_2">
              กำลังเรียนอยู่<br/>
              <span className="course_number_stat">{students_overview.inprogress_students}</span>
              </div>

              <div className="course_number_box course_number_box_3">
              เรียนจบแล้ว<br/>
              <span className="course_number_stat">{students_overview.graduated_students}</span>
              </div>

            </div>

            <div className="course_filter_input_body_admin">
              <select className="course_list_search_1 filter_course_label_txt_box course_filter_input_body_1" name="filter" defaultValue={filter_status} onChange={e => selectFilterStatus(e.target.value)}>
                <option disabled value="null" hidden>สถานะ</option>
                <optgroup label="สถานะ">
                  <option value="null">แสดงทั้งหมด</option>
                  <option value="graduated">เรียนจบแล้ว</option>
                  <option value="inprogress">กำลังเรียน</option>
                </optgroup>
              </select>

              <select className="course_list_search_1 filter_course_label_txt_box course_filter_input_body_2" name="filter" defaultValue={filter_handycap} onChange={e => selectFilterHandycap(e.target.value)}>
                <option disabled value="null" hidden>ความพิการ</option>
                <optgroup label="ความพิการ">
                  <option value="null">แสดงทั้งหมด</option>
                  {disability_enum ? (disability_enum.map((item, index) => {
                    return (
                      <option key={index} value={item.id}>({index + 1}) {item.name}</option>
                    )
                  })
                  ) : ""}
                </optgroup>
              </select>

              <label className="label_icon label_icon_search course_filter_input_body_3">
                  <input className="filter_course_label_txt_box_white insider_txt_box_with_icon" 
                  name="search" 
                  defaultValue={filter_search}
                  placeholder="ค้นหาตามชื่อ"
                  onChange={e => setFilterSearch(e.target.value)}
                  />
                </label>
                <button 
                className="filter_course_label_txt_box_white filter_course_label_txt_box_white_btn course_filter_input_body_4" onClick={e => selectFilterSearch()}>ค้นหา</button>
            </div>

        <table className="course_list_table">
         <thead className="course_list_thread">
           <tr className="course_list_tr">
             <th className="course_list_th"></th>
             <th className="course_list_th">ชื่อนักเรียน</th>
             <th className="course_list_th">E-mail</th>
             <th className="course_list_th">PWD type</th>
             <th className="course_list_th">Joined</th>
             <th className="course_list_th">สถานะ</th>
             {/*<th className="course_list_th">Actions</th>*/}
          </tr>
         </thead>
         <tbody className="course_list_tbody">
          {user_list ? (user_list.length > 0 ? (user_list.map((key, index) => {
            //console.log(key)
            return (
              <tr className="course_list_tr" key={key.id}>
                <td className="course_list_td"></td>
                <td className="course_list_td">{key.name}</td>
                <td className="course_list_td">{key.email}</td>
                <td className="course_list_td">{key.handycap_type}</td>
                <td className="course_list_td">{key.joined === "from linkage" ? "from linkage": SimplifyDateString(key.joined)}</td>
                <td className={"course_list_td "+(key.status === "graduated" ? "course_list_td_grad" : "")}>{Configuration("proper_text_display")[key.status]}</td>
                {/*<td className="course_list_td"><UserAdminDropdown userID={key.id}/></td>*/}
              </tr>
            );
          })) : (<tr className="course_list_tr">
                <td className="course_list_td"></td>
                <td className="course_list_td">ไม่พบรายการใดๆ</td>
                <td className="course_list_td"></td>
                <td className="course_list_td"></td>
                <td className="course_list_td"></td>
                {/*<td className="course_list_td"></td>*/}
              </tr>)
          ) : 
              (<tr className="course_list_tr">
                <td className="course_list_td"></td>
                <td className="course_list_td">Loading...</td>
                <td className="course_list_td"></td>
                <td className="course_list_td"></td>
                <td className="course_list_td"></td>
                {/*<td className="course_list_td"></td>*/}
              </tr>)}
          </tbody>
        </table>


          <div className="pagination_box">
            <div className="pagination_left_component">
            {/* ref: https://stackoverflow.com/questions/3520996/calculating-item-offset-for-pagination */}
            Showing {(pagination.page - 1) * pagination.perPage + 1}-{pagination.page !== pagination.totalPage ? pagination.page*pagination.perPage : pagination.total} of {pagination.total}
            </div>

            <div className="pagination_right_component">
            {pagination.prev > 0 ? <button className="pagination_btn" onClick={e => reloadTable(pagination.page-1, filter_status, filter_handycap, filter_search)}>{"<"}</button> : <button className="pagination_btn" disabled>{"<"}</button>} Page {pagination.page} of {pagination.totalPage} {pagination.next > 0 ? <button className="pagination_btn" onClick={e => reloadTable(pagination.page+1, filter_status, filter_handycap, filter_search)}>{">"}</button> : <button className="pagination_btn" disabled>{">"}</button>}
            </div>
          </div>


          </div> : ""}

          </div>
        </div>
      
        
      </div>
      <HomeFooter/>
      </div>
      </main>
    );
  
}

export default ProfileAdminCourseUserList;
