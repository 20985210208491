import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import Configuration from '../functions/Configuration';
import GetUrlHashValue from '../functions/GetUrlHashValue';
import OutsiderNavbar from '../components/OutsiderNavbar';
import WelcomeBox from '../components/WelcomeBox';

function RegisterEmailSent() {
  const [input_email, setEmail] = useState("")

  const [is_error, setIsError] = useState(false)
  const [error_msg, setErrorMsg] = useState("")

  const navigate = useNavigate();

  const recheckEmail = () => {
    navigate('/register_email_input');
  }

  useEffect(
    () => {
      document.body.style.background = "#503e9d"

      let user_email = GetUrlHashValue("email")
      if (user_email) {
        setEmail(user_email);
      } else {
        navigate('/register_invalid_link')
        return
      }
    },
    []
  )

  return (
    <div>

      <div>

        <OutsiderNavbar />

        <div className="boxes">
            <div className="msg_regis_box">
              <div className="msg_regis_box_top">
                <h4 className="outsider_txt">ระบบได้จัดส่ง Link สำหรับการสร้างบัญชี<br />
                  Vulcan Academy ไปยัง <br />
                  {input_email} แล้ว <br /></h4>

                <br />

                <h4 className="outsider_txt">กรุณาตรวจสอบอีเมลของท่าน<br />
                  เพื่อดำเนินขั้นตอนต่อไป<br />
                </h4>
                <h6 className="outsider_txt">(Link ดังกล่าวจะมีระยะเวลาใช้งาน 24 ชั่วโมงเท่านั้น)</h6><br />
              </div>

              <h6 className="outsider_txt">*หากท่านไม่ได้รับอีเมลดังกล่าว กรุณาตรวจสอบในช่อง “อีเมลขยะ” <br />
                หรือตรวจสอบตัวสะกดอีเมลของท่านให้ถูกต้องอีกครั้ง <br />
                คลิก <span onClick={recheckEmail}><u>ที่นี่</u></span> เพื่อส่ง Link อีเมลยืนยันอีกครั้ง</h6>

            </div>

          <WelcomeBox isRegis={true} />

        </div>

      </div>

    </div>
  );

}

export default RegisterEmailSent;
