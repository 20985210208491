function SimplifyDateString(raw_date_str) {
let monthNames = ["ม.ค.", "ก.พ.", "มี.ค.", "เม.ย.", "พ.ค.", "มิ.ย.",
  "ก.ค.", "ส.ค.", "ก.ย.", "ต.ค.", "พ.ย.", "ธ.ค."
];

  let raw_date_obj = new Date(raw_date_str)
    let timezone_offset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
    let date_obj = (new Date(raw_date_obj - timezone_offset))
    let the_year = date_obj.getFullYear()
    let the_month = date_obj.getMonth()+1
    let the_day = date_obj.getDate()
    return " "+the_day+" "+monthNames[the_month-1]+" "+the_year
}

export default SimplifyDateString;