import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import Configuration from '../functions/Configuration';
import recheckAccessTokenIsExpired from '../functions/recheckAccessTokenIsExpired';
import getCurrentUserType from '../functions/getCurrentUserType';
import GetUrlHashValue from '../functions/GetUrlHashValue';
import AlertDialog from '../components/AlertDialog';
import ConfirmDialog from '../components/ConfirmDialog';
import ProfileMenu from '../components/ProfileMenu';
import NewCourseHeader from '../components/NewCourseHeader';
import NewCourseProgress from '../components/NewCourseProgress';
import NewCourseNaviBox from '../components/NewCourseNaviBox';
import created_check from '../res/created_check.svg';
import edit_btn_icon from '../res/edit_btn_icon.svg';
import delete_btn_icon from '../res/delete_btn_icon.svg';
import vid_list from '../res/vid_list.svg';
import link_url_icon from '../res/link_url_icon.svg';
import NavBar from '../components/NavBar';
import HomeFooter from '../components/HomeFooter';

function ProfileAdminNewCoursePart2() {
  const [course_id, setCourseID] = useState("")
  const [is_add_lesson_mode, setAddLessonMode] = useState(false)
  const [lessons, setLessons] = useState([]);
  const [lessons_loaded, setLessonsLoaded] = useState(false);

  const [edit_lesson_id, setEditLessonID] = useState("");

  const init_input_state = {
    title: "",
    videos: [],
    materials: [],
    description: "",
  }

  const [new_vid_title, setNewVidTitleValue] = useState("untitled");
  const [new_mat_title, setNewMatTitleValue] = useState("untitled");

  const [new_lesson_input, setNewLessonInput] = useState(init_input_state);
  const [edit_lesson_input, setEditLessonInput] = useState(init_input_state);

  const [youtube_link, setYoutubeLink] = useState("");

  const [dialog_confirm_on, setDialogConfirmOn] = useState(false)
  const [dialog_data, setDialogData] = useState(false)

  const [dialog_alert_on, setDialogAlertOn] = useState(false)
  const [error_msg, setErrorMsg] = useState("")

  const navigate = useNavigate();

  const nextPage = () => {
    navigate('/profile_admin_new_course_3#id='+course_id);
  }

  const enableAddLesson = () => {
    let load_caches = function() {

        for (let name in new_lesson_input) {

            let cache = window.localStorage.getItem("newcourse_p2_"+name);
            //console.log(cache, name)
            if (cache) {
              if (name === "videos" || name === "materials") {
                let json = JSON.parse(cache)
                setValueJSON(name, json)
              } else {
                setValue(name, cache)
              }
            }
          
        }

      }
    load_caches()
    setAddLessonMode(true)
  }

  const disableAddLesson = () => {
    setAddLessonMode(false)
    setNewLessonInput(init_input_state)
  }

  const enableEditLesson = (lesson_id) => {
    setEditLessonID(lesson_id)
    getLessonData(course_id,lesson_id)
  }

  const disableEditLesson = () => {
    setEditLessonID("")
  }

  const reloadLessons = () => {
      reloadLessonsList(course_id)
      setTimeout(() => { 
        disableAddLesson()
        disableEditLesson()
        setLessonsLoaded(true)
          }, 5);      
    }

  const reloadLessonsWithSelected = (selected) => {
      disableEditLesson()
      reloadLessonsList(course_id)
      setTimeout(() => { 
        disableAddLesson()
        setLessonsLoaded(true)
        enableEditLesson(selected)
          }, 5);      
    }

  const addLesson = () => {
    let access_token_data = window.localStorage.getItem("access_token_data");
    let access_token_data_type = window.localStorage.getItem("access_token_data_type");

    const url = Configuration("video_host")+"/api/v1/course/"+course_id+"/lesson";
    let body = new_lesson_input

    //console.log(body)

      const postData = async () => {
        try {
          await axios({
            method: "post",
            url: url,
            data: body,
            headers: { "Authorization": access_token_data_type + " " + access_token_data },
          });
          //console.log(response);

          setLessonsLoaded(false)

          setNewLessonInput(init_input_state)
          let remove_cache = function() {
            for (let name in new_lesson_input) {
                window.localStorage.removeItem("newcourse_p2_"+name);
            }
          }
          remove_cache()

          reloadLessons()
          
        } catch (error) {
          if (error.response.status < 500) {
            //console.log(error.response.data.error_message)
            setErrorMsg("Add Error")
          } else {
            setErrorMsg("Internal Server Error")
          }
          setDialogAlertOn(true)
        }
      };

      postData()

   } 

  const editLesson = (lesson_id) => {
    let access_token_data = window.localStorage.getItem("access_token_data");
    let access_token_data_type = window.localStorage.getItem("access_token_data_type");

    const url = Configuration("video_host")+"/api/v1/course/"+course_id+"/lesson/"+lesson_id;
    let body = edit_lesson_input

    //console.log(url)
    //console.log(body)

      const patchData = async () => {
        try {
          await axios({
            method: "patch",
            url: url,
            data: body,
            headers: { "Authorization": access_token_data_type + " " + access_token_data },
          });

          setLessonsLoaded(false)

          setEditLessonInput(init_input_state)

          disableEditLesson()
          reloadLessons()
          
        } catch (error) {
          if (error.response.status < 500) {
            //console.log(error.response.data.error_message)
            setErrorMsg("Edit Error")
          } else {
            setErrorMsg("Internal Server Error")
          }
          setDialogAlertOn(true)
        }
      };

      patchData()

  }

  const setValue = (name, value) => {
    setNewLessonInput(prevState => ({
                ...prevState,
                [name]: value
    }));
    window.localStorage.setItem("newcourse_p2_"+name, value);
   } 

  const setValueJSON = (name, value) => {
    setNewLessonInput(prevState => ({
                ...prevState,
                [name]: value
    }));
    window.localStorage.setItem("newcourse_p2_"+name, JSON.stringify(value));
   } 

  const setValueEdit = (name, value) => {
    setEditLessonInput(prevState => ({
                ...prevState,
                [name]: value
    }));
   } 

  const addVideoYoutubeLink = (is_edit) => {
    let title_input = new_vid_title
    if (new_vid_title === "") {
        title_input = "untitled"
    }

    let storage_id = "yt_video"
    if (youtube_link.includes("/embed/")) {
          let source_parts = youtube_link.split("/embed/")
          storage_id = source_parts[1]
    } else {
          let source_parts_w = youtube_link.split("watch?v=")
          storage_id = source_parts_w[1]
    }

    let new_vid_data = {
        "storage_id": storage_id,
        "source": youtube_link,
        "title": title_input,
        "video_type": "youtube"
    }
          
    if (!is_edit) {
        setTimeout(() => { 
            setNewLessonInput(prevState => ({
                  ...prevState,
                  videos: [...prevState.videos, new_vid_data]
            }));
            
            window.localStorage.setItem("newcourse_p2_videos", JSON.stringify([...new_lesson_input.videos, new_vid_data]))

      }, 5);
    } else {
        setTimeout(() => { 
          setEditLessonInput(prevState => ({
                  ...prevState,
                  videos: [...prevState.videos, new_vid_data]
          }));

      }, 5);
    }
  }

  const uploadVideo = (files, is_edit) => {
    let access_token_data = window.localStorage.getItem("access_token_data");
    let access_token_data_type = window.localStorage.getItem("access_token_data_type");

    const url = Configuration("video_host")+"/api/v1/course/"+course_id+"/video/upload";
    let file = files[0]

      const postData = async () => {
        let file_data = new FormData()
        file_data.append("file",file)

        try {
          const response = await axios({
            method: "post",
            url: url,
            data: file_data,
            headers: { "Content-Type": "multipart/form-data",
            "Authorization": access_token_data_type + " " + access_token_data },
          });

          let the_id = response.data.data.storage_id
          let the_url = response.data.data.source

          let title_input = new_vid_title
          if (new_vid_title === "") {
            title_input = "untitled"
          }

          let new_vid_data = {
            "storage_id": the_id,
            "source": the_url,
            "title": title_input,
            "video_type": "upload"
          }
          
          if (!is_edit) {
            setTimeout(() => { 
               setNewLessonInput(prevState => ({
                      ...prevState,
                        videos: [...prevState.videos, new_vid_data]
                }));

               window.localStorage.setItem("newcourse_p2_videos", JSON.stringify([...new_lesson_input.videos, new_vid_data]))

            }, 5);
          } else {
            setTimeout(() => { 
               setEditLessonInput(prevState => ({
                      ...prevState,
                        videos: [...prevState.videos, new_vid_data]
                }));

            }, 5);
          }
          
        } catch (error) {
          if (error.response.status < 500) {
            setErrorMsg("Upload Error")
          } else {
            setErrorMsg("Internal Server Error")
          }
          setDialogAlertOn(true)
        }
      };

      postData()
   }

   const uploadMaterial = (files, is_edit) => {
    let access_token_data = window.localStorage.getItem("access_token_data");
    let access_token_data_type = window.localStorage.getItem("access_token_data_type");

    const url = Configuration("video_host")+"/api/v1/course/"+course_id+"/material/upload";
    let file = files[0]

      const postData = async () => {
        let file_data = new FormData()
        file_data.append("file",file)

        try {
          const response = await axios({
            method: "post",
            url: url,
            data: file_data,
            headers: { "Content-Type": "multipart/form-data",
            "Authorization": access_token_data_type + " " + access_token_data },
          });

          let the_id = response.data.data.storage_id
          let the_url = response.data.data.source
          let the_file_type = response.data.data.file_type

          let title_input = new_mat_title
          if (new_mat_title === "") {
            title_input = "untitled"
          }

          let new_mat_data = {
            "storage_id": the_id,
            "source": the_url,
            "title": title_input,
            "file_type": the_file_type
          }
          
          if (!is_edit) {
            setTimeout(() => { 
               setNewLessonInput(prevState => ({
                      ...prevState,
                        materials: [...prevState.materials, new_mat_data]
                }));

               window.localStorage.setItem("newcourse_p2_materials", JSON.stringify([...new_lesson_input.materials, new_mat_data]))

            }, 5);
          } else {
            setTimeout(() => { 
               setEditLessonInput(prevState => ({
                      ...prevState,
                        materials: [...prevState.materials, new_mat_data]
                }));

            }, 5);
          }
          
        } catch (error) {
          if (error.response.status < 500) {
            setErrorMsg("Upload Error")
          } else {
            setErrorMsg("Internal Server Error")
          }
          setDialogAlertOn(true)
        }
      };

      postData()
   }

   const deleteVideo = (video_id, video_type, source) => {
    let access_token_data = window.localStorage.getItem("access_token_data");
    let access_token_data_type = window.localStorage.getItem("access_token_data_type");

      const url = Configuration("video_host")+"/api/v1/course/"+course_id+"/lesson/"+edit_lesson_id+"/video/"+video_id;

      const deleteData = async () => {
        try {
          await axios({
            method: "delete",
            url: url,
            headers: { "Authorization": access_token_data_type + " " + access_token_data },
          });
          //console.log(response)
          setLessonsLoaded(false)
          reloadLessonsWithSelected(edit_lesson_id)

          setEditLessonInput(prevState => ({
                      ...prevState,
                        videos: prevState.videos.filter(item => item.id !== video_id)
                }));
          setDialogConfirmOn(false)
          
        } catch (error) {
          if (error.response.status < 500) {
            setErrorMsg("Delete Error")
          } else {
            setErrorMsg("Internal Server Error")
          }
          setDialogAlertOn(true)
        }
      };

      if (video_type === "upload") {
        deleteData()
      } else {
        setEditLessonInput(prevState => ({
                      ...prevState,
                        videos: prevState.videos.filter(item => item.id !== video_id)
                }));
        setDialogConfirmOn(false)
      }
      
   }

   const cancelVideo = (storage_id, video_type, source) => {
    let access_token_data = window.localStorage.getItem("access_token_data");
    let access_token_data_type = window.localStorage.getItem("access_token_data_type");

      const url = Configuration("video_host")+"/api/v1/course/"+course_id+"/video/"+storage_id+"/cancel-upload";

      const postData = async () => {
        try {
          await axios({
            method: "post",
            url: url,
            headers: { "Authorization": access_token_data_type + " " + access_token_data },
          });
          //console.log(response)

          setNewLessonInput(prevState => ({
                      ...prevState,
                        videos: prevState.videos.filter(item => item.storage_id !== storage_id)
                }));
          window.localStorage.setItem("newcourse_p2_videos", JSON.stringify(new_lesson_input.materials.filter(item => item.storage_id !== storage_id)))
          
        } catch (error) {
          if (error.response.status < 500) {
            setErrorMsg("Cancel Error")
          } else {
            setErrorMsg("Internal Server Error")
          }
          setDialogAlertOn(true)
        }
      };

      if (video_type === "upload") {
        postData()
      } else {
        setNewLessonInput(prevState => ({
                      ...prevState,
                        videos: prevState.videos.filter(item => item.source !== source)
                }));
        window.localStorage.setItem("newcourse_p2_videos", JSON.stringify(new_lesson_input.videos.filter(item => item.source !== source)))
      }
   }

   const deleteMaterial = (material_id) => {
    let access_token_data = window.localStorage.getItem("access_token_data");
    let access_token_data_type = window.localStorage.getItem("access_token_data_type");

      const url = Configuration("video_host")+"/api/v1/course/"+course_id+"/lesson/"+edit_lesson_id+"/material/"+material_id;

      const deleteData = async () => {
        try {
          await axios({
            method: "delete",
            url: url,
            headers: { "Authorization": access_token_data_type + " " + access_token_data },
          });
          //console.log(response)
          setLessonsLoaded(false)
          reloadLessonsWithSelected(edit_lesson_id)

          setEditLessonInput(prevState => ({
                      ...prevState,
                        materials: prevState.materials.filter(item => item.id !== material_id)
                }));
          setDialogConfirmOn(false)

        } catch (error) {
          if (error.response.status < 500) {
            setErrorMsg("Delete Error")
          } else {
            setErrorMsg("Internal Server Error")
          }
          setDialogAlertOn(true)
        }
      };

      deleteData()
   }

   const cancelMaterial = (storage_id) => {
    let access_token_data = window.localStorage.getItem("access_token_data");
    let access_token_data_type = window.localStorage.getItem("access_token_data_type");

      const url = Configuration("video_host")+"/api/v1/course/"+course_id+"/material/"+storage_id+"/cancel-upload";

      const postData = async () => {
        try {
          await axios({
            method: "post",
            url: url,
            headers: { "Authorization": access_token_data_type + " " + access_token_data },
          });
          //console.log(response)

          setNewLessonInput(prevState => ({
                      ...prevState,
                        materials: prevState.materials.filter(item => item.storage_id !== storage_id)
                }));
           window.localStorage.setItem("newcourse_p2_materials", JSON.stringify(new_lesson_input.materials.filter(item => item.storage_id !== storage_id)))
          
        } catch (error) {
          if (error.response.status < 500) {
            setErrorMsg("Cancel Error")
          } else {
            setErrorMsg("Internal Server Error")
          }
          setDialogAlertOn(true)
        }
      };

      postData()
   }

   const reloadLessonsList = (course_id) => {
    let access_token_data = window.localStorage.getItem("access_token_data");
    let access_token_data_type = window.localStorage.getItem("access_token_data_type");

    const url = Configuration("video_host")+"/api/v1/course/"+course_id+"/lesson";

      const fetchData = async () => {
        try {
          const {data: response} = await axios({
            method: "get",
            url: url,
            headers: { "Authorization": access_token_data_type + " " + access_token_data },
          });
          let the_data = response.data.lessons
          if (!the_data) {
            setLessons([])
          } else {
            setLessons(the_data)
          }
          
          setLessonsLoaded(true)
        } catch (error) {
          if (error.response.status < 500) {
            setErrorMsg("Reload Error")
          } else {
            setErrorMsg("Internal Server Error")
          }
          setDialogAlertOn(true)
        }
      };

      fetchData()
   }

   const getLessonData = (course_id, lesson_id) => {
    let access_token_data = window.localStorage.getItem("access_token_data");
    let access_token_data_type = window.localStorage.getItem("access_token_data_type");

    const url = Configuration("video_host")+"/api/v1/course/"+course_id+"/lesson/"+lesson_id;

      const fetchData = async () => {
        try {
          const {data: response} = await axios({
            method: "get",
            url: url,
            headers: { "Authorization": access_token_data_type + " " + access_token_data },
          });
          let the_data = response.data
          
          if (!the_data.videos) {
            the_data.videos = []
          }
          if (!the_data.materials) {
            the_data.materials = []
          }

          //console.log(the_data)
          setEditLessonInput(the_data)
          
        } catch (error) {
          if (error.response.status < 500) {
            setErrorMsg("Reload Error")
          } else {
            setErrorMsg("Internal Server Error")
          }
        }
      };

      fetchData()
   }

   const deleteLesson = (lesson_id) => {
    let access_token_data = window.localStorage.getItem("access_token_data");
    let access_token_data_type = window.localStorage.getItem("access_token_data_type");

    const url = Configuration("video_host")+"/api/v1/course/"+course_id+"/lesson/"+lesson_id;

      const fetchData = async () => {
        try {
          await axios({
            method: "delete",
            url: url,
            headers: { "Authorization": access_token_data_type + " " + access_token_data },
          });
          //console.log(response)

          reloadLessons()
          setDialogConfirmOn(false)
          
        } catch (error) {
          if (error.response.status < 500) {
            setErrorMsg("Delete Error")
          } else {
            setErrorMsg("Internal Server Error")
          }
          setDialogAlertOn(true)
        }
      };

      fetchData()
   }

   const backToCourseList = () => {
      navigate('/profile_admin_course_list');
   } 

  const activateDeleteDialog = (to_delete_id, delete_data_type, source) => {
    setDialogConfirmOn(true)
    setDialogData({
      "id": to_delete_id,
      "type": delete_data_type,
      "source": source,
    })
  }

  const cancelDeleteDialog = () => {
    setDialogConfirmOn(false)
  }

  const deleteSomething = (deletion_data) => {
    //console.log(deletion_data)
    if (deletion_data.type === "lesson") {
      deleteLesson(deletion_data.id)
    } else if (deletion_data.type === "material") {
      deleteMaterial(deletion_data.id)
    } else {
      deleteVideo(deletion_data.id, deletion_data.type)
    }
  }

  const closeAlertDialog = () => {
    setDialogAlertOn(false)
  }

   useEffect(
    () => {

      let get_course_id = GetUrlHashValue("id")
      setCourseID(get_course_id)

      document.body.style.background = "#503e9d"
      document.body.style.padding = "20px 2% 20px 2%";
      document.getElementById("root").style.background = "#503e9d"
      document.getElementById("root").style.borderRadius  = "20px"

      if (recheckAccessTokenIsExpired() || getCurrentUserType() !== "admin") {
        navigate('/');
      }

      reloadLessonsList(get_course_id)

    },
    []
  )

    return (
      <main>
        <NavBar isCourse={true}/>
        <div className='container'>
      <div className={"course_body_profile"}>

      {dialog_alert_on ? <AlertDialog alert_title={error_msg} alert_msg={""} parentCallback={closeAlertDialog}/> : ""}

      {dialog_confirm_on ? <ConfirmDialog confirm_title={"คุณต้องการจะลบใช่หรือไม่?"} confirm_msg={""} dialog_data={dialog_data} parentCallback={deleteSomething} parentCallbackCancel={cancelDeleteDialog}/> : ""}

        <div className="boxes">
          <ProfileMenu isAdmin={true}/>

          <div className="admin_profile_detail_box">
            
            <NewCourseHeader parentCallback={backToCourseList}/>

            <NewCourseProgress progress={2}/>

            <div className="boxes">
            <h4 className="new_course_title">Section 2 : Curriculum</h4> <NewCourseNaviBox parentCallback={nextPage}/>
            </div>

            {lessons_loaded ? <div>
              <h5>Lessons list :</h5>
              { 
                 lessons.map((item,index) => {
                  //console.log(item)
                    return (
                        <div className="course_edit_box_item" key={item.id+"_"+index}>
                          <p><b><img alt="a created lesson" src={created_check} width="30" height="30"/> {item.title}</b> <br/>{item.videos ? item.videos.length+" Videos" : "0 Videos"}
                          <span className="edit_nor_delete"><button className="edit_select" onClick={() => enableEditLesson(item.id)}><img alt="Edit" src={edit_btn_icon} width="25" height="25"/></button> <button className="delete_select" onClick={() => activateDeleteDialog(item.id, "lesson")}><img alt="Delete" src={delete_btn_icon} width="25" height="25"/></button></span></p>
                          {edit_lesson_id === item.id ? 
                          <div>

                          <div className="boxes">
                                  <div className="new_course_label_txt horizontal_txtbox_left">Title: </div>
                                  <input className="new_course_label_txt_box horizontal_txtbox_right" type="text" name="title" defaultValue={edit_lesson_input.title} onChange={e => setValueEdit(e.target.name, e.target.value)}/>
                                </div>

                          <br/>

                          <div className="boxes">
                              <div className="new_course_label_txt horizontal_txtbox_left">Description</div>
                              <input className="new_course_label_txt_box horizontal_txtbox_right" name="description" defaultValue={edit_lesson_input.description} onChange={e => setValueEdit(e.target.name, e.target.value)}/>
                            </div>

                            <br/>

                          <h6>Videos : </h6>

                          { edit_lesson_input.videos ? (edit_lesson_input.videos.map((item,index) => {
                            return (
                                <div key={item.storage_id+"_"+index}>
                                <p><img alt="Video item" src={vid_list} width="35" height="25"/> {item.title} <button className="delete_select" onClick={() => activateDeleteDialog(item.id, item.video_type, item.source)}><img alt="Delete" src={delete_btn_icon} width="25" height="25"/></button></p>
                                </div>
                              )
                          })) : ""
                          }

                          <div className="boxes">
                                  <div className="new_course_label_txt horizontal_txtbox_left">Video Title (Input this before upload!): </div>
                                  <input className="new_course_label_txt_box horizontal_txtbox_right" type="text" name="video_title" onChange={e => setNewVidTitleValue(e.target.value)}/>
                                </div>

                          <div className="course_add_txt_box_triple">
                            <input className="btn_upload horizontal_txtbox_left" type="file" onChange={e => uploadVideo(e.target.files, true)}/>
                            <div>
                                  <div className="new_course_label_txt ">Or Youtube Link: </div><br/>
                                  <div className="boxes youtube_link_txt_boxes yt_boxes">
                                    <img alt="URL" src={link_url_icon} width="35" height="35"/> <input className="new_course_label_txt_box" type="text" name="youtube_link" onChange={e => setYoutubeLink(e.target.value)}/>
                                    <button className="admin_profile_detail_button_3" onClick={() => addVideoYoutubeLink(true)}>Add</button>
                                  </div>
                                </div>
                          </div>

                          <br/>

                          <h6>Materials : </h6>

                          { edit_lesson_input.materials ? (edit_lesson_input.materials.map((item,index) => {
                            return (
                                <div key={item.storage_id+"_"+index}>
                                <p><img alt="Material item" src={vid_list} width="35" height="25"/> {item.title} <button className="delete_select" onClick={() => activateDeleteDialog(item.id, "material")}><img alt="Delete" src={delete_btn_icon} width="25" height="25"/></button></p>
                                </div>
                              )
                          })) : ""
                          }

                            <div className="boxes">
                              <div className="new_course_label_txt horizontal_txtbox_left">Material Title (Input this before upload!): </div>
                              <input className="new_course_label_txt_box horizontal_txtbox_right" type="text" name="material_title" onChange={e => setNewMatTitleValue(e.target.value)}/>
                            </div>

                            <br/>

                            <input className="btn_upload  horizontal_txtbox_left" type="file" onChange={e => uploadMaterial(e.target.files, true)}/>

                            <br/>

                          <div className="course_detail_button_container">
                              <button className="course_detail_button_items_left" onClick={disableEditLesson}>Cancel</button>
                              <button className="course_detail_button_items_right" onClick={() => editLesson(item.id)}>Edit Lesson</button>
                            </div>

                            <br/>


                          </div> : ""}
                        </div>
                      )
                  })
          
              }</div>: ""}

            {is_add_lesson_mode ? <div>

              <br/>

            <h5><b>Add New Lesson : </b></h5>

            <div className="boxes">
                    <div className="new_course_label_txt horizontal_txtbox_left">Title: </div>
                    <input className="new_course_label_txt_box horizontal_txtbox_right" type="text" name="title" defaultValue={new_lesson_input.title} onChange={e => setValue(e.target.name, e.target.value)}/>
                  </div>

            <br/>

            <div className="boxes">
                <div className="new_course_label_txt horizontal_txtbox_left">Description</div>
                <input className="new_course_label_txt_box horizontal_txtbox_right" name="description" defaultValue={new_lesson_input.description} onChange={e => setValue(e.target.name, e.target.value)}/>
              </div>

            <br/>

            <h6>Upload Video : </h6>

            { new_lesson_input.videos ? (new_lesson_input.videos.map((item,index) => {
              return (
                  <div key={item.storage_id+"_"+index}>
                  <p><img alt="Video item" src={vid_list} width="35" height="25"/> {item.title} <button className="delete_select" onClick={() => cancelVideo(item.storage_id, item.video_type, item.source)}><img alt="Cancel" src={delete_btn_icon} width="25" height="25"/></button></p>
                  </div>
                )
            })) : ""
            }

            <div className="boxes">
                    <div className="new_course_label_txt horizontal_txtbox_left">Video Title (Input this before upload!): </div>
                    <input className="new_course_label_txt_box horizontal_txtbox_right" type="text" name="video_title" onChange={e => setNewVidTitleValue(e.target.value)}/>
                  </div>

            <div className="course_add_txt_box_triple">
              <input className="btn_upload horizontal_txtbox_left" type="file" onChange={e => uploadVideo(e.target.files, false)}/>
              <div>
                    <div className="new_course_label_txt ">Or Youtube Link: </div><br/>
                    <div className="boxes youtube_link_txt_boxes yt_boxes">
                      <img alt="URL" src={link_url_icon} width="35" height="35"/> <input className="new_course_label_txt_box" type="text" name="youtube_link" onChange={e => setYoutubeLink(e.target.value)}/>
                      <button className="admin_profile_detail_button_3" onClick={() => addVideoYoutubeLink(false)}>Add</button>
                    </div>
                  </div>
            </div>

            <br/>

            <h6>Upload Learning Material : </h6>

            { new_lesson_input.materials ? (new_lesson_input.materials.map((item,index) => {
              return (
                  <div key={item.storage_id+"_"+index}>
                  <p><img alt="Video item" src={vid_list} width="35" height="25"/> {item.title} <button className="delete_select" onClick={() => cancelMaterial(item.storage_id)}><img alt="Cancel" src={delete_btn_icon} width="25" height="25"/></button></p>
                  </div>
                )
            })) : ""
            }

            <div className="boxes">
                    <div className="new_course_label_txt horizontal_txtbox_left">Material Title (Input this before upload!): </div>
                    <input className="new_course_label_txt_box horizontal_txtbox_right" type="text" name="material_title" onChange={e => setNewMatTitleValue(e.target.value)}/>
                  </div>

              <br/>

            <input className="btn_upload horizontal_txtbox_left" type="file" onChange={e => uploadMaterial(e.target.files, false)}/>

              <br/>

            <div className="course_detail_button_container">
                <button className="course_detail_button_items_left" onClick={disableAddLesson}>Cancel</button>
                <button className="course_detail_button_items_right" onClick={addLesson}>Add Lesson</button>
              </div>

              </div> : ""}

            {is_add_lesson_mode ? "" : <button className="lesson_add_btn" onClick={enableAddLesson}>+ Add Lesson</button>}

          </div>
        </div>
        
        
      </div>
      <HomeFooter/>
      </div>
      </main>
    );
  
}

export default ProfileAdminNewCoursePart2;
