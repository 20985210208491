function Configuration(key) {
  let config = {
    "main_host": process.env.REACT_APP_COURSE_SERVICE, //should not end with slash here
    "video_host": process.env.REACT_APP_VIDEO_COURSE_SERVICE,
    "user_host": process.env.REACT_APP_USER_SERVICE,
    "proper_text_display" : {
      "published" : "Published",
      "unpublished" : "Not Published",
      "admin": "Administrator",
      "vulcan_hero": "Vulcan Hero User",
      "general_user": "ข้อมูลส่วนตัวทั่วไป",
      "thai": "Thai",
      "english": "English",
      "upskill": "Upskill Course",
      "reskill": "Reskill Course",
      "working": "Working Platform Tutorial",
      "upskill_th": "หลักสูตร Upskill",
      "reskill_th": "หลักสูตร Reskill",
      "working_th": "คู่มือการทำงานวัลแคนฮีโร่",
      "video": "Video",
      "exam": "Exam",
      "graduated" : "เรียนจบแล้ว",
      "inprogress" : "กำลังเรียน",
    }
  }
  return config[key]
}

export default Configuration;