import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import Configuration from '../functions/Configuration';
import getCurrentUserType from '../functions/getCurrentUserType';
import recheckAccessTokenIsExpired from '../functions/recheckAccessTokenIsExpired';
import getValueOutOfID from '../functions/getValueOutOfID';
import makeDisplay00 from '../functions/makeDisplay00';
import OptionalFieldDisplayFilter from '../functions/OptionalFieldDisplayFilter';
import ProfileMenu from '../components/ProfileMenu';
import HomeFooter from '../components/HomeFooter';
import AlertDialog from '../components/AlertDialog';
import NavBar from '../components/NavBar';
import DateDropdownEdit from '../components/DateDropdownEdit';

function ProfileStudent() {
  const [user_data, setUserData] = useState(null);
  const [user_data_loaded, setUserDataLoaded] = useState(false);
  const [district_loaded, setDistrictLoaded] = useState(true)
  const [subdistrict_loaded, setSubDistrictLoaded] = useState(true)

  const [disability_enum, setDisabilityEnum] = useState([]);
  const [major_disability_enum, setMajorDisabilityEnum] = useState([]);
  const [province_enum, setProvinceEnum] = useState([]);
  const [district_enum, setDistrictEnum] = useState([]);
  const [subdistrict_enum, setSubDistrictEnum] = useState([]);

  const [editMode, setEditMode] = useState(false);
  const [edit_data, setEditData] = useState(null);

  const [edit_mode_firstLoad, setEditModeFirstLoad] = useState(false);

  const [current_birthyear, setInitBirthYear] = useState(null);
  const [current_birthmonth, setInitBirthMonth] = useState(null);
  const [current_birthdate, setInitBirthDate] = useState(null);
  const [selected_birthyear, setBirthYear] = useState(null);
  const [selected_birthmonth, setBirthMonth] = useState(null);
  const [selected_birthdate, setBirthDate] = useState(null);
  const [date_enabled, setDateShouldEnable] = useState(true);

  const [monthNames, setMonthNames] = useState(["ม.ค.", "ก.พ.", "มี.ค.", "เม.ย.", "พ.ค.", "มิ.ย.",
    "ก.ค.", "ส.ค.", "ก.ย.", "ต.ค.", "พ.ย.", "ธ.ค."
  ])

  const [reenable_disabled, setReEnableDisabled] = useState(false);
  const [reenable_district, setReEnableDistrict] = useState(false);
  const [reenable_subdistrict, setReEnableSubDistrict] = useState(false);

  const navigate = useNavigate();

  const [dialog_alert_on, setDialogAlertOn] = useState(false)
  const [error_msg, setErrorMsg] = useState("")

  const closeAlertDialog = () => {
    setDialogAlertOn(false)
  }

  const getUserInfo = () => {
    const url = Configuration("user_host")+"/api/v1/user/me";
    let access_token_data = window.localStorage.getItem("access_token_data");
    let access_token_data_type = window.localStorage.getItem("access_token_data_type");

      const fetchData = async () => {
        try {
          const {data: response} = await axios({
            method: "get",
            url: url,
            headers: { "Authorization": access_token_data_type + " " + access_token_data },
          });
          //console.log(response)
          let the_data = response.data
          /*if (the_data.is_handycap === false) {
            the_data["primary_handycap_type"] = 0
            the_data["secondary_handycap_type"] = 0
          }*/
          setUserData(the_data)

          let birthday_data = response.data.birthday.split("-")
          setInitBirthYear(parseInt(birthday_data[0]))
          setInitBirthMonth(parseInt(birthday_data[1]))
          setInitBirthDate(parseInt(birthday_data[2]))

          setUserDataLoaded(true)
          
        } catch (error) {
          if (error.response.status < 500) {
            setErrorMsg("Load User Data Error")
          } else {
            setErrorMsg("Internal Server Error")
          }
          setDialogAlertOn(true)
        }
      };

      fetchData()
  }

  const enableEdit = () => {
    setBirthYear(current_birthyear)
    setBirthMonth(current_birthmonth)
    setBirthDate(current_birthdate)
    setEditModeFirstLoad(true)
    let districts = []
    for (let i in province_enum) {
        if (province_enum[i].id === parseInt(user_data.address.province.id)) {
            districts = province_enum[i].district
            setDistrictEnum(districts)
        }
    }
    setTimeout(() => { 
        for (let i in districts) {
            if (districts[i].id === parseInt(user_data.address.district.id)) {
              setSubDistrictEnum(districts[i].subdistrict)
            }
          }
        let to_edit_data = user_data
        to_edit_data["address"]["province_id"] = parseInt(user_data.address.province.id)
        to_edit_data["address"]["district_id"] = parseInt(user_data.address.district.id)
        to_edit_data["address"]["subdistrict_id"] = parseInt(user_data.address.subdistrict.id)
        setEditData(to_edit_data)
        setEditMode(true)
        setEditModeFirstLoad(false)
      }, 1);
  }

  const cancelEdit = () => {
    setEditMode(false)
    setEditData(null)
    setBirthYear(null)
    setBirthMonth(null)
    setBirthDate(null)
  }

  const getBirthdayString = () => {
    if (!selected_birthyear || !selected_birthmonth || !selected_birthdate) {
      return "One of birthday selections is missing"
    }
    return selected_birthyear+"-"+makeDisplay00(selected_birthmonth)+"-"+makeDisplay00(selected_birthdate)
  }

  const editUserInfo = () => {
    const url = Configuration("user_host")+"/api/v1/user/me";
    let access_token_data = window.localStorage.getItem("access_token_data");
    let access_token_data_type = window.localStorage.getItem("access_token_data_type");

    let body = edit_data
    body["birthday"] = getBirthdayString()

    //console.log(edit_data)

      const fetchData = async () => {
        try {
          await axios({
            method: "patch",
            url: url,
            data: body,
            headers: { "Authorization": access_token_data_type + " " + access_token_data },
          });
          //console.log(response)
          setUserDataLoaded(false)
          getUserInfo()
          setEditMode(false)
          setEditData(null)
          
        } catch (error) {
          if (error.response.status < 500) {
            setErrorMsg("Edit Input Error")
          } else {
            setErrorMsg("Internal Server Error")
          }
          setDialogAlertOn(true)
        }
      };

      fetchData()
  }

  const setValue = (name, value) => {
    setEditData(prevState => ({
                ...prevState,
                [name]: value
    }));
   } 

  const setAddressValue = (name, value) => {
    setEditData(prevState => ({
                ...prevState,
                address: {
                  ...prevState.address,
                  [name]: value
                }
    }));
   } 

  const setNumAddressValue = (name, value) => {
    if (name === "province_id") {
      setReEnableDistrict(true)
      setReEnableSubDistrict(true)
      for (let i in province_enum) {
        if (province_enum[i].id === parseInt(value)) {
          setDistrictEnum(province_enum[i].district)
        }
      }
      setDistrictLoaded(false)
      setSubDistrictLoaded(false)
      setTimeout(() => { 
        setEditData(prevState => ({
                    ...prevState,
                    address: {
                      ...prevState.address,
                      district_id: 0,
                      subdistrict_id: 0,
                    }
        }));
        setDistrictLoaded(true)
        setReEnableDistrict(false)
        setReEnableSubDistrict(false)
      }, 1);
    } else if (name === "district_id") {
      setReEnableSubDistrict(true)
      for (let j in district_enum) {
        if (district_enum[j].id === parseInt(value)) {
          setSubDistrictEnum(district_enum[j].subdistrict)
        }
      }
      setSubDistrictLoaded(false)
      setTimeout(() => { 
        setEditData(prevState => ({
                  ...prevState,
                  address: {
                    ...prevState.address,
                    subdistrict_id: 0,
                  }
        }));
        setSubDistrictLoaded(true)
        setReEnableSubDistrict(false)
      }, 1);
    }

    setEditData(prevState => ({
                ...prevState,
                address: {
                  ...prevState.address,
                  [name]: parseInt(value)
                }
    }));
   }

  const setBoolValue = (name, value) => {
    let into_bool = (value === "true" || value === true)
    setEditData(prevState => ({
                ...prevState,
                [name]: into_bool
    }));

    if (name === "is_handycap" && !into_bool) {
      setReEnableDisabled(true)
      setTimeout(() => { 
        setEditData(prevState => ({
                    ...prevState,
                    primary_handycap_type: 0,
                    secondary_handycap_type: 0,
        }));
        setTimeout(() => { 
          setReEnableDisabled(false)
        }, 1);
      }, 1);
    }

    if (name === "is_handycap" && into_bool) {
      setReEnableDisabled(true)
      setTimeout(() => { 
        setReEnableDisabled(false)
      }, 1);
    }
   } 
  
   const setIntValue = (name, value) => {
    setEditData(prevState => ({
                ...prevState,
                [name]: parseInt(value)
    }));
   } 

   const setBirthday = (type, value) => {
    //console.log(type, value)
    if (value === "null") {
      value = null
    }
    if (type === "year") {
      setBirthYear(value)
      setDateShouldEnable(false)
      setBirthDate(null)
      setTimeout(() => { 
        setDateShouldEnable(true)
      }, 1);
      
    } else if (type === "month") {
      setBirthMonth(value)
      setDateShouldEnable(false)
      setBirthDate(null)
      setTimeout(() => { 
        setDateShouldEnable(true)
      }, 1);

    } else if (type === "date") {
      setBirthDate(value)
    }
  }

   useEffect(
    () => {

      if (recheckAccessTokenIsExpired()) {
        navigate('/');
      }

      setMonthNames(["ม.ค.", "ก.พ.", "มี.ค.", "เม.ย.", "พ.ค.", "มิ.ย.",
        "ก.ค.", "ส.ค.", "ก.ย.", "ต.ค.", "พ.ย.", "ธ.ค."
      ])

      let access_token_data = window.localStorage.getItem("access_token_data");
      let access_token_data_type = window.localStorage.getItem("access_token_data_type");

      const url = Configuration("user_host")+"/api/v1/enum/disability-info";

      const url2 = Configuration("user_host")+"/api/v1/enum/major-disability-info";

      const url3 = Configuration("user_host")+"/api/v1/enum/province-info";

      const fetchDataAll = async () => {
        try {
          const data_arr = await Promise.all([
            axios({
              method: "get",
              url: url,
              headers: { "Authorization": access_token_data_type + " " + access_token_data },
            }),
            axios.get(url2),
            axios.get(url3)
          ]);
          //console.log(data_arr)

          for (let i in data_arr) {
            let index = parseInt(i)
            let response = data_arr[index].data

            if (index === 0) {
              setDisabilityEnum(response.data)
            } else if (index === 1) {
              setMajorDisabilityEnum(response.data)
            } else if (index === 2) {
              setProvinceEnum(response.data)
              getUserInfo()
            } 

          }
          
        } catch (error) {
          if (error.response.status < 500) {
            setErrorMsg("Load Enum Error")
          } else {
            setErrorMsg("Internal Server Error")
          }
        }
      };

      fetchDataAll()

    },
    []
  )

    return (
      <main>
        <NavBar isCourse={true}/>
        <div className='container'>
    <div className={"course_body_back "}>
      <div className={"course_body_profile"}>

        {dialog_alert_on ? <AlertDialog alert_title={error_msg} alert_msg={""} parentCallback={closeAlertDialog}/> : ""}

        <div className="boxes">
          <ProfileMenu/>

          <div className="profile_detail_box">
            {user_data_loaded ? (getCurrentUserType() === "general_user" ? <div>
              
              <div className='profile_title_box'>
              <div className="admin_profile_detail_title">
                <h4 className="card_header_txt">ข้อมูลของฉัน</h4>
                
              </div>
              {!editMode ? 
                <div className="student_profile_detail_button_container">
                  <button className="student_profile_detail_button student_profile_detail_button_center" onClick={enableEdit}>แก้ไข</button>
                  </div>
                : <div className="student_profile_detail_button_container">
                <button className="student_profile_detail_button student_profile_detail_button_left" onClick={cancelEdit}>ยกเลิกการแก้ไข</button>
                <button className="student_profile_detail_button student_profile_detail_button_right" onClick={editUserInfo}>ยืนยันการแก้ไข</button>
              </div>}
              </div>

              <div className="course_add_txt_box_double">
                {!editMode ? <span tabIndex="0" className="new_course_label_txt_display course_add_txt_box_in_double_1"><b>ชื่อ :</b> {user_data.name}</span> : 
                  <div className="course_add_txt_box_in_double_1 boxes">
                    <div className="new_course_label_txt horizontal_txtbox_left">ชื่อ</div>
                    <input className="new_course_label_txt_box_student horizontal_txtbox_right" type="text" name="name" defaultValue={edit_data.name} onChange={e => setValue(e.target.name, e.target.value)} />
                  </div>}
                {!editMode ? <span className="new_course_label_txt_display course_add_txt_box_in_double_2"><b>นามสกุล :</b> {user_data.lastname}</span> : 
                  <div className="course_add_txt_box_in_double_2 boxes">
                    <div className="new_course_label_txt horizontal_txtbox_left">นามสกุล</div>
                    <input className="new_course_label_txt_box_student horizontal_txtbox_right" type="text" name="lastname" defaultValue={edit_data.lastname} onChange={e => setValue(e.target.name, e.target.value)} />
                  </div>}    
              </div>

              <div className="course_add_txt_box_double">
                {!editMode ? <span className="new_course_label_txt_display course_add_txt_box_in_double_1"><b>Name :</b> {user_data.english_name}</span> : 
                  <div className="course_add_txt_box_in_double_1 boxes">
                    <div className="new_course_label_txt horizontal_txtbox_left">Name</div>
                    <input className="new_course_label_txt_box_student horizontal_txtbox_right" type="text" name="english_name" defaultValue={edit_data.english_name} onChange={e => setValue(e.target.name, e.target.value)} />
                  </div>}
                {!editMode ? <span className="new_course_label_txt_display course_add_txt_box_in_double_2"><b>Surname :</b> {user_data.english_lastname}</span> : 
                  <div className="course_add_txt_box_in_double_2 boxes">
                    <div className="new_course_label_txt horizontal_txtbox_left">Surname</div>
                    <input className="new_course_label_txt_box_student horizontal_txtbox_right" type="text" name="english_lastname" defaultValue={edit_data.english_lastname} onChange={e => setValue(e.target.name, e.target.value)} />
                  </div>}
              </div>

              <div className="course_add_txt_box_single_big_box">
                {!editMode ? <span className="new_course_label_txt_display course_add_txt_box_single"><b>เลขบัตรประจำตัวประชาชน :</b> {user_data.personal_id}</span> : 
                  <div className="course_add_txt_box_single boxes">
                    <div className="new_course_label_txt horizontal_txtbox_left">เลขบัตรประจำตัวประชาชน</div>
                    <input className="new_course_label_txt_box_student horizontal_txtbox_right" type="text" name="personal_id" defaultValue={edit_data.personal_id} onChange={e => setAddressValue(e.target.name, e.target.value)} />
                  </div>}
              </div>

              <div className="course_add_txt_box_single_big_box">
                {!editMode ? <span className="new_course_label_txt_display course_add_txt_box_single"><b>เพศ :</b> {user_data.gender}</span> : 
                  <select className="new_course_label_txt_box_student course_add_txt_box_single" name="gender" defaultValue={edit_data.gender} onChange={e => setValue(e.target.name, e.target.value)}>
                    <option disabled value="null" hidden>เพศ</option>
                    <optgroup label="เพศ">
                      <option value="ชาย">เพศ : ชาย</option>
                      <option value="หญิง">เพศ : หญิง</option>
                    </optgroup>
                  </select>}
              </div>

              <div className="course_add_txt_box_triple">
                {!editMode ? <span className="new_course_label_txt_display course_add_txt_box_in_triple_1"><b>ปีเกิด :</b> {current_birthyear}</span> : 
                  <DateDropdownEdit classNames={"new_course_label_txt_box_student course_add_txt_box_in_triple_1"} initDisplay={"ปีเกิด"} valueName={"birthday_year"} valueType={"year"} initValue={selected_birthyear} parentCallback={setBirthday}/>}
                {!editMode ? <span className="new_course_label_txt_display course_add_txt_box_in_triple_2"><b>เดือนเกิด :</b> {monthNames[current_birthmonth-1]}</span> : 
                  <DateDropdownEdit classNames={"new_course_label_txt_box_student course_add_txt_box_in_triple_2"} initDisplay={"เดือนเกิด"} valueName={"birthday_month"} valueType={"month"} initValue={selected_birthmonth} parentCallback={setBirthday}/>}
                {!editMode ? <span className="new_course_label_txt_display course_add_txt_box_in_triple_3"><b>วันที่เกิด :</b> {current_birthdate}</span> : 
                  (selected_birthyear && selected_birthmonth && date_enabled ? 
                    <DateDropdownEdit classNames={"new_course_label_txt_box_student course_add_txt_box_in_triple_3"} initDisplay={"วันที่เกิด"} valueName={"birthday_date"} valueType={"date"} initValue={selected_birthdate} preSelectYear={selected_birthyear} preSelectMonth={selected_birthmonth} parentCallback={setBirthday}/>
                         : <select className="new_course_label_txt_box_student course_add_txt_box_in_triple_3" disabled>
                    <option value="null">วันที่เกิด</option>
                  </select>)}
              </div>

              <div className="course_add_txt_box_triple">
                {!editMode ? <span className="new_course_label_txt_display course_add_txt_box_in_triple_1"><b>พิการหรือไม่ :</b> {user_data.is_handycap ? "ใช่" : "ไม่ใช่"}</span> : 
                  <select className="new_course_label_txt_box_student course_add_txt_box_in_triple_1" name="is_handycap" defaultValue={edit_data.is_handycap} onChange={e => setBoolValue(e.target.name, e.target.value)}>
                    <option disabled value="null" hidden>คุณเป็นคนพิการหรือไม่?</option>
                    <optgroup label="คุณเป็นคนพิการหรือไม่?">
                      <option value="true">เป็นคนพิการ</option>
                      <option value="false">ไม่ได้เป็นคนพิการ</option>
                    </optgroup>
                  </select>}
                {!editMode ? <span className="new_course_label_txt_display course_add_txt_box_in_triple_2"><b>ความพิการ :</b> {OptionalFieldDisplayFilter(getValueOutOfID(major_disability_enum, user_data.primary_handycap_type))}</span> : 
                  (edit_data.is_handycap ? <select className="new_course_label_txt_box_student course_add_txt_box_in_triple_2" name="primary_handycap_type" defaultValue={edit_data.primary_handycap_type} onChange={e => setIntValue(e.target.name, e.target.value)}>
                    <option disabled={!reenable_disabled} value={0} hidden>ประเภทความพิการ</option>
                    <optgroup label="ประเภทความพิการ">
                      { major_disability_enum ? (major_disability_enum.map((item,index) => {
                            return (
                                <option key={index} value={item.id}>{item.name}</option>
                              )
                          })
                          ) : ""}
                      </optgroup>
                  </select> : <select className="new_course_label_txt_box_student course_add_txt_box_in_triple_2" defaultValue="null" disabled={!reenable_disabled}>
                      <option value="null">ประเภทความพิการ</option>
                    </select>)}
                {!editMode ? <span className="new_course_label_txt_display course_add_txt_box_in_triple_3"><b>ความพิการที่ 2 :</b> {OptionalFieldDisplayFilter(getValueOutOfID(disability_enum, user_data.secondary_handycap_type))}</span> : 
                  (edit_data.is_handycap ? <select className="new_course_label_txt_box_student course_add_txt_box_in_triple_3" name="secondary_handycap_type" defaultValue={edit_data.secondary_handycap_type} onChange={e => setIntValue(e.target.name, e.target.value)}>
                    <option disabled={!reenable_disabled} value={0} hidden>ประเภทความพิการที่ 2 (กรณีพิการซ้ำซ้อน)</option>
                    <optgroup label="ประเภทความพิการที่ 2 (กรณีพิการซ้ำซ้อน)">
                      { disability_enum ? (disability_enum.map((item,index) => {
                            return (
                                <option key={index} value={item.id}>{item.name}</option>
                              )
                          })
                          ) : ""}
                      </optgroup>
                  </select> : <select className="new_course_label_txt_box_student course_add_txt_box_in_triple_3" defaultValue="null" disabled={!reenable_disabled}>
                      <option value="null">ประเภทความพิการที่ 2</option>
                    </select>)}
              </div>

              <div className="course_add_txt_box_double">
                {!editMode ? <span className="new_course_label_txt_display course_add_txt_box_in_double_1"><b>Email :</b> {user_data.email}</span> : 
                  <div className="course_add_txt_box_in_double_1 boxes">
                    <div className="new_course_label_txt horizontal_txtbox_left">Email</div>
                    <input className="new_course_label_txt_box_student horizontal_txtbox_right" type="email" name="email" defaultValue={edit_data.email} onChange={e => setValue(e.target.name, e.target.value)} />
                  </div>}

                {!editMode ? <span className="new_course_label_txt_display course_add_txt_box_in_double_2"><b>เบอร์โทรศัพท์ :</b> {OptionalFieldDisplayFilter(user_data.phone_number)}</span> : 
                  <div className="course_add_txt_box_in_double_2 boxes">
                    <div className="new_course_label_txt horizontal_txtbox_left">เบอร์โทรศัพท์</div>
                    <input className="new_course_label_txt_box_student horizontal_txtbox_right" type="text" name="phone_number" defaultValue={edit_data.phone_number} onChange={e => setValue(e.target.name, e.target.value)} />
                  </div>}
              </div>

              <div className="course_add_txt_box_double">
                {!editMode ? <span className="new_course_label_txt_display course_add_txt_box_in_double_1"><b>Facebook :</b> {OptionalFieldDisplayFilter(user_data.facebook_id)}</span> : 
                  <div className="course_add_txt_box_in_double_1 boxes">
                    <div className="new_course_label_txt horizontal_txtbox_left">Facebook</div>
                    <input className="new_course_label_txt_box_student horizontal_txtbox_right" type="text" name="facebook_id" defaultValue={edit_data.facebook_id} onChange={e => setValue(e.target.name, e.target.value)} />
                  </div>}
                {!editMode ? <span className="new_course_label_txt_display course_add_txt_box_in_double_2"><b>Line ID :</b> {OptionalFieldDisplayFilter(user_data.line_id)}</span> : 
                  <div className="course_add_txt_box_in_double_2 boxes">
                    <div className="new_course_label_txt horizontal_txtbox_left">Line ID</div>
                    <input className="new_course_label_txt_box_student horizontal_txtbox_right" type="text" name="line_id" defaultValue={edit_data.line_id} onChange={e => setValue(e.target.name, e.target.value)} />
                  </div>}
              </div>

              <br/>

              <div className="admin_profile_detail_title">
                <p className="card_header_txt">ที่อยู่ปัจจุบัน</p>
              </div>

              <div className="course_add_txt_box_triple">
                {!editMode ? <span className="new_course_label_txt_display course_add_txt_box_in_triple_1"><b>ชื่ออาคาร :</b> {OptionalFieldDisplayFilter(user_data.address.building_no)}</span> : 
                  <div className="course_add_txt_box_in_triple_1 boxes">
                    <div className="new_course_label_txt horizontal_txtbox_left">ชื่ออาคาร</div>
                    <input className="new_course_label_txt_box_student horizontal_txtbox_right" type="text" name="building_no" defaultValue={edit_data.address.building_no} onChange={e => setAddressValue(e.target.name, e.target.value)} />
                  </div>}
                {!editMode ? <span className="new_course_label_txt_display course_add_txt_box_in_triple_2"><b>ชั้นที่ :</b> {OptionalFieldDisplayFilter(user_data.address.building_floor)}</span> : 
                  <div className="course_add_txt_box_in_triple_2 boxes">
                    <div className="new_course_label_txt horizontal_txtbox_left">ชั้นที่</div>
                    <input className="new_course_label_txt_box_student horizontal_txtbox_right" type="text" name="building_floor" defaultValue={edit_data.address.building_floor} onChange={e => setAddressValue(e.target.name, e.target.value)} />
                  </div>}
                {!editMode ? <span className="new_course_label_txt_display course_add_txt_box_in_triple_3"><b>บ้านเลขที่ :</b> {user_data.address.residential_no}</span> : 
                  <div className="course_add_txt_box_in_triple_3 boxes">
                    <div className="new_course_label_txt horizontal_txtbox_left">บ้านเลขที่</div>
                    <input className="new_course_label_txt_box_student horizontal_txtbox_right" type="text" name="residential_no" defaultValue={edit_data.address.residential_no} onChange={e => setAddressValue(e.target.name, e.target.value)} />
                  </div>}
              </div>

              <div className="course_add_txt_box_triple">
                {!editMode ? <span className="new_course_label_txt_display course_add_txt_box_in_triple_1"><b>หมู่ที่ :</b> {OptionalFieldDisplayFilter(user_data.address.residential_local)}</span> : 
                  <div className="course_add_txt_box_in_triple_1 boxes">
                    <div className="new_course_label_txt horizontal_txtbox_left">หมู่ที่</div>
                    <input className="new_course_label_txt_box_student horizontal_txtbox_right" type="text" name="residential_local" defaultValue={edit_data.address.residential_local} onChange={e => setAddressValue(e.target.name, e.target.value)} />
                  </div>}
                {!editMode ? <span className="new_course_label_txt_display course_add_txt_box_in_triple_2"><b>ตรอก/ซอย :</b> {OptionalFieldDisplayFilter(user_data.address.residential_alley)}</span> : 
                  <div className="course_add_txt_box_in_triple_2 boxes">
                    <div className="new_course_label_txt horizontal_txtbox_left">ตรอก/ซอย</div>
                    <input className="new_course_label_txt_box_student horizontal_txtbox_right" type="text" name="residential_alley" defaultValue={edit_data.address.residential_alley} onChange={e => setAddressValue(e.target.name, e.target.value)} />
                  </div>}
                {!editMode ? <span className="new_course_label_txt_display course_add_txt_box_in_triple_3"><b>ถนน :</b> {OptionalFieldDisplayFilter(user_data.address.residential_street)}</span> : 
                  <div className="course_add_txt_box_in_triple_3 boxes">
                    <div className="new_course_label_txt horizontal_txtbox_left">ถนน</div>
                    <input className="new_course_label_txt_box_student horizontal_txtbox_right" type="text" name="residential_street" defaultValue={edit_data.address.residential_street} onChange={e => setAddressValue(e.target.name, e.target.value)} />
                  </div>}
              </div>

              <div className="course_add_txt_box_triple">
                {!editMode ? <span className="new_course_label_txt_display course_add_txt_box_in_triple_1"><b>จังหวัด :</b> {user_data.address.province.name}</span> : 
                <select className="new_course_label_txt_box_student course_add_txt_box_in_triple_1" name="province_id" defaultValue={edit_data.address.province.id} onChange={e => setNumAddressValue(e.target.name, e.target.value)}>
                    <option disabled value={0} hidden>จังหวัด</option>
                    <optgroup label="จังหวัด">
                    { province_enum ? (province_enum.map((item,index) => {
                          return (
                              <option key={index} value={item.id}>{item.name}</option>
                            )
                        })
                        ) : ""}
                    </optgroup>
                  </select>}
                {!editMode ? <span className="new_course_label_txt_display course_add_txt_box_in_triple_2"><b>เขต/อำเภอ :</b> {user_data.address.district.name}</span> : 
                  (district_loaded ? <select className="new_course_label_txt_box_student course_add_txt_box_in_triple_2" name="district_id" defaultValue={edit_data.address.district.id} onChange={e => setNumAddressValue(e.target.name, e.target.value)}>
                    <option disabled={!reenable_district} value={0} hidden>เขต/อำเภอ</option>
                    <optgroup label="เขต/อำเภอ">
                    { district_enum ? (district_enum.map((item,index) => {
                          return (
                              <option key={index} value={item.id}>{item.name}</option>
                            )
                        })
                        ) : ""}
                    </optgroup>
                  </select> : <select className="new_course_label_txt_box_student course_add_txt_box_in_triple_2" defaultValue={0} disabled={!reenable_district}>
                    <option value={0}>เขต/อำเภอ</option>
                  </select>)}
                {!editMode ? <span className="new_course_label_txt_display course_add_txt_box_in_triple_3"><b>แขวง/ตำบล :</b> {user_data.address.subdistrict.name}</span> : 
                  (subdistrict_loaded ? <select className="new_course_label_txt_box_student course_add_txt_box_in_triple_3" name="subdistrict_id" defaultValue={edit_data.address.subdistrict.id} onChange={e => setNumAddressValue(e.target.name, e.target.value)}>
                    <option disabled={!reenable_subdistrict} value={0} hidden>แขวง/ตำบล</option>
                    <optgroup label="แขวง/ตำบล">
                    { subdistrict_enum ? (subdistrict_enum.map((item,index) => {
                          return (
                              <option key={index} value={item.id}>{item.name}</option>
                            )
                        })
                        ) : ""}
                    </optgroup>
                  </select> : <select className="new_course_label_txt_box_student course_add_txt_box_in_triple_3" defaultValue={0} disabled={!reenable_subdistrict}>
                    <option value={0}>แขวง/ตำบล</option>
                  </select>)}
              </div>

              <div className="course_add_txt_box_single_big_box">
                {!editMode ? <span className="new_course_label_txt_display course_add_txt_box_single"><b>รหัสไปรษณีย์ :</b> {OptionalFieldDisplayFilter(user_data.address.postal_code)}</span> : 
                  <div className="course_add_txt_box_single boxes">
                    <div className="new_course_label_txt horizontal_txtbox_left">รหัสไปรษณีย์</div>
                    <input className="new_course_label_txt_box_student horizontal_txtbox_right" type="text" name="postal_code" defaultValue={edit_data.address.postal_code} onChange={e => setAddressValue(e.target.name, e.target.value)} />
                  </div>}
              </div>

              <br/>

            </div> : <div className="admin_profile_detail_title">
                <h4 className="card_header_txt">ข้อมูลส่วนตัวเป็นไปตามระบบ Vulcan Linkage สำหรับ Vulcan Hero</h4>
              </div>) : <h4 className="card_header_txt">Loading...</h4>}
          </div>
        </div>
      </div>
        <HomeFooter/>
      </div>
      </div>
      </main>
    );
  
}

export default ProfileStudent;
