import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

function UserAdminDropdown(props) {
  const parentCallbackDuplicate = props.parentCallbackDuplicate
  const parentCallbackDelete = props.parentCallbackDelete
  const user_id = props.userID
  const [is_open, setOpen] = useState(false)

  const navigate = useNavigate();

  const handleClick = () => {
    setOpen(!is_open)
  }

  const viewUser = (user_id) => {
      navigate('/profile_admin_view_student#id='+user_id+"&edit=false");
   } 

  const editUser = (user_id) => {
      navigate('/profile_admin_view_student#id='+user_id+"&edit=true");
   } 

  const deleteDialog = (user_id) => {
          parentCallbackDelete(user_id)
          setOpen(!is_open)
  }

    return (
      <span>
      <button className="navbar_item_admin navbar2_item" onClick={handleClick}>

        &#9660;

      </button>
      {is_open ? <div className={"dropdown-content-admin-course"}>
          <br/>
          <a className="navbar2_hover_menu_item" onClick={e => viewUser(user_id)}>View</a><br/>
          <a className="navbar2_hover_menu_item" onClick={e => editUser(user_id)}>Edit</a><br/>
          <a className="navbar2_hover_menu_item" onClick={e => deleteDialog(user_id)}>Delete</a><br/>
        </div> : ""}
      </span>
    );
}


export default UserAdminDropdown