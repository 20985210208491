import recheckAccessTokenIsExpired from '../functions/recheckAccessTokenIsExpired';

function Logout() {
  if (!recheckAccessTokenIsExpired()) {
        window.localStorage.removeItem("access_token_data");
        window.localStorage.removeItem("access_token_data_type");
    }

  return true
}

export default Logout;