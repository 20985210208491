import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import OutsiderNavbar from '../components/OutsiderNavbar';
import WelcomeBox from '../components/WelcomeBox';


function ResetPasswordSuccess() {
    
    const navigate = useNavigate();

    const backToLogin = () => {
        navigate('/login');
    }

    useEffect(
        () => {
            document.body.style.background = "#503e9d"
        },
        []
    )

    return (
        <div>

            <div>

                <OutsiderNavbar />

                <div className="boxes">

                    <div className="msg_regis_box">
                        <h1 className="outsider_txt outsider_txt_regis_success">
                            บัญชีของคุณ ได้ตั้งค่ารหัสผ่านใหม่เรียบร้อยแล้ว<br />
                            กรุณาล๊อกอิน เพื่อเข้าสู่ระบบอีกครั้ง<br />
                        </h1><br /><br />

                        <div className="outsider_btns">
                            <input className="outsider_btn_base outsider_btn_regis_success" type="submit" value="เข้าสู่ระบบ" onClick={backToLogin} />
                        </div>

                    </div>

                    <WelcomeBox isRegis={true} />

                </div>

            </div>

        </div>
    );

}

export default ResetPasswordSuccess;
