function RenderMarkdownHTML(pre_html_input, actual) {
  let new_html_output = pre_html_input
  if (actual) {
    new_html_output = new_html_output.replace(/(?:\r\n|\r|\n)/g, '<br/>');
    new_html_output = new_html_output.replace(/(?:\[\*\*)/g, '<b>');
    new_html_output = new_html_output.replace(/(?:\*\*\])/g, '</b>');
    new_html_output = new_html_output.replace(/(?:\[\*)/g, '<i>');
    new_html_output = new_html_output.replace(/(?:\*\])/g, '</i>');
    new_html_output = new_html_output.replace(/(?:\[__)/g, '<u>');
    new_html_output = new_html_output.replace(/(?:__\])/g, '</u>');
    return new_html_output
  } else {
    new_html_output = new_html_output.replace(/(?:\r\n|\r|\n)/g, ' ');
    new_html_output = new_html_output.replace(/(?:\[\*\*)/g, '');
    new_html_output = new_html_output.replace(/(?:\*\*\])/g, '');
    new_html_output = new_html_output.replace(/(?:\[\*)/g, '');
    new_html_output = new_html_output.replace(/(?:\*\])/g, '');
    new_html_output = new_html_output.replace(/(?:\[__)/g, '');
    new_html_output = new_html_output.replace(/(?:__\])/g, '');
    return new_html_output
  }
  
}

export default RenderMarkdownHTML;