import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import RenderMarkdownHTML from '../functions/RenderMarkdownHTML';
import getCurrentUserType from '../functions/getCurrentUserType';

function CourseIconItem(props) {
  const [is_admin, setIsAdmin] = useState(false)

  const navigate = useNavigate();

  const [is_landscape_thumbnail, setIsLandscapeThumbnail] = useState(true)

  const data = props.courseData
  const displayOn = props.displayOn

  var course_cta_label
  if(data.course_app === "video"){
    course_cta_label = "เริ่มเรียน"
    if (displayOn == "home") {
      if (data.is_completed){
        course_cta_label = "ทบทวนบทเรียน"
      }else if(data.is_enrolled){
        course_cta_label = "เรียนต่อ"
      }
    }else if(displayOn == "current_course") {
        course_cta_label = "เรียนต่อ"
    }else if(displayOn == "completed_course") {
      course_cta_label = "ทบทวนบทเรียน"
    }
  }else if(data.course_app === "exam"){
    course_cta_label = "เริ่มทำแบบทดสอบ"
    if (displayOn == "home") {
      if (data.is_completed){
        course_cta_label = "ทำแบบทดสอบเสร็จสิ้น"
      }else if(data.is_enrolled){
        course_cta_label = "ทำแบบทดสอบต่อ"
      }
    }else if(displayOn == "current_course") {
        course_cta_label = "ทำแบบทดสอบต่อ"
    }else if(displayOn == "completed_course") {
      course_cta_label = "ทำแบบทดสอบเสร็จสิ้น"
    }
  }
  // TODO handle default course app type 

  
  
  const is_in_profile = props.is_in_profile

   const goToCourse = () => {
       navigate('/course_enroll#id='+data.id+"&course_app="+data.course_app);
   } 

   const getMeta = (url, callback) => {
        let img = new Image();
        img.src = url;
        img.onload = function() { callback(this.width, this.height); }
    }

   useEffect(
    () => {
      if (getCurrentUserType() === "admin") {
        setIsAdmin(true)
      }

      getMeta(
        data.thumbnail.source,
        function(width, height) { 

          const ratio = width/height
          if (ratio < 1) {
            setIsLandscapeThumbnail(false)
          }
          
         }
      );
    },
    []
  )

    return (
      
      <div className="card">
        <div className="img-wrapper">
          <div className="responsive-img-wrap">
            <div className={"img-container "+(is_in_profile ? "img-container-shorter" : "")}>
              
                <img alt="course_thumbnail" className={is_landscape_thumbnail ? "card-image-ideal" : "card-image-portrait"} src={data.thumbnail.source}/>
              
            </div>
          </div>
        </div>
        <div className="card-time-strip">{data.duration} ชั่วโมง</div>
        <div className={"card-body "+(is_in_profile ? "card-body-shorter" : "")}>
          <h3 className={"card-title "+(is_in_profile ? "card-title-smaller" : "")}>{data.course_title}</h3>
          <h4 className={"card-text "+(is_in_profile ? "card-text-smaller" : "")}>{RenderMarkdownHTML(data.overview_desc, false)}</h4>
          <h4 className={"card-author "+(is_in_profile ? "card-author-smaller" : "")}><b>ผู้สอน:</b> {data.author}</h4>
        </div>
        {!is_admin ? <div className="card-enroll-box">
          <button className="card-enroll-btn" onClick={goToCourse}>{course_cta_label}</button>
        </div> : <div className="card-enroll-box">
          <button className="card-enroll-btn" disabled>ตอนนี้ Admin ยังไม่ได้สร้างหน้าตรวจสถิติ...</button>
        </div>}
      </div>

    );
  
}

export default CourseIconItem;