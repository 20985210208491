import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

function UserGroupAdminDropdown(props) {
  const parentCallbackDuplicate = props.parentCallbackDuplicate
  const parentCallbackDelete = props.parentCallbackDelete
  const group_id = props.groupID
  const [is_open, setOpen] = useState(false)

  const navigate = useNavigate();

  const handleClick = () => {
    setOpen(!is_open)
  }

  const goToEditCourse1 = (group_id) => {
      navigate('/profile_admin_new_course_1#id='+group_id+"&edit=true");
   } 

  const goToEditCourse2 = (group_id) => {
      navigate('/profile_admin_new_course_2#id='+group_id);
   } 

  const goToEditCourse3 = (group_id) => {
      navigate('/profile_admin_new_course_3#id='+group_id);
   } 

  const goToEditCourse4 = (group_id) => {
      navigate('/profile_admin_new_course_4#id='+group_id);
   } 

  const duplicateDialog = (group_id) => {
          parentCallbackDuplicate(group_id)
          setOpen(!is_open)
  }

  const deleteDialog = (group_id) => {
          parentCallbackDelete(group_id)
          setOpen(!is_open)
  }

    return (
      <span>
      <button className="navbar_item_admin navbar2_item" onClick={handleClick}>

        &#9660;

      </button>
      {is_open ? <div className={"dropdown-content-admin-course"}>
          <br/>
          <a className="navbar2_hover_menu_item" onClick={e => goToEditCourse1(group_id)}>View</a><br/>
          <a className="navbar2_hover_menu_item" onClick={e => goToEditCourse2(group_id)}>Edit</a><br/>
          <a className="navbar2_hover_menu_item" onClick={e => deleteDialog(group_id)}>Delete</a><br/>
        </div> : ""}
      </span>
    );
}


export default UserGroupAdminDropdown