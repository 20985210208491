import React, { useEffect, useRef } from 'react';

function AlertDialog(props) {
  const parentCallback = props.parentCallback
  const alert_title = props.alert_title
  const alert_msg = props.alert_msg

  const dialogRef = useRef()

  const is_good = props.is_good

  const callBack = () => {
    document.body.style.overflow = "visible"
    parentCallback()
  }

  useEffect(
    () => {
      let top  = window.pageYOffset || document.documentElement.scrollTop
      document.getElementById("confirm_box_object").style.top = (top+(window.screen.height/3))+"px"
      document.body.style.overflow = "hidden"
      document.getElementById("confirm_box_overlay_object").style.top = (top)+"px"
      document.getElementById("confirm_box_overlay_object").style.height = (window.screen.height)+"px"
    },
    []
  )
  useEffect(()=>{
    dialogRef.current?.focus()
  },[])

    return (
      <div>
      <div className={"confirm_box_overlay"} id={"confirm_box_overlay_object"}>

      </div>
      <div aria-modal='true' className={"confirm_box"} id={"confirm_box_object"}>
        <br/>
        <h2 ref={dialogRef} className="confirm_txt" tabIndex="0">{alert_title}</h2>
        {alert_msg !== "" ? <span><label className="confirm_msg"> {alert_msg} </label>
        <br/> </span> : ""}
        {is_good ? <input className="confirm_solo_btn confirm_solo_btn_good" type="button" value="ตกลง" onClick={callBack}/> :
        <input className="confirm_solo_btn confirm_solo_btn_bad" type="button" value="ตกลง" onClick={callBack}/>}
      </div>
      </div>
    );
}


export default AlertDialog