import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import Configuration from '../functions/Configuration';
import GetUrlHashValue from '../functions/GetUrlHashValue';

function VerifyEmail() {
  const [is_error, setIsError] = useState(false)
  const [error_msg, setErrorMsg] = useState("")

  const navigate = useNavigate();

  const toRegister = (email_value) => {
    navigate('/register', { state: { email: email_value } });
   } 

  const toInvalidPage = () => {
    navigate('/register_invalid_link')
  }

  useEffect(
    () => {
      document.body.style.background = "#503e9d"

      let is_redirect = GetUrlHashValue("redirect")

      let get_token = GetUrlHashValue("token")

      if (is_redirect === "true" || is_redirect === true) {
        const url = Configuration("user_host")+"/api/v1/login/verify-email-token";
        let body = {
          token: get_token,
        }

          const postData = async () => {
            try {
              const response = await axios.post(url, body);

              toRegister(response.data.data.email)
              
            } catch (error) {
              if (error.response.status < 500) {
                toInvalidPage()
              } else {
                setErrorMsg("Internal Server Error")
              }

              setIsError(true)
            }
          };

          postData()
      } else {
        toInvalidPage()
      }
    },
    []
  )

    return (
      <div>
        {is_error ? <h6 className="outsider_login_error">{error_msg}</h6> : ""}
      </div>
    );
  
}

export default VerifyEmail;
